import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Layout from "../../layout/Layout";
import { smallButton } from "../../constants/styles";
import { Link, useNavigate } from "react-router-dom";
import { PiCopyLight } from "react-icons/pi";
import { FaCheck } from "react-icons/fa";
import styles from "./Home.module.scss";
import styled from "@emotion/styled";
import Loader from "../../components/Loader/Loader";
import CRMAPI from "../../services/crm-api";
import { getDownloadUrlFromPresignedUrl } from "../../helpers/upload-helper";
import UserAPI from "../../services/profile-api";
import Swal from "sweetalert2";

import { useDispatch, useSelector } from "react-redux";

import { changeFilterLoader } from "../../redux/slices/filterSlice";

// components
import FilterButton from "../../components/Filters/FilterButton";
import SwitchFilter from "../../components/Filters/SwitchFilter";
import FilterLoader from "../../components/Loader/FilterLoader";
import DateRangeSelector from "../../components/Filters/DateRangeSelector";
import UTMDialog from "./UTMDialog/UTMDialog";

export default function Home() {
  const [report, setReport] = useState(null);

  const [copySuccess, setCopySuccess] = useState(false);

  const { utmLink } = useSelector((state) => state.user);

  const { filterLoader } = useSelector((state) => state.filter);
  const { dsacommissionType } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { getDashboardReportAPI, uploadLeadsAPI } = new CRMAPI();
  const { generatePresignedURLAPI } = new UserAPI();

  const navigate = useNavigate();

  useEffect(() => {
    fetchReportDetails();
  }, [filterLoader]);

  const fetchReportDetails = async () => {
    const res = await getDashboardReportAPI();
    if (res.ok) {
      setReport(res.data);
    }
    dispatch(changeFilterLoader(false));
  };

  const handleUploadLeads = async (file) => {
    dispatch(changeFilterLoader(true));
    const res = await generatePresignedURLAPI(file);

    const presignedUrl = res.data.url;

    await fetch(presignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        "Content-Length": file.size,
      },
      body: file,
    });

    const downloadURL = await getDownloadUrlFromPresignedUrl(presignedUrl);

    if (downloadURL) {
      const res = await uploadLeadsAPI(downloadURL);

      if (res.ok) {
        Swal.fire({
          icon: "success",
          text: "Upload Successfully!",
          showConfirmButton: false,
          timer: 2000,
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }

    dispatch(changeFilterLoader(false));
    navigate("/uploaded-leads");
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const copyUTMLink = () => {
    navigator.clipboard
      .writeText(utmLink)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => {
          setCopySuccess(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  console.log("utmLink------------------", utmLink);

  if (!report) {
    return <Loader />;
  }
  return (
    <Layout>
      <Box className={styles.home__page}>
        <Stack spacing={3}>
          <Card elevation={0} sx={{ p: 2, borderRadius: 3 }}>
            <Grid container spacing={6}>
              <Grid size={3}>
                <Box className={styles.leadCard}>
                  <Typography variant="h6">Add Leads</Typography>
                  <Box>
                    <a
                      href="/images/leads-sample.xlsx"
                      className={styles.link}
                      download
                    >
                      Download Format
                    </a>

                    <Button
                      component="label"
                      size="small"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      sx={smallButton}
                    >
                      <Typography sx={{ fontSize: 11 }}>Upload</Typography>
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(event) =>
                          handleUploadLeads(event.target.files[0])
                        }
                      />
                    </Button>
                  </Box>
                </Box>
              </Grid>

              <Grid size={6}>
                <Box className={styles.leadCard}>
                  <Typography variant="h6">Create UTM</Typography>

                  {utmLink === "https://www.google.com" ? (
                    <UTMDialog />
                  ) : (
                    <Box className={styles.utm}>
                      <Box className={styles.utmLink}>
                        <Link to="/">{utmLink}</Link>
                        <Box className={styles.icon} onClick={copyUTMLink}>
                          {copySuccess ? (
                            <FaCheck size={14} color="#48b73b" />
                          ) : (
                            <PiCopyLight size={18} />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>

              <Grid size={3}>
                <Box className={styles.leadCard}>
                  <Typography variant="h6">API Documentation</Typography>
                  <Box className={styles.viewDocument}>
                    <a
                      href="https://cdn-ramfincorp.s3.ap-south-1.amazonaws.com/API+Documentation.pdf"
                      download
                      className={styles.link}
                      target="_blank"
                    >
                      View Document
                    </a>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>

          <Card elevation={0} sx={{ py: 1, borderRadius: 3 }}>
            <CardHeader
              action={
                <Box display="flex" justifyContent="flex-end">
                  <SwitchFilter />
                  <FilterButton />
                </Box>
              }
              title={
                <Box className="header-title">
                  <Typography variant="h4">DSA Performance</Typography>
                  <DateRangeSelector />
                </Box>
              }
            />

            <CardContent>
              <Grid container spacing={4}>
                {/* <Grid size={3}>
                  <Box className={styles.dsaPerformance}>
                    <Typography variant="h6">{report.closed_leads}</Typography>
                    <Typography variant="body2">Closed Leads</Typography>
                  </Box>
                </Grid> */}

                {dsacommissionType === "disbursal" && (
                  <Grid size={3}>
                    <Box className={styles.dsaPerformance}>
                      <Typography variant="h6">
                        ₹{report.disbursal_amount}
                      </Typography>
                      <Typography variant="body2">Disbursal Amount</Typography>
                    </Box>
                  </Grid>
                )}

                {dsacommissionType === "disbursal" && (
                  <Grid size={3}>
                    <Box className={styles.dsaPerformance}>
                      <Typography variant="h6">
                        {report.disbursed_leads}
                      </Typography>
                      <Typography variant="body2">Disbursed Leads</Typography>
                    </Box>
                  </Grid>
                )}

                <Grid size={3}>
                  <Box className={styles.dsaPerformance}>
                    <Typography variant="h6">{report.lead_count}</Typography>
                    <Typography variant="body2">Lead Count</Typography>
                  </Box>
                </Grid>

                <Grid size={3}>
                  <Box className={styles.dsaPerformance}>
                    <Typography variant="h6">
                      {report.not_interested_leads}
                    </Typography>
                    <Typography variant="body2">
                      Not Interested Leads
                    </Typography>
                  </Box>
                </Grid>

                {/* <Grid size={3}>
                  <Box className={styles.dsaPerformance}>
                    <Typography variant="h6">{report.overdue_leads}</Typography>
                    <Typography variant="body2">Overdue Leads</Typography>
                  </Box>
                </Grid> */}

                <Grid size={3}>
                  <Box className={styles.dsaPerformance}>
                    <Typography variant="h6">
                      {report.commission_amount}
                    </Typography>
                    <Typography variant="body2">Commission Amount</Typography>
                  </Box>
                </Grid>

                <Grid size={3}>
                  <Box className={styles.dsaPerformance}>
                    <Typography variant="h6">
                      {report.rejected_leads}
                    </Typography>
                    <Typography variant="body2">Rejected Leads</Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Stack>
      </Box>

      {/* Filter Loader */}
      {filterLoader && <FilterLoader />}
    </Layout>
  );
}
