import React, { useEffect, useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFilterLoader,
  setUpdateDateRange,
} from "../../redux/slices/filterSlice";

export default function DateRangeSelector() {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const { filterLoader, updateDateRange } = useSelector(
    (state) => state.filter
  );

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);

  const paramStartDate = queryParams.get("startDate");
  const paramEndDate = queryParams.get("endDate");

  // console.log("paramStartDate-------------------", paramStartDate);
  // console.log("paramEndDate-------------------", paramEndDate);
  // console.log("updateDateRange-------------------", updateDateRange);

  useEffect(() => {
    if (startDate && endDate) {
      const newStartDate = moment(startDate)
        .subtract(0, "days")
        .format("YYYY-MM-DD");
      const newEndDate = moment(endDate)
        .subtract(0, "days")
        .format("YYYY-MM-DD");

      queryParams.set("startDate", newStartDate);
      queryParams.set("endDate", newEndDate);
      navigate(`${location.pathname}?${queryParams.toString()}`);
      dispatch(changeFilterLoader(!filterLoader));
    }

    if (startDate == null && endDate == null) {
      queryParams.delete("startDate");
      queryParams.delete("endDate");
      navigate(`${location.pathname}?${queryParams.toString()}`);
      dispatch(changeFilterLoader(!filterLoader));
    }
  }, [dateRange]);

  useEffect(() => {
    if (updateDateRange) {
      if (paramStartDate && paramEndDate) {
        setDateRange([new Date(paramStartDate), new Date(paramEndDate)]);
        dispatch(setUpdateDateRange(false));
      }
    }
  }, [updateDateRange]);

  return (
    <div style={{ margin: "0 10px" }}>
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update) => {
          setDateRange(update);
        }}
        dateFormat="dd-MM-yyyy"
        isClearable={true}
        placeholderText={"Custom Date"}
      />
    </div>
  );
}
