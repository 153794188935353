import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout";
import Loader from "../../components/Loader/Loader";

import { Leads } from "../CRMDSAUserDetails";

export default function LeadReport() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <Layout>
      <Leads id="" />
    </Layout>
  );
}
