import React from "react";
import { Routes, Route } from "react-router-dom";

// DSA Pages
import { Home } from "../pages/Home";
import { LeadReport } from "../pages/LeadReport";
import { Commission } from "../pages/Commissions";
import { Profile } from "../pages/Profile";

// CRM Pages
import { CRMDSAUser } from "../pages/CRMDSAUser";
import { CRMDSAUserDetails } from "../pages/CRMDSAUserDetails";
import { Logout } from "../pages/Auth";
import { UploadedLeads } from "../pages/UploadedLeads";
import { useSelector } from "react-redux";
import Agreement from "../pages/Agreement/Agreement";
import Hold from "../pages/Hold/Hold";

export default function DashboardRoutes() {
  const { user } = useSelector((state) => state.user);

  return (
    <Routes>
      {user.role === "DSA" ? (
        <>
          <Route path="/" element={<Home />} />
          <Route path="/lead-report" element={<LeadReport />} />
          <Route path="/commission" element={<Commission />} />
          <Route path="/uploaded-leads" element={<UploadedLeads />} />
          <Route path="/agreement" element={<Agreement />} />
          <Route path="/hold" element={<Hold />} />
        </>
      ) : (
        <>
          <Route path="/" element={<CRMDSAUser />} />
          <Route path="/:id" element={<CRMDSAUserDetails />} />
        </>
      )}

      <Route path="/profile" element={<Profile />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}
