import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import Layout from "../../layout/Layout";
import { FiEye } from "react-icons/fi";
import Loader from "../../components/Loader/Loader";
import CRMAPI from "../../services/crm-api";
import { MdOutlineCloudUpload } from "react-icons/md";
import styled from "@emotion/styled";
import { smallButton } from "../../constants/styles";
import UserAPI from "../../services/profile-api";
import { getDownloadUrlFromPresignedUrl } from "../../helpers/upload-helper";

import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import Swal from "sweetalert2";
import { MdCloudDownload } from "react-icons/md";
import { API_URL } from "../../utils/api";
import FilterLoader from "../../components/Loader/FilterLoader";

export default function UploadedLeads(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);

  const { getUploadedAPI } = new UserAPI();
  const { downloadUploadedLeadsAPI } = new CRMAPI();

  useEffect(() => {
    fetchPayoutDetails();
  }, []);

  const fetchPayoutDetails = async () => {
    const res = await getUploadedAPI();
    if (res.ok) {
      setData(res.data);
    }
    setLoading(false);
  };

  const handleDownloadAPI = async (leadFileId, status) => {
    setFilterLoader(true);
    await downloadUploadedLeadsAPI(leadFileId, status);
    const downloadUrl = `${API_URL}api/v1/dsa/lead/upload/list/download?leadFileId=${leadFileId}&status=${status}`;
    window.location.href = downloadUrl;
    setFilterLoader(false);
  };

  console.log("data----------", data);

  if (loading) {
    return <Loader />;
  }
  return (
    <Layout>
      <Card elevation={0} sx={{ py: 1, borderRadius: 3 }}>
        <CardHeader
          title={
            <Box className="header-title">
              <Typography variant="h4">Uploaded Leads</Typography>
            </Box>
          }
        />
        <CardContent>
          <TableContainer component={Paper}>
            <Table aria-label="lead table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>File Name</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Updated</TableCell>
                  <TableCell>Download</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>{row?.url?.split("/").at(-1)}</TableCell>
                    <TableCell>
                      {new Date(row.createdAt).toDateString()}
                    </TableCell>
                    <TableCell>
                      {new Date(row.updatedAt).toDateString()}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        startIcon={<MdCloudDownload size={20} />}
                        sx={{ mr: 1, background: "#28a745" }}
                        size="small"
                        onClick={() => handleDownloadAPI(row.id, row.status)}
                      >
                        Download
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      {/* Filter Loader */}
      {filterLoader && <FilterLoader />}
    </Layout>
  );
}
