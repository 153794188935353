import React from "react";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Commission(props) {
  const { values, commissions, setCommissions, item, index } = props;

  const updateCommissionStart = (value, key) => {
    commissions[index][key] = value;
    setCommissions([...commissions]);
  };

  const handleRemoveRow = () => {
    commissions.splice(index, 1);
    setCommissions([...commissions]);
  };

  return (
    <>
      <Grid size={3}>
        <TextField
          fullWidth
          autoComplete="off"
          size="small"
          name="commissionStart"
          label="Commission Start"
          variant="outlined"
          value={
            item.start !== null && item.start !== undefined ? item.start : ""
          }
          onChange={(e) => updateCommissionStart(e.target.value, "start")}
        />
      </Grid>

      <Grid size={3}>
        <TextField
          fullWidth
          autoComplete="off"
          size="small"
          name="commissionEnd"
          label="Commission End"
          variant="outlined"
          value={item.end !== null && item.end !== undefined ? item.end : ""}
          onChange={(e) => updateCommissionStart(e.target.value, "end")}
        />
      </Grid>

      <Grid size={3}>
        <TextField
          fullWidth
          autoComplete="off"
          size="small"
          name="commissionValue"
          label={
            values.commissionType === "disbursal"
              ? "Commission Percentage"
              : "Commission Amount"
          }
          variant="outlined"
          value={item.value || ""}
          onChange={(e) => updateCommissionStart(e.target.value, "value")}
        />
      </Grid>
      <Grid size={3}>
        <IconButton aria-label="delete" onClick={() => handleRemoveRow()}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </>
  );
}
