import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isLoggedIn: false,
  userName: null,
  utmLink: "",
  dsacommissionType: "",
  userStatus: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addUserInfo: (state, action) => {
      state.user = action.payload;
    },

    handleUserLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },

    addUserName: (state, action) => {
      state.userName = action.payload;
    },

    addUTMLink: (state, action) => {
      state.utmLink = action.payload;
    },

    setDSACommissionType: (state, action) => {
      state.dsacommissionType = action.payload;
    },

    setUserStatus: (state, action) => {
      state.userStatus = action.payload;
    },
  },
});

export const {
  addUserInfo,
  handleUserLoggedIn,
  addUserName,
  addUTMLink,
  setDSACommissionType,
  setUserStatus,
} = userSlice.actions;

export default userSlice.reducer;
