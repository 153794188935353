import React from "react";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { rejectValidation } from "../../validations/payment.validation";
import { Formik } from "formik";
import Grid from "@mui/material/Grid2";
import CRMAPI from "../../services/crm-api";
import Swal from "sweetalert2";

export default function RejectPaymentForm(props) {
  const { id, setOpen, setPaymentMode } = props;

  const { updateCommissionStatusAPI } = new CRMAPI();

  const initialValues = {
    remarks: "",
  };

  console.log("id-----------", id);

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={rejectValidation}
        onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
          setSubmitting(true);
          setTimeout(async () => {
            const res = await updateCommissionStatusAPI(
              id,
              "rejected",
              values.remarks
            );

            if (res.ok) {
              Swal.fire({
                icon: "success",
                text: "Reject Successfully",
                showConfirmButton: false,
                timer: 3000,
              });
              window.location.reload();
            } else {
              Swal.fire({
                icon: "error",
                text: res.err,
                showConfirmButton: false,
                timer: 3000,
              });
            }

            setOpen(false);
            setPaymentMode("");

            setSubmitting(false);
          }, 500);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 1 }}>
              <TextField
                multiline
                rows={3}
                fullWidth
                autoComplete="off"
                size="small"
                name="remarks"
                label="Remarks"
                variant="outlined"
                helperText={touched.remarks && errors.remarks}
                error={touched.remarks && errors.remarks}
                value={values.remarks || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>

            <Grid container spacing={0}>
              <Grid size={6} offset={3}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  size="large"
                  endIcon={
                    isSubmitting && (
                      <CircularProgress
                        size="1.4rem"
                        style={{ color: "#ffffff", marginLeft: 6 }}
                      />
                    )
                  }
                  sx={{
                    "&.Mui-disabled": {
                      color: "#000",
                      background: "#ddddddd",
                    },
                    my: 1,
                  }}
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
}
