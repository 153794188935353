import * as Yup from "yup";

// Payment Validation
export const paymentValidation = Yup.object().shape({
  commission: Yup.string("")
    .matches(/^\d+$/, "Enter valid commission")
    .required("Please enter commission."),
  gst: Yup.string("")
    .matches(/^\d+$/, "Enter valid GST")
    .required("Please enter GST."),
  tds: Yup.string("")
    .matches(/^\d+$/, "Enter valid TDS")
    .required("Please enter TDS."),
  totalAmount: Yup.string("")
    .matches(/^\d+$/, "Enter valid amount")
    .required("Please enter total amount."),
  remarks: Yup.string("").required("Please enter remarks."),
});

export const paymentValidationManual = Yup.object().shape({
  commission: Yup.string("")
    .matches(/^\d+$/, "Enter valid commission")
    .required("Please enter commission."),
  gst: Yup.string("")
    .matches(/^\d+$/, "Enter valid GST")
    .required("Please enter GST."),
  tds: Yup.string("")
    .matches(/^\d+$/, "Enter valid TDS")
    .required("Please enter TDS."),
  totalAmount: Yup.string("")
    .matches(/^\d+$/, "Enter valid amount")
    .required("Please enter total amount."),
  remarks: Yup.string("").required("Please enter remarks."),
  referenceNumber: Yup.string("").required("Please enter reference number."),
});

// Payment Validation
export const rejectValidation = Yup.object().shape({
  remarks: Yup.string("").required("Please enter remarks."),
});
