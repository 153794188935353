import React, { useEffect, useState } from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";
import { statusButton } from "../../constants/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeFilterLoader } from "../../redux/slices/filterSlice";

export default function StatusDropdown(props) {
  const [activeStatus, setActiveStatus] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { filterLoader } = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const status = queryParams.get("status");

    if (status) {
      setActiveStatus(status);
    } else {
      setActiveStatus(status);
      queryParams.delete("status");
    }
  }, []);

  const handleFilter = (item) => {
    queryParams.set("status", item);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const statusItems = [
    "Closed",
    "Approved",
    "Interested",
    "Rejected",
    "Fresh Lead",
    "ALL",
  ];

  return (
    <Box>
      <Button
        variant="outlined"
        size="small"
        endIcon={<IoIosArrowDown size={14} />}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={statusButton}
      >
        {activeStatus ? activeStatus : "Status"}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {statusItems.map((item, index) => (
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setActiveStatus(item);
              handleFilter(item);
              dispatch(changeFilterLoader(true));
              // setTotalPage(1);
            }}
            sx={{ fontSize: "0.875rem" }}
            key={index}
          >
            {item}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
