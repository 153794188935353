import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Formik } from "formik";
import styles from "./Auth.module.scss";
import { useLocation } from "react-router-dom";
import AuthAPI from "../../services/auth-api";
import { useDispatch } from "react-redux";
import { addUserInfo, handleUserLoggedIn } from "../../redux/slices/userSlice";
import { storeToken, storeUserId } from "../../utils/storage";
import { loginOtpValidation } from "./Auth.validation";

export default function LoginOTP() {
  const location = useLocation();
  const dispatch = useDispatch();

  // const { email, requestId } = location.state || {};

  const { tempEmail, data } = location.state || {};

  const { verifyOTP } = new AuthAPI();

  // console.log("tempEmail------------>>>>", tempEmail);

  const initialValues = {
    otp: "",
  };

  return (
    <Box className={styles.auth__styles}>
      <Container maxWidth="md">
        <Grid container spacing={4}>
          <Grid size={12}>
            <Box className={styles.box}>
              <Box className={styles.form}>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  // validationSchema={loginOtpValidation}
                  onSubmit={(
                    values,
                    { setSubmitting, setFieldError, resetForm }
                  ) => {
                    setSubmitting(true);
                    setTimeout(async () => {
                      const res = await verifyOTP(tempEmail, data, values.otp);

                      if (res.ok) {
                        const { token, ...userData } = res.data;
                        storeToken(res.data.token);
                        storeUserId(userData.id);
                        dispatch(addUserInfo(userData));
                        dispatch(handleUserLoggedIn(true));
                        window.location.href = "/";
                      } else {
                        setFieldError("otp", res.err);
                      }

                      setSubmitting(false);
                    }, 500);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box sx={{ mb: 3 }}>
                        <img src="/images/logo2.png" alt="" />
                        <Typography variant="h2">Submit OTP</Typography>
                        <Typography variant="body2">
                          Welcome to Ram Fincorp - Your Partner in Financial
                          Growth
                        </Typography>
                      </Box>

                      <Grid container spacing={3}>
                        <Grid size={12}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            size="small"
                            name="otp"
                            label="Enter OTP"
                            variant="outlined"
                            helperText={touched.otp && errors.otp}
                            error={touched.otp && errors.otp}
                            value={values.otp}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>

                        <Grid size={12} textAlign="center">
                          <Button
                            color="primary"
                            // disabled={true}
                            variant="contained"
                            fullWidth
                            type="submit"
                            size="large"
                            endIcon={
                              isSubmitting && (
                                <CircularProgress
                                  size="1.4rem"
                                  style={{ color: "#ffffff", marginLeft: 6 }}
                                />
                              )
                            }
                            sx={{
                              "&.Mui-disabled": {
                                color: "#333333",
                                background: "#ddddddd",
                              },
                              width: 200,
                              margin: 2,
                              textTransform: "capitalize",
                            }}
                          >
                            Request OTP
                          </Button>

                          <Typography typography="body2">
                            OTP sent to +91 {data.phoneNo} and {data.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Box>
              <Box className={styles.images}>
                <img src="/images/login-image.jpg" alt="" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
