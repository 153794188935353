import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterUpdate: false,
  filterLoader: false,
  totalPages: null,
  pageNumber: 1,
  updateDateRange: false,
};

export const userSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setFilterUpdate: (state, action) => {
      state.filterUpdate = action.payload;
    },

    changeFilterLoader: (state, action) => {
      state.filterLoader = action.payload;
    },

    getTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },

    changePageNumber: (state, action) => {
      state.pageNumber = action.payload;
    },

    setUpdateDateRange: (state, action) => {
      state.updateDateRange = action.payload;
    },
  },
});

export const {
  setFilterUpdate,
  changeFilterLoader,
  getTotalPages,
  changePageNumber,
  setUpdateDateRange,
} = userSlice.actions;

export default userSlice.reducer;
