import React, { useState, useEffect, useRef } from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import { IconButton } from "@mui/material";
import { IoSettingsOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import UserAPI from "../../../services/profile-api";

export default function Actions({ data }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const { updateUserStatusAPI } = new UserAPI();

  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const options = ["Activate", "Deactivate", "BlackList", "Edit", "View"];

  const handleActions = (item) => {
    // console.log("item--------------", item);

    switch (item) {
      case "Activate":
        navigate(`/profile?id=${data.id}`);
        break;

      case "Deactivate":
        handleStatus(item);
        break;

      case "BlackList":
        handleStatus(item);
        break;

      case "Edit":
        navigate(`/profile?id=${data.id}`);
        break;

      case "View":
        navigate(`/${data.id}?type=details`);
        break;

      default:
        console.log("hi");
    }
  };

  const handleStatus = async (action) => {
    const status = action == "Deactivate" ? "INACTIVE" : "BLACKLISTED";

    const res = await updateUserStatusAPI(data.id, status);

    if (res.ok) {
      Swal.fire({
        icon: "success",
        text: "Status Update Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      Swal.fire({
        icon: "error",
        text: res.err,
        showConfirmButton: false,
        timer: 3000,
      });
    }

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  //   console.log("data----------", data);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <IconButton
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <IoSettingsOutline />
        </IconButton>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{
            zIndex: "999",
          }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {options.map((item, index) => (
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e);
                          handleActions(item);
                        }}
                        key={index}
                        sx={{ fontSize: 15 }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
