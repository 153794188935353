import React from "react";
import "./Loader.scss";

export default function FilterLoader() {
  return (
    <div className="filter__loader">
      <img src="/images/loading-gif.gif" width="50" alt="" />
    </div>
  );
}
