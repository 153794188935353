// Storage

const storeToken = (token) => {
  try {
    localStorage.setItem("token", token);
  } catch (error) {
    console.log("Error storing in token", error);
  }
};

const getToken = () => {
  try {
    return localStorage.getItem("token");
  } catch (error) {
    console.log("Error getting in token", error``);
  }
};

const storeUserId = (userId) => {
  try {
    localStorage.setItem("userId", userId);
  } catch (error) {
    console.log("Error storing in userId", error);
  }
};

const getUserId = () => {
  try {
    return localStorage.getItem("userId");
  } catch (error) {
    console.log("Error getting in userId", error``);
  }
};

export { storeToken, getToken, storeUserId, getUserId };
