import React from "react";
import { Box, Button, Card, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./UserInfo.scss";
import { AiOutlineUser } from "react-icons/ai";

import { PiPhoneLight, PiEnvelopeOpenThin } from "react-icons/pi";
import UserAPI from "../../services/profile-api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function UserInfo({ data }) {
  const { updateUserStatusAPI } = new UserAPI();

  const navigate = useNavigate();

  const handleInactive = async () => {
    const confirmSubmit = window.confirm(
      "Are you sure you to De-activate this user?"
    );

    if (!confirmSubmit) {
      return;
    }

    const res = await updateUserStatusAPI(data.id, "INACTIVE");
    if (res.ok) {
      Swal.fire({
        icon: "success",
        text: "Status Update Successfully",
        showConfirmButton: false,
        timer: 3000,
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  return (
    <Box className="user-info">
      <Card elevation={0} sx={{ p: 2, borderRadius: 3 }}>
        <Grid container spacing={4}>
          <Grid size={3.5}>
            <Box>
              <Typography variant="h3">{data.name}</Typography>
              <Box className="user-id">
                <AiOutlineUser color="#4e4e4e" />
                <Box component="span">ID: {data.internalUserId}</Box>
              </Box>
            </Box>
          </Grid>

          <Grid size={3}>
            <Box className="info-box">
              <Typography variant="h6">
                {data?.profile?.aadhaarNumber}
              </Typography>
              <Box className="info-detail">
                <Box component="span">Aadhaar Number</Box>
                {/* <Button variant="outlined" size="small" sx={smallButton}>
                  Verify
                </Button> */}
              </Box>
            </Box>
          </Grid>

          <Grid size={3}>
            <Box className="info-box">
              <Typography variant="h6">{data?.profile?.PAN}</Typography>
              <Box className="info-detail" sx={{ mt: 0.8 }}>
                <Box component="span">PAN Number</Box>
                {/* <FaCheck color="#28A745" /> */}
              </Box>
            </Box>
          </Grid>

          <Grid size={2.5}>
            {data.status === "ACTIVE" ? (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  mt: 1.5,
                  borderRadius: 2,
                  border: "none",
                  py: 1.5,
                  textTransform: "capitalize",
                  backgroundColor: "#9b9b9b",
                  color: "#ffffff",
                }}
                onClick={() => handleInactive()}
              >
                De-activate
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                sx={{
                  mt: 1.5,
                  borderRadius: 2,
                  py: 1.5,
                  textTransform: "capitalize",
                }}
                onClick={() => navigate(`/profile?id=${data.id}`)}
              >
                Activate
              </Button>
            )}
          </Grid>
        </Grid>

        <Box className="bottom-info">
          <Grid container spacing={4}>
            {/* <Grid size={4}>
              <Box display="flex" alignItems="center">
                <PiCalendarDotsLight size={20} color="#4e4e4e" />
                <Box component="span">DOB: 24-Dec-1998</Box>
              </Box>
            </Grid> */}

            <Grid size={4}>
              <Box display="flex" alignItems="center">
                <PiPhoneLight size={20} color="#4e4e4e" />
                <Box component="span">Number: +91 {data.phone}</Box>
              </Box>
            </Grid>

            <Grid size={4}>
              <Box display="flex" alignItems="center">
                <PiEnvelopeOpenThin size={20} color="#4e4e4e" />
                <Box component="span">Email: {data.email}</Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  );
}
