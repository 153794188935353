import * as Yup from "yup";

// Profile Details Validation
export const profileValidation = (businessType) => {
  return Yup.object().shape({
    firstName: Yup.string("").required("First name is required."),
    pan: Yup.string()
      .matches(
        /^([A-Za-z]){3}[pP]([A-Za-z]){1}([0-9]){4}([A-Za-z]){1}$/,
        "Enter valid PAN."
      )
      .required("PAN is required"),

    // aadhaarNumber: Yup.string()
    //   .matches(
    //     /^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$/,
    //     "Enter valid aadhaar number"
    //   )
    //   .required("Enter aadhaar number"),

    city: Yup.string("").required("City is required."),
    state: Yup.string("").required("State is required."),
    pincode: Yup.string("").required("Pincode is required."),
    address: Yup.string("").required("Address is required."),

    gstNumber: Yup.string().when([], {
      is: () => businessType === "BUSINESS",
      then: (schema) =>
        schema
          .required("GST number is required.")
          .min(15, "GST number should be min 15 char.")
          .max(15, "GST number should be max 15 char."),
      otherwise: (schema) => schema.notRequired(),
    }),

    companyPAN: Yup.string().when([], {
      is: () => businessType === "BUSINESS",
      then: (schema) =>
        schema
          .matches(
            /^([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/,
            "Enter valid company PAN."
          )
          .required("Company PAN is required."),
      otherwise: (schema) => schema.notRequired(),
    }),

    // gstNumber: Yup.string("").required("GST number is required."),
    // companyPAN: Yup.string("").required("Company PAN is required."),

    bankAccountNumber: Yup.string("").required(
      "Bank account number is required."
    ),
    accountHolderName: Yup.string("").required(
      "Account holder name is required."
    ),

    bankIFSC: Yup.string("")
      .matches(/^[A-Za-z]{4}\d{7}$/, "Enter valid IFSC code.")
      .required("IFSC is required."),

    bankName: Yup.string("").required("Bank name is required."),

    verificationDocumentType: Yup.string("").required(
      "Document type is required."
    ),
  });
};
