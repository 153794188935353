import React from "react";
import { Box, Button, CircularProgress, TextField } from "@mui/material";
import {
  paymentValidation,
  paymentValidationManual,
} from "../../validations/payment.validation";
import { Formik } from "formik";
import Grid from "@mui/material/Grid2";
import CRMAPI from "../../services/crm-api";
import Swal from "sweetalert2";

export default function InitiatePaymentForm(props) {
  const { data, paymentMode, setOpen, setPaymentMode } = props;
  const { createPaymentAPI } = new CRMAPI();

  const initialValues = {
    commission: data.commissionAmount
      ? data.commissionAmount.replace(/,/g, "")
      : "",
    gst: data.gstAmount ? data.gstAmount.replace(/,/g, "") : "",
    tds: data.tdsAmount ? data.tdsAmount.replace(/,/g, "") : "",
    totalAmount: data.totalAmount ? data.totalAmount.replace(/,/g, "") : "",
    remarks: data.remarks || "",
    paymentMode: paymentMode || "",
    referenceNumber: "",
  };

  // const initialValues = {
  //   commission: "",
  //   gst: "",
  //   tds: "",
  //   totalAmount: "",
  //   remarks: "",
  //   paymentMode: "",
  //   referenceNumber: "",
  // };

  // console.log("paymentMode-----------", paymentMode);

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={
          paymentMode === "auto" ? paymentValidation : paymentValidationManual
        }
        onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
          setSubmitting(true);
          setTimeout(async () => {
            // console.log("values======", values);

            const res = await createPaymentAPI(values, data);

            // console.log("res-------", res);

            if (res.ok) {
              Swal.fire({
                icon: "success",
                text: "Payment Successfully",
                showConfirmButton: false,
                timer: 3000,
              });
              window.location.reload();
            } else {
              Swal.fire({
                icon: "error",
                text: res.err,
                showConfirmButton: false,
                timer: 3000,
              });
            }

            setOpen(false);
            setPaymentMode("");

            setSubmitting(false);
          }, 500);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                autoComplete="off"
                size="small"
                name="commission"
                label="Commission"
                variant="outlined"
                helperText={touched.commission && errors.commission}
                error={touched.commission && errors.commission}
                value={values.commission || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                // InputProps={{
                //   readOnly: true,
                // }}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                autoComplete="off"
                size="small"
                name="gst"
                label="GST (Auto calculate if DSA GST exists)"
                variant="outlined"
                helperText={touched.gst && errors.gst}
                error={touched.gst && errors.gst}
                value={values.gst || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                // InputProps={{
                //   readOnly: true,
                // }}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                autoComplete="off"
                size="small"
                name="tds"
                label="TDS (Autocalculate 10% of Commission)"
                variant="outlined"
                helperText={touched.tds && errors.tds}
                error={touched.tds && errors.tds}
                value={values.tds || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                // InputProps={{
                //   readOnly: true,
                // }}
              />
            </Box>

            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                autoComplete="off"
                size="small"
                name="totalAmount"
                label="Total Amount (Auto calculate)"
                variant="outlined"
                helperText={touched.totalAmount && errors.totalAmount}
                error={touched.totalAmount && errors.totalAmount}
                value={values.totalAmount || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                // type="number"
                // InputProps={{
                //   readOnly: paymentMode === "auto" ? true : false,
                // }}
              />
            </Box>

            {paymentMode === "manual" && (
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  size="small"
                  name="referenceNumber"
                  label="Reference Number"
                  variant="outlined"
                  helperText={touched.referenceNumber && errors.referenceNumber}
                  error={touched.referenceNumber && errors.referenceNumber}
                  value={values.referenceNumber || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>
            )}

            <Box sx={{ mb: 1 }}>
              <TextField
                multiline
                rows={3}
                fullWidth
                autoComplete="off"
                size="small"
                name="remarks"
                label="Remarks"
                variant="outlined"
                helperText={touched.remarks && errors.remarks}
                error={touched.remarks && errors.remarks}
                value={values.remarks || ""}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Box>

            <Grid container spacing={0}>
              <Grid size={6} offset={3}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  size="large"
                  endIcon={
                    isSubmitting && (
                      <CircularProgress
                        size="1.4rem"
                        style={{ color: "#ffffff", marginLeft: 6 }}
                      />
                    )
                  }
                  sx={{
                    "&.Mui-disabled": {
                      color: "#000",
                      background: "#ddddddd",
                    },
                    my: 1,
                  }}
                >
                  SUBMIT
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
}
