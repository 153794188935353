import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Layout from "../../layout/Layout";
import styles from "./Hold.module.scss";
import UserAPI from "../../services/profile-api";
import { useNavigate, useParams } from "react-router-dom";

export default function Hold() {
  const navigate = useNavigate();

  return (
    <Layout>
      <Box className={styles.hold}>
        <h3>Your Application Is Being Processed</h3>
        <p>
          Thank you for registering with Ram Fincorp and RK Bansal Finance
          Private Limited. Your application is being processed, and we will
          notify you via email if any discrepancies are found. Once approved,
          you'll receive an email confirmation and can log into the DSA portal.
        </p>
        <br />
        <p>
          Before proceeding, please review our Terms and Conditions and Privacy
          Policy to understand how we handle your data and your responsibilities
          while using the platform.
        </p>
        <br />
        <br />

        <Grid container spacing={3}>
          <Grid size={4} offset={4} textAlign="center">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              size="large"
              onClick={() => navigate("/commission")}
            >
              Go Back
            </Button>
          </Grid>
        </Grid>

        <br />

        <p>
          For any issues, please contact us at <strong>+91 98999-85495</strong>{" "}
          or email us at <strong>info@ramfncorp.com</strong>
        </p>
      </Box>
    </Layout>
  );
}
