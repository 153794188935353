import api, { apiData } from "../utils/api";
import { getUserId } from "../utils/storage";

class UserAPI {
  async getProfileAPI(id) {
    const userId = id || getUserId();

    try {
      const endpoint = `api/v1/user/?id=${userId}`;
      const req = {
        url: endpoint,
        method: "GET",
        data: {},
      };

      return await apiData(req);
    } catch (error) {
      console.log(error);
    }
  }

  async createProfileAPI(id, values) {
    const userId = id || getUserId();

    try {
      const endpoint = "api/v1/user/profile";
      const req = {
        userId: userId,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        PAN: values.pan.toUpperCase(),
        address: values.address,
        city: values.city,
        state: values.state,
        pincode: values.pincode,
        aadhaarNumber: "333256567878",
      };

      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async addIndividualAPI(id, businessType, values, panUrl) {
    const userId = id || getUserId();

    try {
      const endpoint = "api/v1/user/profile/business";

      const req = {
        userId: userId,
        businessType: businessType, // 'INDIVIDUAL', 'BUSINESS', null
        PAN: values.pan.toUpperCase(), // Optional
        panUrl: panUrl,
      };

      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async addBusinessAPI(id, businessType, values, companyPanUrl, gstUrl) {
    const userId = id || getUserId();

    try {
      const endpoint = "api/v1/user/profile/business";

      const req = {
        userId: userId,
        businessType: businessType,
        PAN: values.companyPAN.toUpperCase(),
        panUrl: companyPanUrl,
        GSTNumber: values.gstNumber.toUpperCase(),
        gstUrl: gstUrl,
      };

      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async generatePresignedURLAPI(file) {
    const type =
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ? "leads"
        : "document";

    try {
      const endpoint = "api/v1/media/upload/presigned-url";
      const req = {
        categoryName: type, // leads, documents
        fileName: `${getUserId()}/${file.name.replace(/\s+/g, "_")}`, // "userId/lead-demo.xlsx",
        contentType: file.type,
      };

      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async addBankAPI(id, values, bankUploadFile) {
    const userId = id || getUserId();

    try {
      const endpoint = "api/v1/user/profile/bank";
      const req = {
        userId: userId,
        accountHolderName: values.accountHolderName,
        bankAccountNumber: values.bankAccountNumber,
        bankName: values.bankName,
        IFSCCode: values.bankIFSC,
        verificationDocumentType: values.verificationDocumentType, //'Bank Statement', 'Cancelled Cheque'
        verificationDocumentUrl: bankUploadFile,
      };

      const { data } = await api.post(endpoint, req);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async commssionAPI(id, values, commissions) {
    const userId = id || getUserId();

    try {
      const endpoint = "api/v1/user/profile/commission";
      const req = {
        userId: userId,
        commissionType: values.commissionType, // disbursal | leads
        commissions: commissions.map((item) => {
          return {
            start: parseInt(item.start, 10),
            end: parseInt(item.end, 10),
            value: parseFloat(item.value),
          };
        }),
      };

      const { data } = await api.patch(endpoint, req);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  // Active Inactive User
  async updateUserStatusAPI(id, status, remarks = "", agreementText = "") {
    const userId = id || getUserId();
    try {
      const endpoint = "api/v1/user";
      const req = {
        userId: userId,
        status: status, // INACTIVE, ACTIVE // BLACKLISTED // AGREEMENT_SIGNED
        remarks: remarks,
        agreementText: agreementText,
      };

      const { data } = await api.patch(endpoint, req);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  // Get Uploaded List
  async getUploadedAPI() {
    try {
      const endpoint = `api/v1/dsa/lead/upload/list?userId=${getUserId()}`;
      const req = {};

      const { data } = await api.get(endpoint, req);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
}

export default UserAPI;
