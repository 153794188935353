// Download

export const downloadFiles = (url) => {
  const element = document.createElement("a");

  element.setAttribute("download", "");
  element.setAttribute("target", "_blank");
  element.setAttribute("href", url);
  document.body.appendChild(element);
  element.click();

  document.body.removeChild(element);
};
