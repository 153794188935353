import UserAPI from "../services/profile-api";

const { generatePresignedURLAPI } = new UserAPI();

// Get Download URL From Presigned URL
export async function getDownloadUrlFromPresignedUrl(presignedUrl) {
  const urlObj = new URL(presignedUrl);

  // Extract the bucket name from the hostname (e.g., "your-bucket-name.s3.amazonaws.com")
  const bucketName = urlObj.hostname.split(".")[0];

  // Extract the key from the pathname (removing leading '/')
  const key = urlObj.pathname.substring(1); // Remove leading '/'

  // Construct the download URL (this URL will work for publicly accessible objects)
  const downloadUrl = `https://${bucketName}.s3.amazonaws.com/${key}`;

  return downloadUrl;
}

// Filter Active Bank
export const filterActiveBank = (data) => {
  if (data?.length > 0) {
    const activeBank = data.filter((item) => item.isActive === true);
    return activeBank[0];
  } else {
    return null;
  }
};

// Upload PAN URL
export const uploadPanURL = async (file, setPanUrl) => {
  const res = await generatePresignedURLAPI(file);

  const presignedUrl = res.data.url;

  await fetch(presignedUrl, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
      "Content-Length": file.size,
    },
    body: file,
  });

  const downloadURL = await getDownloadUrlFromPresignedUrl(presignedUrl);

  if (downloadURL) {
    setPanUrl(downloadURL);
  }
};

// GST Document URL
export const gstDocURL = async (file, setGstUrl) => {
  const res = await generatePresignedURLAPI(file);

  const presignedUrl = res.data.url;

  await fetch(presignedUrl, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
      "Content-Length": file.size,
    },
    body: file,
  });

  const downloadURL = await getDownloadUrlFromPresignedUrl(presignedUrl);

  if (downloadURL) {
    setGstUrl(downloadURL);
  }
};

// GST Document URL
export const companyPANDocURL = async (file, setGstUrl) => {
  const res = await generatePresignedURLAPI(file);

  const presignedUrl = res.data.url;

  await fetch(presignedUrl, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
      "Content-Length": file.size,
    },
    body: file,
  });

  const downloadURL = await getDownloadUrlFromPresignedUrl(presignedUrl);

  if (downloadURL) {
    setGstUrl(downloadURL);
  }
};
