// Custom Styles

export const smallButton = {
  background: "#fff",
  textTransform: "capitalize",
  borderRadius: 2,
  fontSize: 12,
};

export const statusButton = {
  textTransform: "capitalize",
  fontSize: 12,
  py: 0.4,
};
