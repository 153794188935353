import React, { useEffect, useState } from "react";
import { Switch, FormControlLabel, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeFilterLoader } from "../../redux/slices/filterSlice";

export default function SwitchFilter(props) {
  const [checked, setChecked] = useState(true);

  const { filterLoader } = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const type = queryParams.get("leadFilter");
    if (type === "disbursal") {
      setChecked(false);
    }
  }, []);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    queryParams.set("leadFilter", event.target.checked ? "leads" : "disbursal");
    navigate(`${location.pathname}?${queryParams.toString()}`);
    dispatch(changeFilterLoader(!filterLoader));
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          size="small"
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label={
        <Typography sx={{ fontSize: "14px" }}>
          {checked ? "Leads" : "Disbursal"}
        </Typography>
      }
    />
  );
}
