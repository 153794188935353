import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import Layout from "../../layout/Layout";
import { FiEye } from "react-icons/fi";
import Loader from "../../components/Loader/Loader";
import CRMAPI from "../../services/crm-api";
import { MdOutlineCloudUpload } from "react-icons/md";
import styled from "@emotion/styled";
import { smallButton } from "../../constants/styles";
import UserAPI from "../../services/profile-api";
import { getDownloadUrlFromPresignedUrl } from "../../helpers/upload-helper";

import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import Swal from "sweetalert2";
import { downloadFiles } from "../../helpers/download";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Commissions(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalCommission, setTotalCommission] = useState(null);

  const { userStatus } = useSelector((state) => state.user);

  const navigate = useNavigate();

  // console.log("userStatus--------------", userStatus);

  const { getPayoutAPI } = new CRMAPI();

  const { generatePresignedURLAPI } = new UserAPI();
  const { uploadInvoiceAPI, downloadDocumentsAPI } = new CRMAPI();

  useEffect(() => {
    fetchPayoutDetails();
  }, [currentPage]);

  const fetchPayoutDetails = async () => {
    const res = await getPayoutAPI("", currentPage);
    if (res.ok) {
      setData(res.data.commissionReport);
      setTotalPage(res.data.totalPages);
      setTotalCommission(res.data.totalRows);
    }
    setLoading(false);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleUploadFile = async (file, commissionReportId) => {
    const res = await generatePresignedURLAPI(file);

    const presignedUrl = res.data.url;

    await fetch(presignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        "Content-Length": file.size,
      },
      body: file,
    });

    const downloadURL = await getDownloadUrlFromPresignedUrl(presignedUrl);

    if (downloadURL) {
      const res = await uploadInvoiceAPI("", commissionReportId, downloadURL);

      if (res.ok) {
        Swal.fire({
          icon: "success",
          text: "Upload Successfully!",
          showConfirmButton: false,
          timer: 2000,
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  };

  const downloadInvoice = async (url) => {
    const res = await downloadDocumentsAPI(url);

    if (res.ok) {
      downloadFiles(res.data.url);
    }
  };

  // console.log("data---------------", data);

  if (loading) {
    return <Loader />;
  }
  return (
    <Layout>
      <Card elevation={0} sx={{ py: 1, borderRadius: 3 }}>
        <CardHeader
          title={
            <Box className="header-title">
              <Typography variant="h4">Commission Report</Typography>
            </Box>
          }
        />

        <CardContent>
          <TableContainer component={Paper}>
            <Table aria-label="lead table">
              <TableHead>
                <TableRow>
                  <TableCell>Month</TableCell>
                  <TableCell>Invoice</TableCell>
                  <TableCell>Commission Amount</TableCell>
                  <TableCell>GST Amount</TableCell>
                  <TableCell>Total Amount</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {new Date(row.month).toDateString()}
                    </TableCell>
                    <TableCell>
                      {row.invoiceUrl && row.status !== "rejected" ? (
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<FiEye size={15} color="#585858" />}
                          sx={{
                            ...smallButton,
                            backgroundColor: "#ebebeb",
                            borderColor: "#ebebeb",
                            color: "#585858",
                            fontSize: 11,
                          }}
                          onClick={() => downloadInvoice(row.invoiceUrl)}
                        >
                          View
                        </Button>
                      ) : (
                        <>
                          {userStatus == "INACTIVE" ||
                          userStatus == "AGREEMENT_SIGNED" ? (
                            <Button
                              variant="contained"
                              startIcon={
                                <MdOutlineCloudUpload
                                  size={16}
                                  color="#585858"
                                />
                              }
                              onClick={() => {
                                if (userStatus == "AGREEMENT_SIGNED") {
                                  navigate("/hold");
                                } else {
                                  navigate("/agreement");
                                }
                              }}
                              sx={{
                                ...smallButton,
                                backgroundColor: "#ebebeb",
                                borderColor: "#ebebeb",
                                background: "#ddd",
                                boxShadow: "none",
                                color: "#585858",
                                fontSize: "11px",
                              }}
                              size="small"
                            >
                              Upload
                            </Button>
                          ) : (
                            <Button
                              component="label"
                              size="small"
                              role={undefined}
                              variant="outlined"
                              tabIndex={-1}
                              startIcon={
                                <MdOutlineCloudUpload
                                  size={16}
                                  color="#585858"
                                />
                              }
                              sx={{
                                ...smallButton,
                                backgroundColor: "#ebebeb",
                                borderColor: "#ebebeb",
                              }}
                            >
                              <Typography
                                sx={{ fontSize: 11, color: "#2e2e2e" }}
                              >
                                Upload
                              </Typography>
                              <VisuallyHiddenInput
                                type="file"
                                onChange={(event) =>
                                  handleUploadFile(
                                    event.target.files[0],
                                    row.id
                                  )
                                }
                              />
                            </Button>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell>₹{row.commissionAmount}</TableCell>
                    <TableCell>₹{row.gstAmount}</TableCell>
                    <TableCell>₹{row.totalAmount}</TableCell>
                    <TableCell>{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            sx={{ mt: 4 }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              {totalCommission > 0 && (
                <Typography variant="body2">
                  Total Leads: <strong>{totalCommission}</strong>
                </Typography>
              )}
            </Box>
            <Box>
              <ResponsivePagination
                current={currentPage}
                total={totalPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Layout>
  );
}
