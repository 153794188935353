import React, { useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import { useDispatch } from "react-redux";
import { handleUserLoggedIn } from "../../redux/slices/userSlice";

export default function Logout() {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();

    setTimeout(() => {
      dispatch(handleUserLoggedIn(false));
      window.location.href = "/";
    }, 500);
  }, []);

  return (
    <div>
      <Loader />
    </div>
  );
}
