import moment from "moment/moment";
import { API_URL, apiData } from "../utils/api";
import { getUserId } from "../utils/storage";
import { getFilterDays } from "../helpers/date-format";

class CRMAPI {
  // Get DSA Users
  async getDsaUserAPI(page) {
    const req = {
      url: `api/v1/user/dsa/list?pageSize=10&pageNumber=${page}`,
      method: "GET",
      data: {},
    };

    return await apiData(req);
  }

  // Get Dashboard Report
  async getDashboardReportAPI(id) {
    const userId = id || getUserId();

    const dataParams = new URLSearchParams(window.location.search);

    const startDate =
      dataParams.get("startDate") || moment(new Date()).format("YYYY-MM-01");
    const endDate =
      dataParams.get("endDate") || moment(new Date()).format("YYYY-MM-DD");
    const leadFilter = dataParams.get("leadFilter") || "leads";

    const req = {
      url: `api/v1/dsa/report?userId=${userId}&startDate=${startDate}&endDate=${endDate}&leadFilter=${leadFilter}`,
      method: "GET",
      data: {},
    };

    return await apiData(req);
  }

  // Get Lead Lists
  async getLeadListAPI(id, page) {
    const userId = id || getUserId();

    const params = new URLSearchParams(window.location.search);

    const startDate =
      params.get("startDate") || moment(new Date()).format("YYYY-MM-01");
    const endDate =
      params.get("endDate") || moment(new Date()).format("YYYY-MM-DD");
    const status = params.get("status") || "All";
    const leadFilter = params.get("leadFilter") || "leads";

    const req = {
      url: `api/v1/dsa/leads/list?userId=${userId}&startDate=${startDate}&endDate=${endDate}&pageNumber=${page}&pageSize=10&status=${status}&leadFilter=${leadFilter}`,
      method: "GET",
      data: {},
    };

    return await apiData(req);
  }

  // Comission Payout
  async getPayoutAPI(id, page) {
    const userId = id || getUserId();

    const startDate = getFilterDays();
    const endDate = moment(new Date()).format("YYYY-MM-DD");

    const req = {
      url: `api/v1/dsa/commission/reports?userId=${userId}&pageNumber=${page}&pageSize=2&startDate=${startDate}&endDate=${endDate}`,
      method: "GET",
      data: {},
    };

    return await apiData(req);
  }

  // Upload Invoice
  async uploadInvoiceAPI(id, commissionReportId, fileUrl) {
    const userId = id || getUserId();

    const req = {
      url: `api/v1/dsa/commission/upload-invoice`,
      method: "POST",
      data: {
        userId: userId,
        commissionReportId: commissionReportId,
        fileUrl: fileUrl,
      },
    };

    return await apiData(req);
  }

  // Leads Download URL
  leadsDownloadURL(id) {
    const userId = id || getUserId();

    const dataParams = new URLSearchParams(window.location.search);

    const startDate =
      dataParams.get("startDate") || moment(new Date()).format("YYYY-MM-01");
    const endDate =
      dataParams.get("endDate") || moment(new Date()).format("YYYY-MM-DD");
    const status = dataParams.get("status") || "All";

    return `${API_URL}api/v1/dsa/leads/list/download?userId=${userId}&startDate=${startDate}&endDate=${endDate}&status=${status}`;
  }

  // Leads Download API
  async leadsDownloadAPI(id) {
    const userId = id || getUserId();

    const dataParams = new URLSearchParams(window.location.search);

    const startDate =
      dataParams.get("startDate") || moment(new Date()).format("YYYY-MM-01");
    const endDate =
      dataParams.get("endDate") || moment(new Date()).format("YYYY-MM-DD");
    const status = dataParams.get("status") || "All";

    const finalURL = `${API_URL}api/v1/dsa/leads/list/download?userId=${userId}&startDate=${startDate}&endDate=${endDate}&status=${status}`;

    const req = {
      url: finalURL,
      method: "GET",
      data: {},
    };

    return await apiData(req);
  }

  // Upload Leads
  async uploadLeadsAPI(fileUrl) {
    const req = {
      url: `api/v1/dsa/lead/upload`,
      method: "POST",
      data: {
        userId: getUserId(),
        fileUrl: fileUrl,
      },
    };

    return await apiData(req);
  }

  // Create Payment
  async createPaymentAPI(values, data) {
    const req = {
      url: `api/v1/dsa/commission/create-payment`,
      method: "POST",
      data: {
        commissionReportId: data.id,
        paymentMode: values.paymentMode, // manual | auto
        amount: Number(values.totalAmount),
        remarks: values.remarks,
        referenceNumber: values.referenceNumber || "",

        commissionAmount: Number(values.commission),
        gstAmount: Number(values.gst),
        tdsAmount: Number(values.tds),
      },
    };

    return await apiData(req);
  }

  // Update Commission Status
  async updateCommissionStatusAPI(id, status, remarks) {
    const req = {
      url: `api/v1/dsa/commission/update-status`,
      method: "POST",
      data: {
        commissionReportId: id,
        status: status, // 'pending' | 'under_review' | 'approved' | 'rejected' | 'paid'
        remarks: remarks,
      },
    };

    return await apiData(req);
  }

  // Download Uploaded Leads
  async downloadUploadedLeadsAPI(leadFileId, status) {
    const req = {
      url: `api/v1/dsa/lead/upload/list/download?leadFileId=${leadFileId}&status=${status}`,
      method: "GET",
      data: {},
    };

    return await apiData(req);
  }

  // Download Documents
  async downloadDocumentsAPI(url) {
    const req = {
      url: `api/v1/dsa/commission/download-document?url=${url}`,
      method: "GET",
      data: {},
    };

    return await apiData(req);
  }
}

export default CRMAPI;
