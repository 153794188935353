import React, { useEffect, useState } from "react";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { LuSearch } from "react-icons/lu";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { IoNotificationsOutline } from "react-icons/io5";
import styles from "./Header.module.scss";
import { Link, useNavigate } from "react-router-dom";
import UserAPI from "../../services/profile-api";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserName,
  addUTMLink,
  setDSACommissionType,
  setUserStatus,
} from "../../redux/slices/userSlice";

export default function Header() {
  const { userName, user } = useSelector((state) => state.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { getProfileAPI } = new UserAPI();

  useEffect(() => {
    fetchUserAPI();
  }, []);

  const fetchUserAPI = async () => {
    const res = await getProfileAPI();
    if (res.ok) {
      const data = {
        name: res?.data?.name?.split(" ")[0],
        adminId: res?.data?.internalUserId,
      };

      dispatch(addUserName(data));
      dispatch(addUTMLink(res.data.utmLink));
      dispatch(setDSACommissionType(res?.data?.profile?.commissionType));
      dispatch(setUserStatus(res.data.status));
    }
  };

  return (
    <>
      <Box className={styles.mantra}>ॐ नमो भगवते वासुदेवाय नमः</Box>
      <Box className={styles.header__styles}>
        {/* <Box className={styles.search}>
          <form>
            <input type="text" placeholder="Search" />
            <select>
              <option>Mobile</option>
              <option>Aadhaar</option>
            </select>
          </form>
          <button>
            <LuSearch />
          </button>
        </Box> */}
        <Box className={styles.user_info}>
          <Box className={styles.notification}>
            <Box>
              <Box className={styles.dot} />
              <IoNotificationsOutline size={20} />
            </Box>
          </Box>
          <Box display="flex" alignItems="flex-end">
            <Box
              display="flex"
              alignItems="flex-end"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                if (user.role === "DSA") navigate("/profile");
              }}
            >
              <Avatar
                src="https://mui.com/static/images/avatar/1.jpg"
                sx={{ width: 45, height: 45 }}
                alt=""
              />
              <Box className={styles.user_name}>
                <Typography variant="h6">{userName?.name}</Typography>
                <Box>
                  <Box className={styles.adminId}>
                    Admin ID: {userName?.adminId}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Link to="/logout" className={styles.logout}>
              <RiLogoutCircleRLine color="#222222" size={22} />
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
}
