import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import InitiatePaymentForm from "./InitiatePaymentForm";
import RejectPaymentForm from "./RejectPaymentForm";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function PaymentModal(props) {
  const { data } = props;
  const [open, setOpen] = useState(false);
  const [paymentMode, setPaymentMode] = useState("");

  console.log("paymentMode-------", paymentMode);

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        size="small"
        sx={{ textTransform: "capitalize" }}
        onClick={() => setOpen(true)}
      >
        Pay Now
      </Button>
      <BootstrapDialog
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Payout Payment
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box sx={{ mb: 2 }}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="payment-select-label">
                Select Payment Type
              </InputLabel>
              <Select
                labelId="payment-select-label"
                id="payment-simple-select"
                label="Select Payment Type"
                onChange={(e) => setPaymentMode(e.target.value)}
              >
                <MenuItem value="">
                  <i>Select Payment</i>
                </MenuItem>
                <MenuItem value="auto">Automatic</MenuItem>
                <MenuItem value="manual">Manual</MenuItem>
                <MenuItem value="rejected">Reject</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {(paymentMode === "auto" || paymentMode === "manual") && (
            <InitiatePaymentForm
              data={data}
              paymentMode={paymentMode}
              setOpen={setOpen}
              setPaymentMode={setPaymentMode}
            />
          )}

          {paymentMode == "rejected" && (
            <RejectPaymentForm
              id={data.id}
              setOpen={setOpen}
              setPaymentMode={setPaymentMode}
            />
          )}
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
