import * as Yup from "yup";

// Login Validation
export const loginValidation = Yup.object().shape({
  email: Yup.string().email("Invalid email Id").required("Email is required"),
});

export const loginOtpValidation = Yup.object().shape({
  otp: Yup.string().required("OTP is required"),
});

// Register Validation
export const registerValidation = Yup.object().shape({
  phone: Yup.string("").required("Please enter phone."),
  email: Yup.string().email("Invalid email Id").required("Email is required"),
});
