import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Layout from "../../layout/Layout";
import { Link, useNavigate } from "react-router-dom";
import CRMAPI from "../../services/crm-api";
import Loader from "../../components/Loader/Loader";
import moment from "moment";
import Actions from "./Actions/Actions";

import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import FilterLoader from "../../components/Loader/FilterLoader";

export default function CRMDSAUser() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);

  const navigate = useNavigate();

  const { getDsaUserAPI } = new CRMAPI();

  useEffect(() => {
    fetchDsaUser();
  }, [currentPage]);

  const fetchDsaUser = async () => {
    const res = await getDsaUserAPI(currentPage);
    if (res.ok) {
      setData(res.data.users);
      setTotalPage(res.data.totalPages);
      setTotalUsers(res.data.totalRows);
    }
    setLoading(false);
    setFilterLoader(false);
  };

  // console.log("data--------->>>>>", data);

  if (loading) {
    return <Loader />;
  }
  return (
    <Layout>
      <Card elevation={0} sx={{ py: 1, borderRadius: 3 }}>
        <CardHeader
          title={
            <Box className="header-title">
              <Typography variant="h4">DSA Selling Agent</Typography>
            </Box>
          }
        />
        <CardContent>
          <TableContainer component={Paper}>
            <Table aria-label="lead table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Date Added</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Link to={`/${row.id}?type=details`}>{row.name}</Link>
                    </TableCell>
                    <TableCell>
                      <Link to={`/${row.id}?type=details`}>{row.phone}</Link>
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>
                      {moment(row.createdAt).format("DD-MM-YYYY")}
                    </TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>
                      <Actions data={row} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            sx={{ mt: 4 }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              {totalUsers > 0 && (
                <Typography variant="body2">
                  Total DSA: <strong>{totalUsers}</strong>
                </Typography>
              )}
            </Box>
            <Box>
              <ResponsivePagination
                current={currentPage}
                total={totalPage}
                onPageChange={(page) => {
                  setCurrentPage(page);
                  setFilterLoader(true);
                }}
                maxWidth={400}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>

      {filterLoader && <FilterLoader />}
    </Layout>
  );
}
