import React from "react";
import { Box } from "@mui/material";
import Drawer from "./Drawer";
import Header from "../components/Header/Header";
// import Header from "./Header";

export default function Layout({ children }) {
  return (
    <Box display="flex">
      <Drawer />
      <Box
        component="main"
        className="main-layout"
        sx={{ px: 2, py: 1, marginLeft: { md: 30 } }}
      >
        <Header />
        {children}
      </Box>
    </Box>
  );
}
