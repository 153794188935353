import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Layout from "../../layout/Layout";
import { Formik } from "formik";
import Grid from "@mui/material/Grid2";
import { MdCloudUpload } from "react-icons/md";
import styled from "@emotion/styled";
import { profileValidation } from "./Profile.validation";
import UserAPI from "../../services/profile-api";
import {
  companyPANDocURL,
  filterActiveBank,
  getDownloadUrlFromPresignedUrl,
  gstDocURL,
  uploadPanURL,
} from "../../helpers/upload-helper";

import { useNavigate } from "react-router-dom";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { downloadFiles } from "../../helpers/download";
import CRMAPI from "../../services/crm-api";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import Commission from "./Commission/Commission";

export default function Profile() {
  const [data, setData] = useState(null);

  const [businessType, setBusinessType] = useState("INDIVIDUAL");
  const [panUrl, setPanUrl] = useState("");
  const [companyPanUrl, setCompanyPanUrl] = useState("");
  const [gstUrl, setGstUrl] = useState("");

  const [bankUploadFile, setBankUploadFile] = useState("");

  const [commissions, setCommissions] = useState([
    {
      start: "",
      end: "",
      value: "",
    },
  ]);

  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const query = new URLSearchParams(window.location.search);
  const userID = query.get("id");

  const {
    getProfileAPI,
    createProfileAPI,
    addIndividualAPI,
    addBusinessAPI,
    generatePresignedURLAPI,
    addBankAPI,
    commssionAPI,
    updateUserStatusAPI,
  } = new UserAPI();

  const { downloadDocumentsAPI } = new CRMAPI();

  useEffect(() => {
    fetchProfileAPI();
  }, []);

  const activeBank = filterActiveBank(data?.profile?.bankDetails);
  const bankUploadedFile = activeBank?.verificationDocumentUrl;

  const fetchProfileAPI = async () => {
    const id = query.get("id");
    const res = await getProfileAPI(id);

    if (res.ok) {
      // Update Business Type
      const businessType = res.data?.profile?.businesses[0]?.businessType;
      if (businessType) {
        setBusinessType(businessType);
      }

      // Update PAN URL
      const panUrl = res.data?.profile?.businesses[0]?.panUrl;
      if (panUrl) {
        setPanUrl(panUrl);
        setCompanyPanUrl(panUrl);
      }

      // Update GST URL
      const gstUrl = res.data?.profile?.businesses[0]?.gstUrl;
      if (gstUrl) {
        setGstUrl(gstUrl);
      }

      // Get Commissions
      const commissions = res.data?.profile?.commissions;
      if (commissions?.length > 0) {
        setCommissions(commissions);
      }

      setData(res.data);
    }
  };

  useEffect(() => {
    if (bankUploadedFile) {
      setBankUploadFile(bankUploadedFile);
    }
  }, [activeBank]);

  const updatedCommisionValue =
    data?.profile?.commissionType === "disbursal"
      ? data?.profile?.commissionDisbursalPercentage
      : data?.profile?.commissionPerLeadPrice;

  const initialValues = {
    // Personal Details
    firstName: data?.profile?.firstName || "",
    middleName: data?.profile?.middleName || "",
    lastName: data?.profile?.lastName || "",
    pan: data?.profile?.PAN || "",
    aadhaarNumber: data?.profile?.aadhaarNumber || "",
    city: data?.profile?.city || "",
    state: data?.profile?.state || "",
    pincode: data?.profile?.pincode || "",
    address: data?.profile?.address || "",

    // Business Details
    gstNumber: data?.profile?.businesses[0]?.GSTNumber || "",
    companyPAN: data?.profile?.businesses[0]?.pan || "",

    // Bank Details
    bankAccountNumber: activeBank?.bankAccountNumber || "",
    accountHolderName: activeBank?.accountHolderName || "",
    bankIFSC: activeBank?.IFSCCode || "",
    bankName: activeBank?.bankName || "",
    verificationDocumentType: activeBank?.verificationDocumentType || "",

    // Commission Details
    commissionType: data?.profile?.commissionType || "",
    // commissionValue: updatedCommisionValue || "",
    commissionValue: "",
    commissionStart: "",
    commissionEnd: "",
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const uploadBankFile = async (file) => {
    const res = await generatePresignedURLAPI(file);

    const presignedUrl = res.data.url;

    await fetch(presignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        "Content-Length": file.size,
      },
      body: file,
    });

    const downloadURL = await getDownloadUrlFromPresignedUrl(presignedUrl);

    if (downloadURL) {
      setBankUploadFile(downloadURL);
    }
  };

  const viewUploadedDocs = async (url) => {
    const res = await downloadDocumentsAPI(url);

    if (res.ok) {
      downloadFiles(res.data.url);
    } else {
      Swal.fire({
        icon: "error",
        text: res.err,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  // console.log("activeBank------------------>>>>>>", activeBank);

  // console.log("commissions-------------", commissions);

  return (
    <Layout>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={profileValidation(businessType)}
        onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
          setSubmitting(true);
          setTimeout(async () => {
            // console.log("values---------", values);

            if (userID) {
              const confirmSubmit = window.confirm(
                "Have you verified all details, If yes please click on activate?"
              );

              if (!confirmSubmit) {
                setSubmitting(false);
                return;
              }
            }

            const userId = query.get("id");

            const bankFile = bankUploadFile || bankUploadedFile;

            const profileRes = await createProfileAPI(userId, values);

            const [businessRes, bankRes, commssionRes, statusRes] =
              await Promise.all([
                businessType === "INDIVIDUAL"
                  ? addIndividualAPI(userId, businessType, values, panUrl)
                  : addBusinessAPI(
                      userId,
                      businessType,
                      values,
                      companyPanUrl,
                      gstUrl
                    ),

                addBankAPI(userId, values, bankFile),

                user.role === "Admin"
                  ? commssionAPI(userId, values, commissions)
                  : Promise.resolve(null),

                user.role === "Admin"
                  ? updateUserStatusAPI(userId, "ACTIVE")
                  : Promise.resolve(null),
              ]);

            // const [profileRes, businessRes, bankRes, commssionRes, statusRes] =
            //   await Promise.all([
            //     createProfileAPI(userId, values),

            //     businessType === "INDIVIDUAL"
            //       ? addIndividualAPI(userId, businessType, values, panUrl)
            //       : addBusinessAPI(
            //           userId,
            //           businessType,
            //           values,
            //           companyPanUrl,
            //           gstUrl
            //         ),

            //     addBankAPI(userId, values, bankFile),

            //     user.role === "Admin"
            //       ? commssionAPI(userId, values, commissions)
            //       : Promise.resolve(null),

            //     user.role === "Admin"
            //       ? updateUserStatusAPI(userId, "ACTIVE")
            //       : Promise.resolve(null),
            //   ]);

            // console.log("Profile Response---------", profileRes);
            // console.log("Business Response--------", businessRes);
            // console.log("Bank Response------------", bankRes);
            // console.log("Commision Response------------", commssionRes);
            // console.log("statusRes Response------------", statusRes);

            if (profileRes.ok && businessRes.ok && bankRes.ok) {
              if (user.role === "Admin") {
                window.location.reload();
              } else {
                navigate("/agreement");
              }
            }

            setSubmitting(false);
          }, 500);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card elevation={0} sx={{ py: 1, borderRadius: 3 }}>
              {/* PERSONAL DETAILS */}
              <CardContent>
                <Box className="header-title" sx={{ mb: 2 }}>
                  <Typography variant="h4">Personal Details</Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid size={4}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="firstName"
                      label="First Name"
                      variant="outlined"
                      helperText={touched.firstName && errors.firstName}
                      error={touched.firstName && errors.firstName}
                      value={values.firstName || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid size={4}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="middleName"
                      label="Middle Name"
                      variant="outlined"
                      helperText={touched.middleName && errors.middleName}
                      error={touched.middleName && errors.middleName}
                      value={values.middleName || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid size={4}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="lastName"
                      label="Last Name"
                      variant="outlined"
                      helperText={touched.lastName && errors.lastName}
                      error={touched.lastName && errors.lastName}
                      value={values.lastName || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid size={4}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="pan"
                      label="PAN Number"
                      variant="outlined"
                      helperText={touched.pan && errors.pan}
                      error={touched.pan && errors.pan}
                      value={values.pan || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                  </Grid>

                  {/* <Grid size={4}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="aadhaarNumber"
                      label="Aadhaar Number"
                      variant="outlined"
                      helperText={touched.aadhaarNumber && errors.aadhaarNumber}
                      error={touched.aadhaarNumber && errors.aadhaarNumber}
                      value={values.aadhaarNumber || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid> */}

                  <Grid size={4}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="city"
                      label="City"
                      variant="outlined"
                      helperText={touched.city && errors.city}
                      error={touched.city && errors.city}
                      value={values.city || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid size={4}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="state"
                      label="State"
                      variant="outlined"
                      helperText={touched.state && errors.state}
                      error={touched.state && errors.state}
                      value={values.state || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid size={4}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="pincode"
                      label="Pincode"
                      variant="outlined"
                      helperText={touched.pincode && errors.pincode}
                      error={touched.pincode && errors.pincode}
                      value={values.pincode || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid size={4}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="address"
                      label="Address"
                      variant="outlined"
                      helperText={touched.address && errors.address}
                      error={touched.address && errors.address}
                      value={values.address || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              {/* BUSINESS DETAILS */}
              <CardContent>
                <Box className="header-title" sx={{ mb: 2 }}>
                  <Typography variant="h4">Business Details</Typography>
                </Box>

                <Grid container spacing={3}>
                  <Grid size={12}>
                    <Typography variant="body1">
                      <strong>Are You?</strong>
                    </Typography>

                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={businessType}
                        onChange={(e) => setBusinessType(e.target.value)}
                      >
                        <FormControlLabel
                          value="INDIVIDUAL"
                          control={<Radio size="small" />}
                          label="Individual"
                        />
                        <FormControlLabel
                          value="BUSINESS"
                          control={<Radio size="small" />}
                          label="Business"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {businessType === "INDIVIDUAL" && (
                    <Grid size={6}>
                      <Box>
                        <Button
                          component="label"
                          role={undefined}
                          variant="outlined"
                          tabIndex={-1}
                          startIcon={<MdCloudUpload />}
                          sx={{ textTransform: "capitalize" }}
                        >
                          Upload PAN Document
                          <VisuallyHiddenInput
                            type="file"
                            onChange={(event) =>
                              uploadPanURL(event.target.files[0], setPanUrl)
                            }
                          />
                        </Button>

                        {panUrl && (
                          <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<MdOutlineRemoveRedEye />}
                            sx={{ textTransform: "capitalize", ml: 2 }}
                            onClick={() => viewUploadedDocs(panUrl)}
                          >
                            View
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  )}

                  {businessType === "BUSINESS" && (
                    <>
                      <Grid size={6}>
                        <TextField
                          fullWidth
                          autoComplete="off"
                          size="small"
                          name="gstNumber"
                          label="GST Number"
                          variant="outlined"
                          helperText={touched.gstNumber && errors.gstNumber}
                          error={touched.gstNumber && errors.gstNumber}
                          value={values.gstNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>

                      <Grid size={6}>
                        <Box>
                          <Button
                            component="label"
                            role={undefined}
                            variant="outlined"
                            tabIndex={-1}
                            startIcon={<MdCloudUpload />}
                            sx={{ textTransform: "capitalize" }}
                          >
                            Upload GST Document
                            <VisuallyHiddenInput
                              type="file"
                              onChange={(event) =>
                                gstDocURL(event.target.files[0], setGstUrl)
                              }
                            />
                          </Button>

                          {gstUrl && (
                            <Button
                              variant="outlined"
                              color="secondary"
                              startIcon={<MdOutlineRemoveRedEye />}
                              sx={{ textTransform: "capitalize", ml: 2 }}
                              onClick={() => viewUploadedDocs(gstUrl)}
                            >
                              View
                            </Button>
                          )}
                        </Box>
                      </Grid>

                      <Grid size={6}>
                        <TextField
                          fullWidth
                          autoComplete="off"
                          size="small"
                          name="companyPAN"
                          label="Company PAN Number"
                          variant="outlined"
                          helperText={touched.companyPAN && errors.companyPAN}
                          error={touched.companyPAN && errors.companyPAN}
                          value={values.companyPAN || ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </Grid>

                      <Grid size={6}>
                        <Box>
                          <Button
                            component="label"
                            role={undefined}
                            variant="outlined"
                            tabIndex={-1}
                            startIcon={<MdCloudUpload />}
                            sx={{ textTransform: "capitalize" }}
                          >
                            Upload Company PAN
                            <VisuallyHiddenInput
                              type="file"
                              onChange={(event) =>
                                companyPANDocURL(
                                  event.target.files[0],
                                  setCompanyPanUrl
                                )
                              }
                            />
                          </Button>

                          {companyPanUrl && (
                            <Button
                              variant="outlined"
                              color="secondary"
                              startIcon={<MdOutlineRemoveRedEye />}
                              sx={{ textTransform: "capitalize", ml: 2 }}
                              onClick={() => viewUploadedDocs(companyPanUrl)}
                            >
                              View
                            </Button>
                          )}
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardContent>

              {/* BANK DETAILS */}
              <CardContent>
                <Box className="header-title" sx={{ mb: 2 }}>
                  <Typography variant="h4">Bank Details</Typography>
                </Box>

                <Grid container spacing={3}>
                  <Grid size={6}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="bankAccountNumber"
                      label="Bank Account Number"
                      variant="outlined"
                      helperText={
                        touched.bankAccountNumber && errors.bankAccountNumber
                      }
                      error={
                        touched.bankAccountNumber && errors.bankAccountNumber
                      }
                      value={values.bankAccountNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid size={6}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="accountHolderName"
                      label="Account Holder Name"
                      variant="outlined"
                      helperText={
                        touched.accountHolderName && errors.accountHolderName
                      }
                      error={
                        touched.accountHolderName && errors.accountHolderName
                      }
                      value={values.accountHolderName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid size={6}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="bankIFSC"
                      label="Bank IFSC"
                      variant="outlined"
                      helperText={touched.bankIFSC && errors.bankIFSC}
                      error={touched.bankIFSC && errors.bankIFSC}
                      value={values.bankIFSC}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid size={6}>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="bankName"
                      label="Bank Name"
                      variant="outlined"
                      helperText={touched.bankName && errors.bankName}
                      error={touched.bankName && errors.bankName}
                      value={values.bankName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>

                  <Grid size={6}>
                    <FormControl
                      variant="outlined"
                      size="small"
                      fullWidth
                      error={
                        touched.verificationDocumentType &&
                        errors.verificationDocumentType
                      }
                    >
                      <InputLabel id="certificate-select-label">
                        Document Type
                      </InputLabel>
                      <Select
                        labelId="certificate-select-label"
                        id="certificate-simple-select"
                        name="verificationDocumentType"
                        value={values.verificationDocumentType || ""}
                        label="Business Certificate"
                        error={
                          touched.verificationDocumentType &&
                          errors.verificationDocumentType
                        }
                        onChange={handleChange}
                        defaultValue=""
                      >
                        <MenuItem value="Bank Statement">
                          Bank Statement
                        </MenuItem>
                        <MenuItem value="Cancelled Cheque">
                          Cancelled Cheque
                        </MenuItem>
                      </Select>
                      <FormHelperText>
                        {touched.verificationDocumentType &&
                          errors.verificationDocumentType}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid size={6}>
                    <Box>
                      <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        startIcon={<MdCloudUpload />}
                        sx={{ textTransform: "capitalize" }}
                      >
                        Upload verification files
                        <VisuallyHiddenInput
                          type="file"
                          onChange={(event) =>
                            uploadBankFile(event.target.files[0])
                          }
                          multiple
                        />
                      </Button>

                      {bankUploadFile && (
                        <Button
                          variant="outlined"
                          color="secondary"
                          startIcon={<MdOutlineRemoveRedEye />}
                          sx={{ textTransform: "capitalize", ml: 2 }}
                          onClick={() => viewUploadedDocs(bankUploadFile)}
                        >
                          View
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>

              {/* COMMISSION DETAILS */}
              {user.role === "Admin" && (
                <CardContent>
                  <Box className="header-title" sx={{ mb: 2 }}>
                    <Typography variant="h4">Commission Details</Typography>
                  </Box>

                  <Grid container spacing={3}>
                    <Grid size={6}>
                      <FormControl
                        variant="outlined"
                        size="small"
                        fullWidth
                        error={touched.commissionType && errors.commissionType}
                      >
                        <InputLabel id="comission-select-label">
                          Commission Type
                        </InputLabel>
                        <Select
                          labelId="comission-select-label"
                          id="comission-simple-select"
                          name="commissionType"
                          value={values.commissionType || ""}
                          label="Commission Type"
                          error={
                            touched.commissionType && errors.commissionType
                          }
                          onChange={handleChange}
                          defaultValue={values.commissionType || ""}
                        >
                          <MenuItem value="disbursal">
                            As per Disbursal
                          </MenuItem>
                          <MenuItem value="leads">As per Leads</MenuItem>
                        </Select>
                        <FormHelperText>
                          {touched.commissionType && errors.commissionType}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid size={6}>
                      <Box sx={{ textAlign: "right" }}>
                        <Button
                          variant="outlined"
                          size="small"
                          sx={{ textTransform: "capitalize" }}
                          onClick={() => {
                            const obj = {
                              start: "",
                              end: "",
                              value: "",
                            };
                            setCommissions([...commissions, obj]);
                          }}
                        >
                          + Add Commision
                        </Button>
                      </Box>
                    </Grid>

                    {commissions.map((item, index) => (
                      <Commission
                        key={index}
                        touched={touched}
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        commissions={commissions}
                        setCommissions={setCommissions}
                        item={item}
                        index={index}
                      />
                    ))}
                  </Grid>
                </CardContent>
              )}

              {/* ACCEPT BUTTON */}

              <CardContent sx={{ mt: 2 }}>
                <Grid container spacing={3}>
                  <Grid size={8} offset={2} textAlign="center">
                    <Typography variant="body2">
                      By clicking on Submit, you accept the terms and conditions
                      of Ram Fincorp and RK Bansal Finance
                    </Typography>
                    <Button
                      color="primary"
                      disabled={data?.status == "ACTIVE" ? true : false}
                      variant="contained"
                      fullWidth
                      type="submit"
                      size="large"
                      endIcon={
                        isSubmitting && (
                          <CircularProgress
                            size="1.4rem"
                            style={{ color: "#ffffff", marginLeft: 6 }}
                          />
                        )
                      }
                      sx={{
                        "&.Mui-disabled": {
                          color: "#000",
                          background: "#ddddddd",
                        },
                        width: 300,
                        marginTop: 4,
                      }}
                    >
                      {userID ? "ACTIVATE" : "SUBMIT"}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </Layout>
  );
}
