import React from "react";
import DashboardRoutes from "./routes/DashboardRoutes";
import AppRoutes from "./routes/AppRoutes";

import { useSelector } from "react-redux";

export default function App() {
  const { isLoggedIn } = useSelector((state) => state.user);

  return <>{isLoggedIn ? <DashboardRoutes /> : <AppRoutes />}</>;
}
