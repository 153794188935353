import React from "react";
import { Box } from "@mui/material";
import { List, ListItem, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Drawer() {
  const { pathname } = useLocation();

  const { user } = useSelector((state) => state.user);

  return (
    <Box component="aside" className="app__drawer">
      <Box className="logo">
        <img src="/images/logo.png" alt="" />
      </Box>
      <Box>
        <List>
          {user.role === "DSA" ? (
            <>
              <ListItem className={pathname === "/" ? "active" : ""}>
                <Link to="/">
                  <ListItemText primary="Home" />
                </Link>
              </ListItem>
              <ListItem className={pathname === "/lead-report" ? "active" : ""}>
                <Link to="/lead-report">
                  <ListItemText primary="Lead Report" />
                </Link>
              </ListItem>
              <ListItem className={pathname === "/commission" ? "active" : ""}>
                <Link to="/commission">
                  <ListItemText primary="Commission" />
                </Link>
              </ListItem>
            </>
          ) : (
            <ListItem className={pathname === "/" ? "active" : ""}>
              <Link to="/">
                <ListItemText primary="Direct Selling Agent" />
              </Link>
            </ListItem>
          )}
        </List>
      </Box>
    </Box>
  );
}
