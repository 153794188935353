import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Layout from "../../layout/Layout";
import styles from "./Agreement.module.scss";
import UserAPI from "../../services/profile-api";
import { useNavigate, useParams } from "react-router-dom";

export default function Agreement() {
  const [data, setData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const { id } = useParams();

  const { getProfileAPI, updateUserStatusAPI } = new UserAPI();

  useEffect(() => {
    fetchProfileAPI();
  }, []);

  const fetchProfileAPI = async () => {
    const res = await getProfileAPI();

    if (res.ok) {
      setData(res.data);
    }
  };

  const handleAgreement = async () => {
    setIsSubmitting(true);

    const divContent = document.getElementById("agreement_content").innerHTML;

    const res = await updateUserStatusAPI(
      id,
      "AGREEMENT_SIGNED",
      "",
      JSON.stringify(divContent)
    );

    if (res.ok) {
      navigate("/hold");
    }
  };

  const panNumber = data?.profile?.businesses[0]?.pan
    ? data?.profile?.businesses[0]?.pan
    : data?.profile?.PAN;

  return (
    <Layout>
      <Box className={styles.agreement}>
        <div id="agreement_content">
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "4.05pt",
              marginRight: "52.8pt",
              marginBottom: "8.0pt",
              marginLeft: "51.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center",
            }}
          >
            <strong>
              <u>
                <span style={{ fontFamily: "Calibri" }}>
                  LEAD GENERATION AGREEMENT
                </span>
              </u>
            </strong>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
              ></span>
            </strong>
          </p>
          <p
            style={{
              marginTop: ".35pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
              ></span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "5.7pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <strong>
              <span style={{ fontFamily: "Calibri" }}>
                THIS LEAD GENERATION AGREEMENT (
              </span>
            </strong>
            <span style={{ fontFamily: "Calibri" }}>
              “<strong>Agreement</strong>
              ”) made and entered into date
            </span>
            <strong>
              <span
                style={{
                  fontSize: 16,
                  fontFamily: "Calibri",
                }}
              >
                {" "}
                {new Date(data?.createdAt).toDateString()}{" "}
              </span>
            </strong>
            <span style={{ fontFamily: "Calibri" }}>(“Effective Date”).</span>
          </p>
          <p
            style={{
              marginTop: "9.75pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "5.0pt",
              textAlign: "center",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <strong>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                Between
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "9.2pt",
              marginRight: "6.7pt",
              marginBottom: ".0001pt",
              marginLeft: "5.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <strong>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                R K Bansal Finance PVT LTD,
              </span>
            </strong>
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              a company incorporated in India under Companies Act, 1956/2013 and
              having corporate identity number U74110DL1984PTC019355 and its
              registered office at 8/9 2<sup>nd</sup> Floor WEA, Karol Bagh New
              Delhi- 110005, (hereinafter referred to as “
              <strong>Company</strong>
              ”, which expression shall include its successors and assigns), of
              the First Part;
            </span>
          </p>
          <p
            style={{
              marginTop: ".05pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span
              style={{ fontSize: 17, fontFamily: '"Calibri",sans-serif' }}
            ></span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center",
            }}
          >
            <strong>
              <span style={{ fontFamily: "Calibri" }}>And</span>
            </strong>
          </p>
          <p
            style={{
              marginTop: ".1pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 19, fontFamily: '"Calibri",sans-serif' }}
              ></span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "2.0pt",
              marginRight: "8.4pt",
              marginBottom: ".0001pt",
              marginLeft: "5.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <strong>
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "112%",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <span>
                  {data?.name}, - {data?.profile?.address},{" "}
                </span>
              </span>
            </strong>
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              an individual signing this agreement for self/ a proprietary firm
              signing
            </span>
            <strong>
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "112%",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                \
              </span>
            </strong>
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              his agreement through its proprietor/ a partnership firm signing
              this Agreement through its principal or managing partner/ a Hindu
              Undivided Family signing this agreement through its Karta/ a
              company incorporated under the Companies Act, 1956/2013 (as the
              case maybe) signing this Agreement through its authorized
              signatory) having its office at{" "}
              <strong>{data?.profile?.address}</strong> the address stated above
              (hereinafter referred to as
              <strong>“the Service Provider/Referrer” </strong>
              which expression shall include, to the context permits, its
              successors in business and permitted assigns) of the Other Part.
            </span>
          </p>
          <p
            style={{
              marginTop: ".35pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span
              style={{ fontSize: 17, fontFamily: '"Calibri",sans-serif' }}
            ></span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "8.4pt",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
              lineHeight: "113%",
            }}
          >
            <strong>
              <span style={{ fontFamily: "Calibri" }}>Company </span>
            </strong>
            <span style={{ fontFamily: "Calibri" }}>
              and the
              <strong>Service Provider/Referrer </strong>are hereinafter
              referred together as “<strong>the Parties</strong>” and each
              individually “<strong>a Party</strong>”.
            </span>
          </p>
          <h3
            style={{
              marginTop: "6.05pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "5.0pt",
              textIndent: "0cm",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>WHEREAS</span>
          </h3>
          <ol style={{ listStyleType: "upper-alpha", marginLeft: 67 }}>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The Company is, inter-alia, engaged in the business of
                facilitating lending services over its internet platform located
                at URL www.ramfincorp.com
                <span style={{ color: "blue" }}> </span>
                <strong>(‘Website’) </strong>
                and is desirous of sourcing potential Borrowers to list on its
                Website.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The Service Provider/Referrer is inter-alia engaged in the
                business of rendering of direct marketing services and has
                represented to the Company that it has the necessary expertise,
                staff, infrastructure, business premises and facilities for
                performing the said services, which is to generate leads to
                track potential Borrowers.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The Company and the Service Provider/Referrer are desirous of
                entering into this Agreement (“
                <strong>Referral Agreement</strong>
                ”) to promote Company’s business and the Parties by this
                Agreement intend to supersede and nullify any earlier Referral
                Agreement if any in accordance with the terms and conditions
                stipulated hereinafter.
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: "5.85pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <strong>
              <u>
                <span style={{ fontFamily: "Calibri" }}>
                  NOW IT IS HEREBY AGREED BY AND BETWEEN THE PARTIES AS FOLLOWS:
                </span>
              </u>
            </strong>
          </p>
          <div
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <ol
              style={{
                marginBottom: "0cm",
                listStyleType: "decimal",
                marginLeft: 25,
              }}
            >
              <li
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "8.0pt",
                  marginLeft: "0cm",
                  fontSize: "11.0pt",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <strong>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    DEFINITIONS:
                  </span>
                </strong>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: ".5pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 12, fontFamily: '"Calibri",sans-serif' }}
              ></span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "4.8pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              In this Agreement, the following expressions shall have the
              meaning hereinafter assigned to them:
            </span>
          </p>
          <p
            style={{
              marginTop: "9.2pt",
              marginRight: "8.4pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              “<strong>Agreement</strong>” means this Referral Agreement and any
              amendments thereto made in accordance with the provisions of this
              Agreement.
            </span>
          </p>
          <p
            style={{
              marginTop: "6.15pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              “<strong>Borrower</strong>” means any person who wishes to be
              listed as a borrower on the Website and in
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span
              style={{ fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            ></span>
          </p>
          <p
            style={{
              marginTop: ".45pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}> </span>
          </p>
          <p
            style={{
              marginTop: "3.75pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              respect of whom the Service Provider has obtained Leads;
            </span>
          </p>
          <p
            style={{
              marginTop: "7.85pt",
              marginRight: "7.1pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <strong>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                “Leads”
              </span>
            </strong>
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              mean information about potential borrowers referred by the Service
              Provider/Referrer to the Company, who are interested in being
              listed as Borrowers on the Website of the Company.
            </span>
          </p>
          <p
            style={{
              marginTop: "6.15pt",
              marginRight: "6.5pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              “<strong>Confidential Information</strong>” of Company shall mean
              and include any non-public information whether or not designated
              as being confidential or which under the circumstances surrounding
              disclosure ought to be treated as confidential. “Confidential
              Information” for purposes of confidentiality includes, but is not
              limited to, the following types of information with respect to the
              business and operations of Company, its database and other
              information of a similar nature (whether or not reduced to
              writing): marketing techniques and materials, marketing and
              development plans, information pertaining to the Borrowers,
              information about the employees (including, addresses, phone
              numbers, e-mail addresses, and all other information relating to
              the employees), price list, brokerage/commission structure,
              pricing policies and financial information, discoveries, ideas,
              concepts, software in various stages of development, drawings,
              specifications, techniques, models, data, mark-up languages (HTML,
              XML), documentation, diagrams, flowcharts, research, development,
              formulas, computer programs, processes (both business and
              technical), technical specifications, data, ideas, inventions,
              algorithms, source code, object code, know-how, software listings,
              schematics and all verbal and written discussions between Company
              and the Service Provider/Referrer. Confidential Information also
              includes any information described above which Company treats as
              proprietary whether or not owned or developed by Company. The
              Confidential Information further includes, without limitation,
              information relating to Company’s released or unreleased services
              or products, the marketing or promotion of any of Company’s
              business policies or practices, and information received from
              others that the Company is obligated to treat as confidential.
              Confidential Information disclosed to the Service
              Provider/Referrer by any employee of Company shall also be
              considered as Confidential Information. Further, all of Company’s
              financial projections, data and other related information and
              documents (including any verbal indication) as well as results and
              conclusion arising from the scrutiny process of any Leads
              submitted by the Service Provider and examination of such data and
              information which is acquired by the Company, documents as well as
              standardized product/instrument concerning the work assigned to
              the Service Provider shall also constitute Confidential
              Information. And in each case whether such information was
              disclosed before or after the date of this Agreement, either in
              writing, in disk or electronic form or orally or visually and
              whether directly or indirectly by the Company or its advisers.
            </span>
          </p>
          <p
            style={{
              marginTop: "6.85pt",
              marginRight: "6.85pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              "<strong>Documentation</strong>" shall mean any and all documents
              submitted by the Service Provider to the Company in connection
              with a Leads with respect to any Borrower.
            </span>
          </p>
          <p
            style={{
              marginTop: "6.2pt",
              marginRight: "6.8pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              “<strong>Laws or Law</strong>” shall mean and include laws,
              treaties, ordinances, judgments, decrees, injunctions, writs,
              orders and stipulations of any court, arbitrator or governmental
              agency or authority and statutes, rules, regulations, orders and
              interpretations thereof of any national, state, municipal,
              regional, environmental or other governmental body,
              instrumentality, agency, authority, court or other body having
              jurisdiction over the Parties.
            </span>
          </p>
          <p
            style={{
              marginTop: "8.15pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              “<strong>Premises</strong>” shall mean the premises being held by
              the Service Provider/Referrer in the Territories
            </span>
          </p>
          <p
            style={{
              marginTop: "9.2pt",
              marginRight: "6.9pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "113%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              “<strong>Services</strong>” shall mean generating Leads and
              referring potential Borrowers to the Company and includes the
              services more particularly specified in{" "}
              <strong>
                Annexure<u> II</u>
              </strong>
              hereof as amended from time to time and shall include any
              processes/procedures thereof.
            </span>
          </p>
          <p
            style={{
              marginTop: ".5pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span
              style={{ fontSize: 16, fontFamily: '"Calibri",sans-serif' }}
            ></span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "7.1pt",
              marginBottom: "8.0pt",
              marginLeft: "41.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "7.1pt",
              marginBottom: "8.0pt",
              marginLeft: "41.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}>
              “<strong>Territories</strong>” shall mean the territories at{" "}
              <strong>{data?.profile?.address}</strong> notified by the Company
              to the Service Provider.
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <strong>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                “Sourcing Fees”
              </span>
            </strong>
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              shall mean, the fees payable by the Company to the Service
              Provider/Referrer in
            </span>
          </p>
          <p
            style={{
              marginTop: "3.75pt",
              marginRight: "6.9pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "113%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              respect of the Loans Disbursed to eligible Borrowers that have
              been listed on the Website pursuant to Leads generated by the
              Service Provider. The fees will be as specified in{" "}
              <strong>Annexure I </strong>annexed hereto.
            </span>
          </p>
          <p
            style={{
              marginTop: ".3pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span
              style={{ fontSize: 11, fontFamily: '"Calibri",sans-serif' }}
            ></span>
          </p>
          <p
            style={{
              marginTop: "4.85pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "41.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <strong>
              <span style={{ fontFamily: "Calibri" }}>“Loan Disbursed”</span>
            </strong>
            <span style={{ fontFamily: "Calibri" }}>
              shall mean the actual sum borrowed by a Borrower.
            </span>
          </p>
          <div
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <ol
              start={2}
              style={{
                marginBottom: "0cm",
                listStyleType: "decimal",
                marginLeft: 25,
              }}
            >
              <li
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "8.0pt",
                  marginLeft: "0cm",
                  fontSize: "11.0pt",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <h3
                  style={{
                    marginTop: "6.2pt",
                    marginRight: "0cm",
                    marginBottom: "0cm",
                    marginLeft: "61.65pt",
                    textIndent: "-20.7pt",
                    fontSize: 15,
                    fontFamily: '"Cambria",serif',
                  }}
                >
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    INTERPRETATION:
                  </span>
                </h3>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: ".2pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 10, fontFamily: '"Calibri",sans-serif' }}
              ></span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "4.8pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              In this Agreement, unless the context otherwise requires:
            </span>
          </p>
          <p
            style={{
              marginTop: ".25pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span
              style={{ fontSize: 15, fontFamily: '"Calibri",sans-serif' }}
            ></span>
          </p>
          <ol style={{ listStyleType: "decimal", marginLeft: 25 }}>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                ‘Headings’ are for convenience only and do not govern or affect
                the construction or interpretation of this Agreement;
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                Any ‘Explanation’ is to be treated as an integral part of this
                Agreement;
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                Reference to any statute or statutory provision shall include a
                reference to that statute or statutory provision for the time
                being in force and also to amendment, extension, modification,
                re-enactment or repeal thereof;
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                All references in this Agreement to any provision of any statute
                shall be deemed also to refer to the statute, modification or
                re-enactment thereof or any statutory rule, order or regulation
                made thereunder or under such re-enactment in force in India at
                the time of entering into this Agreement;
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                All references in the Agreement to Clauses, Sub-Clauses and
                Annexure shall be construed as references respectively to the
                Clauses, Sub-Clauses and Annexure;
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The Recitals constituted herein shall form an integral operative
                part of this Agreement;
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                Words importing singular shall include the plural and vice
                versa, and words importing the male sex shall include for its
                purposes the female sex and vice versa.
              </span>
            </li>
          </ol>
          <ol
            start={3}
            style={{ listStyleType: "decimal", marginLeft: "55.3px" }}
          >
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  TERM:
                </span>
              </h3>
            </li>
          </ol>
          <p
            style={{
              marginTop: "7.85pt",
              marginRight: "6.85pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "113%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              This Agreement shall commence from the Effective Date and shall
              continue for a term{" "}
              <span>
                of <strong>1 (One) </strong>Years (“
                <strong>TERM</strong>
              </span>
              ”), unless otherwise terminated in accordance with clause 9, and
              will be renewed as per mutual consent of both parties post expiry.
            </span>
          </p>
          <ol
            className="decimal_type"
            start={4}
            style={{ listStyleType: "decimal", marginLeft: "44.75px" }}
          >
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  APPOINTMENT:
                </span>
              </h3>
              <ol
                className="decimal_type"
                start={4}
                style={{ listStyleType: "decimal" }}
              >
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    Subject to the terms and conditions contained in this
                    Agreement, Company hereby appoints the Service
                    Provider/Referrer, on non-exclusive basis and in its
                    capacity as an independent Service Provider/Referrer, for
                    providing the Service to the Company and for performing the
                    functions, services, operations mentioned herein and the
                    Service Provider/Referrer accepts the said appointment and
                    agrees that during the term of this Agreement, it shall
                    render to Company the Services on a non-exclusive basis in
                    terms of this Agreement and in accordance with this
                    Agreement.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    Notwithstanding anything contained herein nothing shall
                    restrict the right of Company to appoint any other Service
                    Provider/Referrer in relation to the same or similar
                    Services and on the same or similar or on any other terms
                    and conditions as the Company may deem fit and proper, which
                    terms and conditions may be at variance with the terms and
                    conditions contained herein.
                  </span>
                </li>
              </ol>
            </li>
          </ol>
          <p
            style={{
              marginTop: "4.75pt",
              marginRight: "6.85pt",
              marginBottom: ".0001pt",
              marginLeft: "35.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}> </span>
          </p>
          <ol
            className="decimal_type"
            start={5}
            style={{ listStyleType: "decimal", marginLeft: "44.45px" }}
          >
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  RESPONSIBILITIES OF SERVICE PROVIDER/REFERRER
                </span>
              </h3>
              <ol
                className="decimal_type"
                start={5}
                style={{ listStyleType: "decimal" }}
              >
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    The Service provider/Referrer agrees and undertakes to
                    generate and provide Leads to the Company and provide the
                    following Services to Company:
                  </span>
                  <ol style={{ listStyleType: "lower-alpha" }}>
                    <li>
                      <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                        Visit Borrowers in the Territory to procure information
                        about the Borrowers, or direct any of the Borrowers to
                        the Website of the Company to register directly on the
                        Company’s Website.
                      </span>
                    </li>
                    <li>
                      <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                        Assist the Company in making proposals to generate
                        Leads.
                      </span>
                      <ol start={3} style={{ listStyleType: "lower-alpha" }}>
                        <li>
                          <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                            Service Provider/Referrer shall provide such
                            financial and business information concerning the
                            Borrower as requested by Company in this regard.
                          </span>
                        </li>
                        <li>
                          <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                            Upon any Borrower positively meeting the credit
                            worthiness prescribed by the Company, to compile and
                            ensure completion of all Documentation required by
                            the Company to list such Borrower on the Website.
                          </span>
                        </li>
                        <li>
                          <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                            Deliver all the completed Documentation to the
                            Company for listing of such Borrower on the Website.
                          </span>
                        </li>
                        <li>
                          <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                            All such other services and functions as intimated
                            to the Service Provider/Referrer by Company from
                            time to time.
                          </span>
                        </li>
                        <li>
                          <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                            If there is an event of any change in the
                            constitution; or management of the Service
                            Provider/Referrer whether or not involving a change
                            in the ownership structure, unless the SERVICE
                            PROVIDER/REFERRER informs Company and obtains the
                            prior written consent from Company of such change;
                          </span>
                        </li>
                        <li>
                          <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                            The Service Provider/Referrer shall prepare and
                            submit a continuity business plan acceptable to the
                            Company, in the event the Service provider/Referrer
                            is unable to provide the Services or for any reason
                            it has to suspend the provision of the Services. The
                            Company will ensure that it assists the Service
                            Provider/Referrer in the event that the Service
                            Provider/Referrer is compelled for any reason to
                            activate the continuity business plan.
                          </span>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                        The Service Provider/Referrer agrees and understands
                        that the Service Provider/Referrer shall not have any
                        power or authority to bind the Company in any way
                        hereunder, including but not limited to, (i) inducing or
                        committing Company, directly or indirectly, to list the
                        Borrower on the Website; (ii) to take any action
                        contrary to those actions expressly authorized
                        hereunder.
                      </span>
                      <ol
                        className="decimal_type"
                        start={6}
                        style={{ listStyleType: "decimal" }}
                      >
                        <li>
                          <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                            The Service Provider/Referrer shall have no power to
                            make representations, promises, agreements or
                            commitments for or on behalf of Company, and Service
                            Provider/Referrer agrees it shall take any and all
                            actions necessary to advise each Borrower
                            accordingly. Nothing contained herein shall be
                            deemed to authorize Service Provider/Referrer to
                            enter into any arrangement or provide any warranty
                            or any guarantee to any Borrower or any other third
                            party for and on behalf of Company, about any Loan
                            disbursements.
                          </span>
                          <ol
                            className="decimal_type"
                            start={6}
                            style={{ listStyleType: "decimal" }}
                          >
                            <li>
                              <span
                                style={{ fontFamily: '"Calibri",sans-serif' }}
                              >
                                The Service Provider/Referrer hereby
                                acknowledges that the Company may in its sole
                                discretion refuse or reject the application of
                                any Borrower without assigning any reason in
                                respect thereof and shall not be liable in this
                                regard, in any manner whatsoever.
                              </span>
                            </li>
                            <li>
                              <span
                                style={{ fontFamily: '"Calibri",sans-serif' }}
                              >
                                For each Lead, Service Provider/Referrer will
                                warrant and represent that; (i) all names,
                                addresses, dates set out in the Documentation
                                are true and correct and the signatures therein
                                have been duly affixed in the presence of
                                Service Provider/Referrer, if any and, (ii)
                                Documentation has not been amended, changed,
                                settled or compromised, in any manner whatsoever
                                without the prior written consent of Company.
                              </span>
                            </li>
                            <li>
                              <span
                                style={{ fontFamily: '"Calibri",sans-serif' }}
                              >
                                The Service Provider/Referrer shall maintain
                                highest degree of probity, discretion and
                                business competence in its dealings and shall
                                use its best endeavors to source Leads for the
                                Company and shall act only in accordance with
                                the operating procedures and other instructions
                                of Company as intimated to the Service
                                Provider/Referrer from time to time.
                              </span>
                              <ol
                                className="decimal_type"
                                start={6}
                                style={{ listStyleType: "decimal" }}
                              >
                                <li>
                                  <span
                                    style={{
                                      fontFamily: '"Calibri",sans-serif',
                                    }}
                                  >
                                    The Service Provider/Referrer covenants that
                                    it will not enter into any agreement or
                                    collude with the Borrower in any manner or
                                    submit false or fraudulent documents in
                                    order to get any Lead approved by Company
                                    and shall procure applications from
                                    Borrowers only for listing on the Website of
                                    Company and shall not discriminate in any
                                    manner against any Borrowers in respect to
                                    the terms and conditions of any Leads.
                                  </span>
                                </li>
                                <li>
                                  <span
                                    style={{
                                      fontFamily: '"Calibri",sans-serif',
                                    }}
                                  >
                                    The Service Provider/Referrer agrees and
                                    undertakes not to charge any fee or other
                                    remuneration from any Borrower in order to
                                    get the Leads approved from Company.
                                  </span>
                                  <ol
                                    className="decimal_type"
                                    start={6}
                                    style={{ listStyleType: "decimal" }}
                                  >
                                    <li>
                                      <span
                                        style={{
                                          fontFamily: '"Calibri",sans-serif',
                                        }}
                                      >
                                        The Service Provider/Referrer shall
                                        submit such reports regarding Leads and
                                        Borrowers as may be specified by the
                                        Company from time to time.
                                      </span>
                                    </li>
                                    <li>
                                      <span
                                        style={{
                                          fontFamily: '"Calibri",sans-serif',
                                        }}
                                      >
                                        The Service Provider/Referrer shall not
                                        have any authority to enter into any
                                        agreement(s) on behalf of the Company,
                                        draw, accept, or endorse any bill of the
                                        Company or to use the name of the
                                        Company except to the extent authorized
                                        by the Company in writing.
                                      </span>
                                      <ol
                                        className="decimal_type"
                                        start={6}
                                        style={{ listStyleType: "decimal" }}
                                      >
                                        <li>
                                          <span
                                            style={{
                                              fontFamily:
                                                '"Calibri",sans-serif',
                                            }}
                                          >
                                            The Service Provider/Referrer shall
                                            not use any of the Company’s trade
                                            name, trade mark, symbol, logo or
                                            the words “<strong>Company</strong>”
                                            either individually or in
                                            conjunction or any other name(s)
                                            used by the Company, on any
                                            stationery, letterhead, name board
                                            or otherwise, except to the extent
                                            and in the form and manner approved
                                            by the Company in writing.
                                          </span>
                                          <ol
                                            className="decimal_type"
                                            start={6}
                                            style={{ listStyleType: "decimal" }}
                                          >
                                            <li>
                                              <span
                                                style={{
                                                  fontFamily:
                                                    '"Calibri",sans-serif',
                                                }}
                                              >
                                                The Service Provider/Referrer
                                                agrees and undertakes that in
                                                performance of its obligations
                                                under this Agreement it will not
                                                directly or indirectly pay,
                                                offer or authorize payment for
                                                anything of value (either in the
                                                form of compensation, gift
                                                contribution or even otherwise)
                                                to any person (in particular,
                                                government officials/employees),
                                                organization, or political
                                                party, contrary to any law in
                                                force in India. The Service
                                                Provider/Referrer will use good
                                                judgment to avoid even the
                                                appearance of any improper
                                                payment and will further ensure
                                                that business entertainment is
                                                of a perfectly lawful,
                                                legitimate and decent nature,
                                                and permissible by the procedure
                                                and business principle of
                                                integrity.
                                              </span>
                                            </li>
                                            <li>
                                              <span
                                                style={{
                                                  fontFamily:
                                                    '"Calibri",sans-serif',
                                                }}
                                              >
                                                The Service Provident /Referrer
                                                undertakes that it shall not
                                                adopt any unfair, monopolistic
                                                or restrictive trade practices
                                                or unfair Labour practices and
                                                it shall not collude/communicate
                                                with any other service provider,
                                                providing the same or similar
                                                services to the Company to fix
                                                prices for the Leads or Services
                                                or for any purpose whatsoever.
                                              </span>
                                              <ol
                                                className="decimal_type"
                                                start={6}
                                                style={{
                                                  listStyleType: "decimal",
                                                }}
                                              >
                                                <li>
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        '"Calibri",sans-serif',
                                                    }}
                                                  >
                                                    The Service
                                                    Provider/Referrer shall
                                                    avoid any conflict of
                                                    interest with the Company
                                                    and also undertakes that
                                                    each and every person who is
                                                    an employee, agent,
                                                    consultant, or
                                                    representative of Service
                                                    Provider/Referrer, will
                                                    avoid any conflict of
                                                    interest with the Company,
                                                    and if any person, including
                                                    any of those mentioned
                                                    above, is in any way,
                                                    connected with the
                                                    performance of obligation
                                                    under this Agreement, then
                                                    such person will also take
                                                    care to ensure avoidance of
                                                    conflict of interest with
                                                    the Company.
                                                  </span>
                                                  <ol
                                                    className="decimal_type"
                                                    start={6}
                                                    style={{
                                                      listStyleType: "decimal",
                                                    }}
                                                  >
                                                    <li>
                                                      <span
                                                        style={{
                                                          fontFamily:
                                                            '"Calibri",sans-serif',
                                                        }}
                                                      >
                                                        The Service
                                                        Provider/Referrer shall
                                                        allow the Company or its
                                                        representatives
                                                        reasonable opportunity
                                                        to inspect its premises
                                                        during business hours
                                                        for verification of all
                                                        records, employee
                                                        related or otherwise
                                                        which are maintained by
                                                        the Service
                                                        Provider/Referrer in
                                                        connection with the
                                                        Services rendered by it.
                                                        This inspection shall
                                                        not be construed as the
                                                        statutory/internal audit
                                                        of the Service
                                                        Provider/Referrer as may
                                                        be required under any
                                                        law.
                                                      </span>
                                                      <ol
                                                        className="decimal_type"
                                                        start={6}
                                                        style={{
                                                          listStyleType:
                                                            "decimal",
                                                        }}
                                                      >
                                                        <li>
                                                          <span
                                                            style={{
                                                              fontFamily:
                                                                '"Calibri",sans-serif',
                                                            }}
                                                          >
                                                            The Service
                                                            Provider/Referrer
                                                            agrees, accepts and
                                                            acknowledges that
                                                            the Company is and
                                                            shall be the sole
                                                            and exclusive owner
                                                            of all Leads and is
                                                            and shall be solely
                                                            entitled to all the
                                                            information and
                                                            Documentation
                                                            concerning the
                                                            Borrowers and that
                                                            the Service
                                                            Provider/Referrer
                                                            has no right, title
                                                            or interest in the
                                                            Borrowers Leads.
                                                          </span>
                                                          <ol
                                                            className="decimal_type"
                                                            start={6}
                                                            style={{
                                                              listStyleType:
                                                                "decimal",
                                                            }}
                                                          >
                                                            <li>
                                                              <span
                                                                style={{
                                                                  fontFamily:
                                                                    '"Calibri",sans-serif',
                                                                }}
                                                              >
                                                                The Service
                                                                Provider/Referrer
                                                                shall follow at
                                                                all stages,
                                                                proper
                                                                accounting,
                                                                reporting and
                                                                control
                                                                procedure,
                                                                including
                                                                keeping all
                                                                financial and
                                                                non-financial
                                                                records
                                                                accurate, up to
                                                                date and
                                                                complete.
                                                              </span>
                                                            </li>
                                                          </ol>
                                                        </li>
                                                        <li>
                                                          <span
                                                            style={{
                                                              fontFamily:
                                                                '"Calibri",sans-serif',
                                                            }}
                                                          >
                                                            In consideration of
                                                            the services being
                                                            rendered by the
                                                            Service
                                                            Provider/Referrer
                                                            under this
                                                            Agreement, Company
                                                            agrees to pay the
                                                            Service
                                                            Provider/Referrer
                                                            the remuneration,
                                                            which is more
                                                            particularly
                                                            specified in
                                                            <strong>
                                                              Annexure I,
                                                            </strong>
                                                            herein.
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <span
                                                            style={{
                                                              fontFamily:
                                                                '"Calibri",sans-serif',
                                                            }}
                                                          >
                                                            The Company shall
                                                            publish a digital
                                                            pay-out details of
                                                            the Service
                                                            Provider/Referrer on
                                                            the dashboard of the
                                                            Service Provider
                                                            located on the
                                                            Website, by the 10th
                                                            of each month for
                                                            the loans disbursed
                                                            in the month prior
                                                            to previous month.
                                                          </span>
                                                        </li>
                                                        <li>
                                                          <span
                                                            style={{
                                                              fontFamily:
                                                                '"Calibri",sans-serif',
                                                            }}
                                                          >
                                                            The Service Provider
                                                            will raise a monthly
                                                            invoice post
                                                            reviewing the
                                                            pay-out details
                                                            published by the
                                                            Company.
                                                          </span>
                                                          <ol
                                                            className="decimal_type"
                                                            start={7}
                                                            style={{
                                                              listStyleType:
                                                                "decimal",
                                                            }}
                                                          >
                                                            <li>
                                                              <span
                                                                style={{
                                                                  fontFamily:
                                                                    '"Calibri",sans-serif',
                                                                }}
                                                              >
                                                                After the
                                                                Service Provider
                                                                submits their
                                                                invoice in
                                                                accordance with
                                                                7.3 above, the
                                                                Company shall
                                                                verify the
                                                                invoice and
                                                                process the
                                                                payment of such
                                                                invoice on/post
                                                                15th of each
                                                                month.{" "}
                                                                <strong></strong>
                                                              </span>
                                                              <ol
                                                                className="decimal_type"
                                                                start={7}
                                                                style={{
                                                                  listStyleType:
                                                                    "decimal",
                                                                }}
                                                              >
                                                                <li>
                                                                  <span
                                                                    style={{
                                                                      fontFamily:
                                                                        '"Calibri",sans-serif',
                                                                    }}
                                                                  >
                                                                    If the
                                                                    Service
                                                                    Provider has
                                                                    not made the
                                                                    payment of
                                                                    GST or if
                                                                    the GST
                                                                    payment is
                                                                    not
                                                                    reflected on
                                                                    the
                                                                    Company’s
                                                                    GST portal
                                                                    then all the
                                                                    payment to
                                                                    be made to
                                                                    the Service
                                                                    Provider
                                                                    shall be
                                                                    withheld by
                                                                    the Company
                                                                    till the
                                                                    time the
                                                                    Service
                                                                    Provider
                                                                    makes
                                                                    payment of
                                                                    the GST or
                                                                    till the
                                                                    time the
                                                                    Service
                                                                    Provider
                                                                    resolves the
                                                                    GST issue to
                                                                    the
                                                                    satisfaction
                                                                    of the
                                                                    Company.
                                                                  </span>
                                                                  <ol
                                                                    className="decimal_type"
                                                                    start={7}
                                                                    style={{
                                                                      listStyleType:
                                                                        "decimal",
                                                                    }}
                                                                  >
                                                                    <li>
                                                                      <span
                                                                        style={{
                                                                          fontFamily:
                                                                            '"Calibri",sans-serif',
                                                                        }}
                                                                      >
                                                                        The
                                                                        payment
                                                                        of fees
                                                                        shall
                                                                        only be
                                                                        processed
                                                                        if the
                                                                        Agreement
                                                                        with the
                                                                        Service
                                                                        Provider
                                                                        is valid
                                                                        and
                                                                        subsisting.
                                                                        All
                                                                        payments
                                                                        shall be
                                                                        made to
                                                                        the
                                                                        Service
                                                                        Provider/Referrer
                                                                        after
                                                                        making
                                                                        statutory
                                                                        deductions,
                                                                        if any.
                                                                        The
                                                                        Service
                                                                        Provider
                                                                        shall be
                                                                        responsible
                                                                        to
                                                                        ensure
                                                                        that the
                                                                        Leads
                                                                        referred
                                                                        by the
                                                                        Service
                                                                        Provider
                                                                        under
                                                                        this
                                                                        Agreement
                                                                        are
                                                                        mapped
                                                                        to the
                                                                        code
                                                                        provided
                                                                        to the
                                                                        Service
                                                                        Provider
                                                                        by the
                                                                        Company.
                                                                      </span>
                                                                      <ol
                                                                        className="decimal_type"
                                                                        start={
                                                                          7
                                                                        }
                                                                        style={{
                                                                          listStyleType:
                                                                            "decimal",
                                                                        }}
                                                                      >
                                                                        <li>
                                                                          <span
                                                                            style={{
                                                                              fontFamily:
                                                                                '"Calibri",sans-serif',
                                                                            }}
                                                                          >
                                                                            If
                                                                            the
                                                                            Service
                                                                            Provider
                                                                            breaches
                                                                            any
                                                                            provisions
                                                                            of
                                                                            the
                                                                            Agreement
                                                                            or
                                                                            this
                                                                            Addendum
                                                                            or
                                                                            if
                                                                            the
                                                                            Service
                                                                            Provider
                                                                            engages
                                                                            in
                                                                            any
                                                                            mis-selling
                                                                            or
                                                                            provides
                                                                            any
                                                                            incorrect
                                                                            information
                                                                            to
                                                                            the
                                                                            Borrower
                                                                            then
                                                                            the
                                                                            payments
                                                                            to
                                                                            be
                                                                            made
                                                                            to
                                                                            the
                                                                            Service
                                                                            Provider
                                                                            shall
                                                                            be
                                                                            withheld
                                                                            till
                                                                            the
                                                                            time
                                                                            the
                                                                            Service
                                                                            Provider
                                                                            cures
                                                                            the
                                                                            breach.
                                                                          </span>
                                                                          <ol
                                                                            className="decimal_type"
                                                                            start={
                                                                              7
                                                                            }
                                                                            style={{
                                                                              listStyleType:
                                                                                "decimal",
                                                                            }}
                                                                          >
                                                                            <li>
                                                                              <span
                                                                                style={{
                                                                                  fontFamily:
                                                                                    '"Calibri",sans-serif',
                                                                                }}
                                                                              >
                                                                                The
                                                                                Company
                                                                                shall
                                                                                also
                                                                                have
                                                                                right
                                                                                to
                                                                                deduct
                                                                                and
                                                                                recover
                                                                                from
                                                                                the
                                                                                Service
                                                                                Provider/Referrer,
                                                                                the
                                                                                additional/excess
                                                                                sourcing
                                                                                fees
                                                                                paid
                                                                                to
                                                                                the
                                                                                Service
                                                                                Provider/Referrer
                                                                                from
                                                                                the
                                                                                sourcing
                                                                                fees
                                                                                payable
                                                                                in
                                                                                the
                                                                                next
                                                                                month,
                                                                                provided
                                                                                the
                                                                                same
                                                                                is
                                                                                intimated
                                                                                in
                                                                                writing
                                                                                to
                                                                                the
                                                                                Service
                                                                                Provider/Referrer.
                                                                              </span>
                                                                            </li>
                                                                            <li>
                                                                              <span
                                                                                style={{
                                                                                  fontFamily:
                                                                                    '"Calibri",sans-serif',
                                                                                }}
                                                                              >
                                                                                The
                                                                                SERVICE
                                                                                PROVIDER/REFERRER
                                                                                shall
                                                                                furnish
                                                                                its/their
                                                                                Income
                                                                                Tax
                                                                                Permanent
                                                                                Account
                                                                                Number
                                                                                (
                                                                                <strong>
                                                                                  {
                                                                                    panNumber
                                                                                  }
                                                                                </strong>

                                                                                )
                                                                                to
                                                                                the
                                                                                Company
                                                                                along
                                                                                with
                                                                                the
                                                                                first
                                                                                bill
                                                                                raised
                                                                                by
                                                                                SERVICE
                                                                                PROVIDER/REFERRER
                                                                                to
                                                                                the
                                                                                Company.
                                                                                In
                                                                                the
                                                                                event
                                                                                of
                                                                                non-receipt
                                                                                of
                                                                                the
                                                                                Permanent
                                                                                Account
                                                                                Number
                                                                                along
                                                                                with
                                                                                the
                                                                                first
                                                                                bill,
                                                                                TDS
                                                                                will
                                                                                be
                                                                                deducted
                                                                                <span>
                                                                                  {" "}
                                                                                  at
                                                                                  10%
                                                                                  or
                                                                                </span>{" "}
                                                                                rates
                                                                                in
                                                                                force
                                                                                whichever
                                                                                is
                                                                                higher.
                                                                              </span>
                                                                            </li>
                                                                            <li>
                                                                              <span
                                                                                style={{
                                                                                  fontFamily:
                                                                                    '"Calibri",sans-serif',
                                                                                }}
                                                                              >
                                                                                The
                                                                                Company
                                                                                will
                                                                                issue
                                                                                a
                                                                                consolidated
                                                                                TDS
                                                                                Certificate
                                                                                to
                                                                                the
                                                                                SERVICE
                                                                                PROVIDER/REFERRER
                                                                                reflecting
                                                                                the
                                                                                total
                                                                                amount
                                                                                of
                                                                                TDS
                                                                                (with
                                                                                break
                                                                                up
                                                                                figures)
                                                                                deducted
                                                                                from
                                                                                time
                                                                                to
                                                                                time
                                                                                while
                                                                                making
                                                                                payments
                                                                                to
                                                                                the
                                                                                SERVICE
                                                                                PROVIDER/REFERRER
                                                                                during
                                                                                the
                                                                                relevant
                                                                                financial
                                                                                year.
                                                                                This
                                                                                consolidated
                                                                                TDS
                                                                                Certificate
                                                                                will
                                                                                be
                                                                                issued
                                                                                within
                                                                                15
                                                                                days
                                                                                from
                                                                                date
                                                                                of
                                                                                filing
                                                                                of
                                                                                TDS
                                                                                return
                                                                                for
                                                                                the
                                                                                respective
                                                                                quarter.
                                                                                However,
                                                                                Company
                                                                                will
                                                                                ensure
                                                                                timely
                                                                                deposit
                                                                                of
                                                                                TDS
                                                                                (deducted
                                                                                from
                                                                                the
                                                                                payments
                                                                                made
                                                                                to
                                                                                the
                                                                                SERVICE
                                                                                PROVIDER/REFERRER)
                                                                                as
                                                                                per
                                                                                the
                                                                                requirements
                                                                                under
                                                                                the
                                                                                relevant
                                                                                and
                                                                                applicable
                                                                                provisions
                                                                                of
                                                                                the
                                                                                Income
                                                                                Tax
                                                                                Act.
                                                                              </span>
                                                                            </li>
                                                                          </ol>
                                                                          <ol
                                                                            className="decimal_type"
                                                                            start={
                                                                              8
                                                                            }
                                                                            style={{
                                                                              listStyleType:
                                                                                "decimal",
                                                                            }}
                                                                          />
                                                                        </li>
                                                                        <li>
                                                                          <span
                                                                            style={{
                                                                              fontFamily:
                                                                                '"Calibri",sans-serif',
                                                                            }}
                                                                          >
                                                                            Either
                                                                            Party
                                                                            to
                                                                            this
                                                                            Agreement
                                                                            shall
                                                                            have
                                                                            a
                                                                            right
                                                                            to
                                                                            terminate
                                                                            this
                                                                            Agreement
                                                                            for
                                                                            convenience,
                                                                            with
                                                                            or
                                                                            without
                                                                            assigning
                                                                            any
                                                                            reasons,
                                                                            on
                                                                            giving
                                                                            not
                                                                            less
                                                                            than
                                                                            30
                                                                            (Thirty)
                                                                            days’
                                                                            prior
                                                                            written
                                                                            notice
                                                                            of
                                                                            intention
                                                                            to
                                                                            do
                                                                            so,
                                                                            to
                                                                            the
                                                                            other
                                                                            Party.
                                                                          </span>
                                                                          <ol
                                                                            className="decimal_type"
                                                                            start={
                                                                              9
                                                                            }
                                                                            style={{
                                                                              listStyleType:
                                                                                "decimal",
                                                                            }}
                                                                          >
                                                                            <li>
                                                                              <span
                                                                                style={{
                                                                                  fontFamily:
                                                                                    '"Calibri",sans-serif',
                                                                                }}
                                                                              >
                                                                                Notwithstanding
                                                                                anything
                                                                                herein
                                                                                contained,
                                                                                the
                                                                                Company
                                                                                may
                                                                                by
                                                                                giving
                                                                                15
                                                                                (Fifteen)
                                                                                days’
                                                                                notice
                                                                                in
                                                                                writing
                                                                                to
                                                                                the
                                                                                SERVICE
                                                                                PROVIDER/REFERRER
                                                                                terminate
                                                                                this
                                                                                Agreement
                                                                                in
                                                                                the
                                                                                event
                                                                                of
                                                                                any
                                                                                default
                                                                                by
                                                                                the
                                                                                SERVICE
                                                                                PROVIDER/REFERRER,
                                                                                if
                                                                                the
                                                                                default
                                                                                is
                                                                                curable
                                                                                and
                                                                                is
                                                                                not
                                                                                cured
                                                                                by
                                                                                the
                                                                                SERVICE
                                                                                PROVIDER/REFERRER
                                                                                within
                                                                                the
                                                                                period
                                                                                stipulated
                                                                                by
                                                                                Company.
                                                                              </span>
                                                                            </li>
                                                                            <li>
                                                                              <span
                                                                                style={{
                                                                                  fontFamily:
                                                                                    '"Calibri",sans-serif',
                                                                                }}
                                                                              >
                                                                                Notwithstanding
                                                                                anything
                                                                                herein
                                                                                contained,
                                                                                the
                                                                                Company
                                                                                may
                                                                                by
                                                                                giving
                                                                                15
                                                                                (Fifteen)
                                                                                days’
                                                                                notice
                                                                                in
                                                                                writing
                                                                                to
                                                                                the
                                                                                SERVICE
                                                                                PROVIDER/REFERRER,
                                                                                forthwith
                                                                                terminate
                                                                                this
                                                                                Agreement
                                                                                under
                                                                                any
                                                                                one
                                                                                or
                                                                                more
                                                                                of
                                                                                the
                                                                                following
                                                                                conditions,
                                                                                without
                                                                                penalty:
                                                                              </span>
                                                                              <ol
                                                                                className="decimal_type"
                                                                                start={
                                                                                  9
                                                                                }
                                                                                style={{
                                                                                  listStyleType:
                                                                                    "decimal",
                                                                                }}
                                                                              >
                                                                                <li>
                                                                                  <span
                                                                                    style={{
                                                                                      fontFamily:
                                                                                        '"Calibri",sans-serif',
                                                                                    }}
                                                                                  >
                                                                                    If,
                                                                                    in
                                                                                    the
                                                                                    reasonable
                                                                                    opinion
                                                                                    of
                                                                                    Company,
                                                                                    performance
                                                                                    of
                                                                                    any
                                                                                    of
                                                                                    the
                                                                                    Services
                                                                                    under
                                                                                    this
                                                                                    Agreement
                                                                                    by
                                                                                    the
                                                                                    SERVICE
                                                                                    PROVIDER/REFERRER
                                                                                    or
                                                                                    its
                                                                                    employees,
                                                                                    is
                                                                                    not
                                                                                    acceptable
                                                                                    as
                                                                                    being
                                                                                    in
                                                                                    contravention
                                                                                    of
                                                                                    any
                                                                                    laws
                                                                                    as
                                                                                    may
                                                                                    be
                                                                                    applicable
                                                                                    from
                                                                                    time
                                                                                    to
                                                                                    time
                                                                                    or
                                                                                    industry
                                                                                    practice
                                                                                    or
                                                                                    under
                                                                                    any
                                                                                    circumstances
                                                                                    which,
                                                                                    would
                                                                                    amount
                                                                                    to
                                                                                    objectionable
                                                                                    service.
                                                                                  </span>
                                                                                </li>
                                                                                <li>
                                                                                  <span
                                                                                    style={{
                                                                                      fontFamily:
                                                                                        '"Calibri",sans-serif',
                                                                                    }}
                                                                                  >
                                                                                    If
                                                                                    Company
                                                                                    is
                                                                                    informed
                                                                                    or
                                                                                    information
                                                                                    comes
                                                                                    to
                                                                                    the
                                                                                    Company’s
                                                                                    attention
                                                                                    that
                                                                                    the
                                                                                    SERVICE
                                                                                    PROVIDER/REFERRER
                                                                                    is
                                                                                    or
                                                                                    may
                                                                                    be
                                                                                    in
                                                                                    the
                                                                                    violation
                                                                                    of
                                                                                    any
                                                                                    law/s,
                                                                                    ordinance/s,
                                                                                    regulation/s,
                                                                                    code/s
                                                                                    or
                                                                                    court
                                                                                    or
                                                                                    quasi-judicial
                                                                                    order/s
                                                                                    or
                                                                                    there
                                                                                    is
                                                                                    an
                                                                                    explicit
                                                                                    and
                                                                                    formal
                                                                                    objection
                                                                                    raised
                                                                                    by
                                                                                    the
                                                                                    regulators/
                                                                                    auditors
                                                                                    with
                                                                                    regard
                                                                                    to
                                                                                    continuation
                                                                                    of
                                                                                    provision
                                                                                    of
                                                                                    Services
                                                                                    by
                                                                                    the
                                                                                    SERVICE
                                                                                    PROVIDER/REFERRER.
                                                                                  </span>
                                                                                </li>
                                                                                <li>
                                                                                  <span
                                                                                    style={{
                                                                                      fontFamily:
                                                                                        '"Calibri",sans-serif',
                                                                                    }}
                                                                                  >
                                                                                    If
                                                                                    the
                                                                                    SERVICE
                                                                                    PROVIDER/REFERRER
                                                                                    and/or
                                                                                    any
                                                                                    individual
                                                                                    assigned
                                                                                    by
                                                                                    it
                                                                                    for
                                                                                    the
                                                                                    performance
                                                                                    of
                                                                                    the
                                                                                    Services
                                                                                    under
                                                                                    this
                                                                                    Agreement
                                                                                    fails
                                                                                    to
                                                                                    perform
                                                                                    the
                                                                                    Services
                                                                                    under
                                                                                    this
                                                                                    Agreement
                                                                                    or
                                                                                    to
                                                                                    observe
                                                                                    any
                                                                                    of
                                                                                    its
                                                                                    obligations,
                                                                                    or
                                                                                    breaches
                                                                                    all
                                                                                    or
                                                                                    any
                                                                                    of
                                                                                    the
                                                                                    terms
                                                                                    of
                                                                                    this
                                                                                    Agreement.
                                                                                  </span>
                                                                                </li>
                                                                                <li>
                                                                                  <span
                                                                                    style={{
                                                                                      fontFamily:
                                                                                        '"Calibri",sans-serif',
                                                                                    }}
                                                                                  >
                                                                                    If
                                                                                    in
                                                                                    the
                                                                                    opinion
                                                                                    of
                                                                                    Company,
                                                                                    the
                                                                                    interests
                                                                                    of
                                                                                    Company
                                                                                    are
                                                                                    jeopardized
                                                                                    in
                                                                                    any
                                                                                    manner
                                                                                    whatsoever.
                                                                                  </span>
                                                                                </li>
                                                                                <li>
                                                                                  <strong>
                                                                                    <span
                                                                                      style={{
                                                                                        fontFamily:
                                                                                          '"Calibri",sans-serif',
                                                                                      }}
                                                                                    >
                                                                                      If
                                                                                      the
                                                                                      SERVICE
                                                                                      PROVIDER/REFERRER
                                                                                      discontinues
                                                                                      its
                                                                                      business.
                                                                                    </span>
                                                                                  </strong>
                                                                                  <ol
                                                                                    className="decimal_type"
                                                                                    start={
                                                                                      9
                                                                                    }
                                                                                    style={{
                                                                                      listStyleType:
                                                                                        "decimal",
                                                                                    }}
                                                                                  >
                                                                                    <li>
                                                                                      <span
                                                                                        style={{
                                                                                          fontFamily:
                                                                                            '"Calibri",sans-serif',
                                                                                        }}
                                                                                      >
                                                                                        If
                                                                                        there
                                                                                        is
                                                                                        an
                                                                                        event
                                                                                        of
                                                                                        any
                                                                                        change
                                                                                        in
                                                                                        the
                                                                                        constitution;
                                                                                        or
                                                                                        management
                                                                                        of
                                                                                        the
                                                                                        SERVICE
                                                                                        PROVIDER/REFERRER
                                                                                        whether
                                                                                        or
                                                                                        not
                                                                                        involving
                                                                                        a
                                                                                        change
                                                                                        in
                                                                                        the
                                                                                        ownership
                                                                                        structure,
                                                                                        unless
                                                                                        the
                                                                                        SERVICE
                                                                                        PROVIDER/REFERRER
                                                                                        informs
                                                                                        Company
                                                                                        and
                                                                                        obtains
                                                                                        the
                                                                                        prior
                                                                                        written
                                                                                        consent
                                                                                        from
                                                                                        Company
                                                                                        of
                                                                                        such
                                                                                        change;
                                                                                      </span>
                                                                                      <ol
                                                                                        className="decimal_type"
                                                                                        start={
                                                                                          9
                                                                                        }
                                                                                        style={{
                                                                                          listStyleType:
                                                                                            "decimal",
                                                                                        }}
                                                                                      >
                                                                                        <li>
                                                                                          <span
                                                                                            style={{
                                                                                              fontFamily:
                                                                                                '"Calibri",sans-serif',
                                                                                            }}
                                                                                          >
                                                                                            If
                                                                                            a
                                                                                            petition
                                                                                            for
                                                                                            insolvency
                                                                                            is
                                                                                            filed
                                                                                            against
                                                                                            the
                                                                                            SERVICE
                                                                                            PROVIDER/REFERRER
                                                                                            and
                                                                                          </span>
                                                                                        </li>
                                                                                      </ol>
                                                                                    </li>
                                                                                  </ol>
                                                                                </li>
                                                                              </ol>
                                                                            </li>
                                                                          </ol>
                                                                        </li>
                                                                      </ol>
                                                                    </li>
                                                                  </ol>
                                                                </li>
                                                              </ol>
                                                            </li>
                                                          </ol>
                                                        </li>
                                                      </ol>
                                                    </li>
                                                  </ol>
                                                </li>
                                              </ol>
                                            </li>
                                          </ol>
                                        </li>
                                      </ol>
                                    </li>
                                  </ol>
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>
                  <ol
                    className="decimal_type"
                    start={6}
                    style={{ listStyleType: "decimal" }}
                  />
                </li>
              </ol>
            </li>
          </ol>
          <p
            style={{
              marginTop: "3.75pt",
              marginRight: "6.55pt",
              marginBottom: ".0001pt",
              marginLeft: "113.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              such petition is not dismissed within 90 (Ninety) days after
              filing and/or if the SERVICE PROVIDER/REFERRER makes an
              arrangement for the benefit of its creditors or, if the Court
              Receiver is appointed as receiver of all/any of the SERVICE
              PROVIDER/REFERRER’ s properties.
            </span>
          </p>
          <p
            style={{
              marginTop: "7.4pt",
              marginRight: "6.45pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "116%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              9.5 Notwithstanding what is stated hereinabove, this Agreement
              shall, unless earlier terminated, continue to be operative in full
              force and effect, even after the expiry of the initial period{" "}
              <span>of 1 (One) year, on a month</span> to month basis until
              renewed or terminated by written notice. During such continuation
              of the Agreement the terms and conditions herein recorded shall be
              valid, subsisting and remain binding on both Parties.
            </span>
          </p>
          <ol
            className="decimal_type"
            start={9}
            style={{ listStyleType: "decimal", marginLeft: 31 }}
          >
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                Upon the termination or sooner determination of this Agreement
                for any reason, the SERVICE PROVIDER/REFERRER shall promptly
                return or destroy all Confidential Information in its possession
                or control, and shall destroy or, if applicable, erase any
                remaining copies of all such Confidential Information, including
                any electronically stored copies. An authorized person of the
                SERVICE PROVIDER/REFERRER shall certify in writing to such
                return and/or destruction and that no Confidential Information
                has been retained.
              </span>
              <ol
                className="decimal_type"
                start={9}
                style={{ listStyleType: "decimal" }}
              >
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    In the event that this Agreement is terminated for any
                    reasons, the SERVICE PROVIDER/REFERRER shall forthwith
                    hand-over to Company all documents, material and any other
                    property belonging to Company that may be in the possession
                    of the SERVICE PROVIDER/REFERRER or any of its employees,
                    agents or individuals assigned by the SERVICE
                    PROVIDER/REFERRER to perform the Services under this
                    Agreement.
                  </span>
                  <ol
                    className="decimal_type"
                    start={9}
                    style={{ listStyleType: "decimal" }}
                  >
                    <li>
                      <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                        It is hereby agreed and understood between the Parties
                        that the provisions of this Clause shall not limit,
                        restrict or preclude the Company from pursuing such
                        further and other legal actions, against the SERVICE
                        PROVIDER/REFERRER for any breach or non-compliance of
                        the terms of this Agreement.
                      </span>
                    </li>
                    <li>
                      <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                        Company shall not be liable for any damages, claims of
                        loss of profit, loss of investment or any such claims of
                        a similar nature, in the case of termination of the
                        Agreement by any mode or for whatever reasons.
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
          <p
            style={{
              marginTop: "7.5pt",
              marginRight: "6.9pt",
              marginBottom: ".0001pt",
              marginLeft: "68.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              textIndent: "-31.5pt",
              lineHeight: "92%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              Without prejudice to the above, in the event of the expiry or
              prior termination of the Agreement as aforesaid, the SERVICE
              PROVIDER/REFERRER shall unless otherwise agreed upon, do the
              following forthwith;
            </span>
          </p>
          <ol style={{ listStyleType: "lower-alpha", marginLeft: 67 }}>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                Ensure that all employees or other persons engaged by it and who
                are deputed to perform the Services in the Premises, return all
                identity/visiting cards available in their possession;
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                cease to use the name, trademark, symbol or logo of the Company
                on any stationery, letterhead, document, nameplate, furniture,
                representation or in any other manner;
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                return to the Company all stocks of stationery, promotional
                material of any kind, sales literature, brochures and all other
                items of similar nature; and
              </span>
            </li>
          </ol>
          <ol
            start={4}
            style={{ listStyleType: "lower-alpha", marginLeft: "71.05px" }}
          >
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                cease to represent Company or source Leads for Company, in any
                manner whatsoever.
              </span>
            </li>
          </ol>
          <ol
            start={5}
            style={{ listStyleType: "lower-alpha", marginLeft: 67 }}
          >
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                return or destroy all Confidential Information in its possession
                or control, and shall destroy or, if applicable, erase any
                remaining copies of all such Confidential Information, including
                any electronically stored copies. An authorized person of the
                Service Provider shall certify in writing to such return and/or
                destruction and that no copies of the Confidential Information
                have been retained.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                hand-over to Company all documents, material and any other
                property belonging to Company that may be in the possession of
                the SERVICE PROVIDER/REFERRER or any of its employees, agents or
                individuals assigned by the SERVICE PROVIDER/REFERRER to perform
                the Services under this Agreement.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The Service Provider/Referrer shall preserve all the documents
                necessary, relating to the Services, beyond any expiry or
                termination of this Agreement in accordance with applicable law.
              </span>
            </li>
          </ol>
          <ol start={11} style={{ listStyleType: "decimal" }}>
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  CONFIDENTIALITY:
                </span>
              </h3>
            </li>
          </ol>
          <ol style={{ listStyleType: "lower-alpha", marginLeft: 67 }}>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The SERVICE PROVIDER/REFERRER acknowledges and agrees that it is
                aware of the sensitivity and secrecy obligations of Company in
                keeping the Borrower information and transaction records and
                ensures that neither the SERVICE PROVIDER/REFERRER nor any of
                its personnel will do any act violating the same.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                It is hereby agreed by and between the Parties hereto that all
                Confidential Information shall be kept confidential and shall
                not be disclosed, except with the written permission of the
                Company, to third parties or otherwise use, except in connection
                with this Agreement and the performance of its duties and
                obligations thereunder, except for disclosures required by any
                law or required to be made to Governmental agencies. All such
                data compiled by the SERVICE PROVIDER/REFERRER shall be the
                exclusive property of Company and the SERVICE PROVIDER/REFERRER
                shall not have any rights over the data whatsoever.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                In the event of a breach or threatened breach by the SERVICE
                PROVIDER/REFERRER for the aforesaid clause, monetary damages may
                not be an adequate remedy; therefore, Company shall be entitled
                to injunctive relief in addition to monetary damages to restrain
                the SERVICE PROVIDER/REFERRER from any such breach, threatened
                or actual.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The SERVICE PROVIDER/REFERRER agrees to: (i) take all necessary
                action to protect the Confidential Information against misuse,
                loss, destruction, deletion and/or alteration; (ii) Not to
                misuse or permit misuse directly or indirectly, commercially
                exploit the Confidential Information for economic or other
                benefit; (iii) Not to make or retain any copies or record of any
                Confidential Information submitted by Company other than as may
                be required for the performance of the SERVICE
                PROVIDER/REFERRER’s obligation under this Agreement; (iv) Notify
                Company promptly of any unauthorized or improper use or
                disclosure of the Confidential Information; (v) Notify Company
                promptly of any court order to disclose information recorded on
                documents or disclose any confidential information, it shall
                notify Company in writing, in sufficient detail, immediately
                upon receipt of such court order, subpoena or other legal or
                regulatory direction/ request or similar process, in order to
                permit Company to make an application for an appropriate
                protective order. Such notice shall be accompanied by a copy of
                the Court order, subpoena or other legal or regulatory
                direction/request or similar process. The SERVICE
                PROVIDER/REFERRER shall give an opportunity to Company to move
                the appropriate court in appeal to obtain a stay order if
                Company so desires;
              </span>
            </li>
          </ol>
          <p
            style={{
              marginTop: ".35pt",
              marginRight: "6.7pt",
              marginBottom: ".0001pt",
              marginLeft: "77.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              (vi) Return all the Confidential Information that is in the
              custody of the SERVICE PROVIDER/REFERRER upon termination/expiry
              of this Agreement along with all copies and replicas and
              facsimiles thereof; (vii) Ensure that the Do Not Call Registry
              numbers are not passed on to any unauthorized person/s or misused
              in any manner.
            </span>
          </p>
          <div
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <ol
              start={5}
              style={{
                marginBottom: "0cm",
                listStyleType: "lower-alpha",
                marginLeft: 67,
              }}
            >
              <li
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "8.0pt",
                  marginLeft: "0cm",
                  fontSize: "11.0pt",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  The SERVICE PROVIDER/REFERRER hereby unconditionally agrees
                  and undertakes that it shall not and that its personnel shall
                  not disclose the terms and conditions of this Agreement or
                  disclose the information submitted by Company under this
                  Agreement to any third party unless such disclosure is
                  required by law or for the purpose of performing the SERVICE
                  PROVIDER/REFERRER’s obligations under this Agreement, provided
                  that prior to any such disclosure, Company’s written consent
                  shall be obtained.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "3.75pt",
              marginRight: "6.2pt",
              marginBottom: ".0001pt",
              marginLeft: "77.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              It shall be incumbent upon the SERVICE PROVIDER/REFERRER to
              undertake not to
            </span>
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              disclose any business related information of Company to any third
              person and the SERVICE PROVIDER/REFERRER shall keep all knowledge
              of the business activities and affairs of Company strictly
              confidential and also to ensure that neither the SERVICE
              PROVIDER/REFERRER nor any of its personnel directly or indirectly
              assist any third person with the promotion of activities which may
              be prejudicial to the interest or in competition to the activities
              of Company.
            </span>
          </p>
          <ol
            start={6}
            style={{ listStyleType: "lower-alpha", marginLeft: 67 }}
          >
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                Access to Borrower information by staff of the SERVICE
                PROVIDER/REFERRER shall be limited to those areas where the
                information is required in order to perform the services.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The Service Provider shall isolate and clearly identify the
                information, documents, records pertaining to the Leads, and
                shall ensure that there is no co-mingling of such records with
                any other records of the Service Provider. The Service Provider
                shall ensure that all data, information and records are stored
                only in servers that are located in India.
              </span>
            </li>
          </ol>
          <ol
            start={8}
            style={{ listStyleType: "lower-alpha", marginLeft: "66.85px" }}
          >
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The provisions of this Section shall survive the termination of
                this Agreement.
              </span>
            </li>
          </ol>
          <ol start={12} style={{ listStyleType: "decimal" }}>
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  INTELLECTUAL PROPERTY RIGHT:
                </span>
              </h3>
            </li>
          </ol>
          <p
            style={{
              marginTop: "7.7pt",
              marginRight: "6.55pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              Each Party acknowledges that nothing contained in this Agreement
              transfers to the other Party any right, title or proprietary
              interest (including without limitation any intellectual property
              rights), in any part which are the subject matter hereof, or any
              proprietary information (including without limitation any
              trademarks, service marks, trade names, or logos ("
              <strong>Marks</strong>
              "), trade secrets, knowhow, inventions, patents (including any
              applications, extensions, continuations, renewals and re-issues
              thereof), copyrights, designs and industrial designs).
            </span>
          </p>
          <p
            style={{
              marginTop: "6.25pt",
              marginRight: "6.25pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "113%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              Parties agree that they shall use the proprietary marks of each
              other in relation to this Agreement. SERVICE PROVIDER/REFERRER
              shall furnish to the Company all such materials and advertisements
              prior to circulating or publishing and obtain prior written
              consent of the Company which consent shall not be unreasonably
              withheld.
            </span>
          </p>
          <p
            style={{
              marginTop: "5.75pt",
              marginRight: "5.9pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              The Parties acknowledge and accept that any use of the
              intellectual property rights of Company and/or SERVICE
              PROVIDER/REFERRER on the materials, advertisements etc. relating
              to the Services and other matters incidental thereto or related
              therewith shall not constitute an infringement of the intellectual
              property rights of Company and/or SERVICE PROVIDER/REFERRER under
              applicable laws.
            </span>
            <span style={{ fontFamily: '"Calibri",sans-serif' }}> </span>
          </p>
          <p
            style={{
              marginTop: "6.1pt",
              marginRight: "6.6pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              The Parties further agree that neither Party shall acquire any
              right whatsoever through use in commerce in the intellectual
              property rights of the other Party on account of permitted use in
              terms of the above and all such rights pertaining to use and title
              of all intellectual property rights shall exclusively vest with
              such Party.
            </span>
          </p>
          <p
            style={{
              marginTop: "6.1pt",
              marginRight: "6.85pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              The Parties covenant that they shall forthwith upon learning of
              any unauthorized reproduction, use, or modifications of the
              intellectual property rights of the other Party inform such Party
              of the same and will assist the said Party in taking all actions
              deemed necessary against such acts.
            </span>
          </p>
          <p
            style={{
              marginTop: "6.05pt",
              marginRight: "6.15pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              The Parties covenant that the said usage of the Marks shall be
              during the tenure of the present arrangement and shall cease upon
              termination of the same. It is clearly understood between the
              Parties that there shall be no consideration for the use of the
              trademark/ tradename of the SERVICE PROVIDER/REFERRER and/or
              Company and the usage of the SERVICE PROVIDER/REFERRER’s and/or
              Company’s Marks does not give any right in the information on
            </span>
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              the application form to the SERVICE PROVIDER/REFERRER. Upon
              termination or dis-continuation of this arrangement on any grounds
              whatsoever, SERVICE PROVIDER/REFERRER and/or Company
            </span>
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              shall forthwith: cease to use the name, trademark, symbol or logo
              of the Company and/or the SERVICE PROVIDER/REFERRER on any
              stationery, letterhead, document, nameplate, furniture, marketing
              material, product brochures, representation or in any other
              manner;
            </span>
          </p>
          <div
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <ol
              start={13}
              style={{ marginBottom: "0cm", listStyleType: "decimal" }}
            >
              <li
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "8.0pt",
                  marginLeft: "0cm",
                  fontSize: "11.0pt",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <h3
                  style={{
                    marginTop: "6.2pt",
                    marginRight: "0cm",
                    marginBottom: "0cm",
                    marginLeft: "61.65pt",
                    textIndent: "-20.7pt",
                    fontSize: 15,
                    fontFamily: '"Cambria",serif',
                  }}
                >
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    FORCE MAJEURE:
                  </span>
                </h3>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "7.7pt",
              marginRight: "6.65pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              Notwithstanding anything to the contrary in this Agreement, a
              Party hereto shall not be liable to the other Party for any loss,
              injury, delay, damages or other injury suffered or incurred by
              such other Party due to strikes, lock-outs, riots, storms, fire,
              explosions, acts of God, war whether declared or not, Government
              or police action or any other cause which is beyond the reasonable
              control of such Party nor shall any failure or delay by either
              Party hereto in the performance of any of its obligations under
              this Agreement due to one or more of such causes be construed as a
              breach of this Agreement or any provision of this Agreement, as
              the case may be, for the purpose of it being considered a default
              under the termination clause or for any other purpose.
            </span>
          </p>
          <div
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <ol
              start={14}
              style={{ marginBottom: "0cm", listStyleType: "decimal" }}
            >
              <li
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "8.0pt",
                  marginLeft: "0cm",
                  fontSize: "11.0pt",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <h3
                  style={{
                    marginTop: "6.2pt",
                    marginRight: "0cm",
                    marginBottom: "0cm",
                    marginLeft: "61.65pt",
                    textIndent: "-20.7pt",
                    fontSize: 15,
                    fontFamily: '"Cambria",serif',
                  }}
                >
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    NON- WAIVER:
                  </span>
                </h3>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "7.75pt",
              marginRight: "6.6pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              No admission or delay on the part of any Party hereto in requiring
              the due and punctual fulfillment by any other Party or Parties
              hereto of the obligations of such other Party hereunder shall be
              deemed to constitute a waiver by omitting or delaying Party of any
              of its rights to require such due and punctual fulfillment.
            </span>
          </p>
          <ol start={15} style={{ listStyleType: "decimal" }}>
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  REPRESENTATIONS AND WARRANTIES:
                </span>
              </h3>
            </li>
          </ol>
          <ol style={{ listStyleType: "lower-alpha", marginLeft: 67 }}>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The SERVICE PROVIDER/REFERRER hereby represents that it has the
                authority, skill, experience and resources to render services
                hereunder and shall so render the services in an ethical and
                bona fide manner and in compliance with all laws and
                regulations.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The SERVICE PROVIDER/REFERRER shall comply with all applicable
                laws in relation to the “National Do Not Call Registry” (the “
                <strong>DNC Registry</strong>”).
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The SERVICE PROVIDER/REFERRER is duly organized, validly
                existing, and in good standing under the laws of India and has
                full power and authority to enter into this Agreement and to
                perform its obligations under this Agreement.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The SERVICE PROVIDER/REFERRER has obtained all necessary
                licenses, permissions, registrations (as applicable) including
                but not limited to Shops and Establishment Act, for all its
                office locations and Service Tax registration. In addition, the
                SERVICE PROVIDER/REFERRER shall make necessary nominations under
                the Payment of Gratuity Act, 1972, Employees Provident Fund
                &amp; Miscellaneous Provisions Act, 1952, Employee State
                Insurance Act, 1948, etc. for all his employees with the
                necessary authorities.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The SERVICE PROVIDER/REFERRER shall take and has appropriate
                mechanisms and means to ensure adequate precautions to protect
                the privacy and confidentiality of all data pertaining to
                Company, the Borrowers, any other third party in relation to
                this Agreement or the services and shall take appropriate
                precautions not to breach the privacy of Company, the Borrowers,
                or any third party during the course of performance of its
                obligations herein.
              </span>
            </li>
          </ol>
          <ol
            className="decimal_type"
            start={16}
            style={{ listStyleType: "decimal" }}
          >
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  INDEMNIFICATION:
                </span>
              </h3>
              <ol
                className="decimal_type"
                start={16}
                style={{ listStyleType: "decimal" }}
              >
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    The SERVICE PROVIDER/REFERRER shall at its own expense,
                    indemnify, defend and hold harmless Company and its
                    officers, directors, personnel, representatives,
                    consultants, nominees, designees from and against any and
                    all liability suffered or incurred (including but not
                    limited to liabilities, judgments, damages, losses, claims,
                    costs and expenses, including attorney’s fees and expenses)
                    or any other loss that may occur, arising from or relating
                    to: (i) Non-performance, inadequate performance, provision
                    of deficient services, breach, misconduct or negligence by
                    the SERVICE PROVIDER/REFERRER, its personnel of any of the
                    terms, conditions, covenants, representations, undertakings,
                    obligations or warranties under this Agreement, including
                    but not limited to the obligations of the SERVICE
                    PROVIDER/REFERRER pertaining to data protection,
                    confidentiality and intellectual property rights of Company
                    , or any guidelines issued by Company to the SERVICE
                    PROVIDER/REFERRER from time to time; (ii) Acts, omissions,
                    errors, representations, misrepresentations, misconduct,
                    negligence of the SERVICE PROVIDER/REFERRER and/or its
                    personnel in performance of its obligations under this
                    Agreement; (iii) Loss, misappropriations, misuse or damage
                    to the documents or instruments/things that are in the
                    possession of the SERVICE PROVIDER/REFERRER or its personnel
                    or any other persons engaged by the SERVICE
                    PROVIDER/REFERRER or within the control of the SERVICE
                    PROVIDER/REFERRER; (iv) Any loss, damages to the Borrowers
                    through any acts or omissions of the SERVICE
                    PROVIDER/REFERRER and/or its personnel; (v) Contravention of
                    any law, as may be applicable from time to time, or industry
                    practice, or violation of any policies of Company .
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    In the event of any claims being made on Company, the
                    SERVICE PROVIDER/REFERRER undertakes to pay on first demand
                    made by Company of any amount on this account without any
                  </span>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    demur, reservation, contest, protest whatsoever within 2
                    (Two) working days of the demand being
                  </span>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    made. Company shall also be entitled to settle any or all
                    Claims made on it and recover the amount so paid from the
                    SERVICE PROVIDER/REFERRER.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    The SERVICE PROVIDER/REFERRER hereby authorizes Company to
                    make deductions to any sum, being the cost, expenses, loss
                    or damage suffered by it on account of any default or breach
                    committed by the SERVICE PROVIDER/REFERRER or its employees,
                    agents, representatives, as mentioned hereinabove or
                    hereinafter. The decision of Company as to the amount of
                    cost, expenses, loss or damage suffered shall be final and
                    binding on the SERVICE PROVIDER/REFERRER and the SERVICE
                    PROVIDER/REFERRER shall not protest, contest the same on any
                    ground whatsoever.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    The SERVICE PROVIDER/REFERRER shall not set up defense or
                    claim in any suit, plaint, petition, complaint, written
                    statement, application to the effect that the employees of
                    the SERVICE PROVIDER/REFERRER are the employees of Company
                    and in the event any defense is set up the same shall be a
                    fraud upon the court or authority where such defense or
                    claim is set up.
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  CONSTRUCTION:
                </span>
              </h3>
              <ol
                className="decimal_type"
                start={17}
                style={{ listStyleType: "decimal" }}
              >
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    This Agreement sets forth the entire Agreement and
                    understanding among the Parties with respect to the subject
                    matter hereof and merges all discussions and negotiations
                    among them, and none of the Parties shall be bound by any
                    conditions, understandings or representations with respect
                    to such subject matter other than those expressly provided
                    therein or duly set forth on or subsequent to the date
                    hereof in writing and signed by a proper and duly authorized
                    officer of the Party to be bound thereby.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    The Articles, Sections, paragraphs and other headings
                    contained in this Agreement are for general reference and
                    guidance and shall not be conclusive as to the meaning or
                    interpretation of this Agreement.
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  COUNTERPARTS:
                </span>
              </h3>
            </li>
          </ol>
          <p
            style={{
              marginTop: "7.5pt",
              marginRight: "6.65pt",
              marginBottom: ".0001pt",
              marginLeft: "77.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              textIndent: "-36.0pt",
              lineHeight: "92%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              This Agreement shall be executed simultaneously in 2 (Two) or more
              counterparts each of which shall be deemed to be an original but
              all of which together shall constitute 1 (One) instrument.
            </span>
          </p>
          <div
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <ol
              start={19}
              style={{ marginBottom: "0cm", listStyleType: "decimal" }}
            >
              <li
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "8.0pt",
                  marginLeft: "0cm",
                  fontSize: "11.0pt",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <h3
                  style={{
                    marginTop: "6.2pt",
                    marginRight: "0cm",
                    marginBottom: "0cm",
                    marginLeft: "61.65pt",
                    textIndent: "-20.7pt",
                    fontSize: 15,
                    fontFamily: '"Cambria",serif',
                  }}
                >
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    AMENDMENTS:
                  </span>
                </h3>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "7.45pt",
              marginRight: "6.8pt",
              marginBottom: ".0001pt",
              marginLeft: "77.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              textIndent: "-36.0pt",
              lineHeight: "92%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              This Agreement shall not be amended, altered or modified except by
              an instrument in writing expressly referring to this Agreement and
              signed by the Parties.
            </span>
          </p>
          <p
            style={{
              marginTop: "6.05pt",
              marginRight: "6.7pt",
              marginBottom: ".0001pt",
              marginLeft: "77.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              textIndent: "-36.0pt",
              lineHeight: "92%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              This Agreement supersedes and nullifies all previous
              arrangements/discussions between the Parties in relation to the
              Services or otherwise.
            </span>
          </p>
          <div
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <ol
              start={20}
              style={{ marginBottom: "0cm", listStyleType: "decimal" }}
            >
              <li
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "8.0pt",
                  marginLeft: "0cm",
                  fontSize: "11.0pt",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <h3
                  style={{
                    marginTop: "6.2pt",
                    marginRight: "0cm",
                    marginBottom: "0cm",
                    marginLeft: "61.65pt",
                    textIndent: "-20.7pt",
                    fontSize: 15,
                    fontFamily: '"Cambria",serif',
                  }}
                >
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    ASSIGNMENT
                  </span>
                </h3>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "7.7pt",
              marginRight: "6.65pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              Company may at any time, at its sole discretion assign, cause to
              assign, and/or transfer any of its rights, benefits, obligations
              or duties accruing under this Agreement to any of its affiliates,
              subsidiaries, group Companies or holding Company and SERVICE
              PROVIDER/REFERRER hereby expressly agrees that in that event,
              Company is not required to obtain any prior permission from the
              SERVICE PROVIDER/REFERRER.
            </span>
          </p>
          <p
            style={{
              marginTop: "6.1pt",
              marginRight: "6.5pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              The SERVICE PROVIDER/REFERRER shall not assign, transfer or
              subcontract any of its rights and responsibilities contained in
              this Agreement to any sub-agent or subcontractor without prior
              written permission of Company, which Company may deny at its
              absolute discretion and if Company gives such prior written
              permission (which permission may be of a general or specific
              nature), it shall not be construed as waiver of any accrued rights
              and/or liabilities and The SERVICE PROVIDER/REFERRER shall be
              fully responsible for all acts and omissions of its sub-
              contractors or sub-agents.
            </span>
          </p>
          <div
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <ol
              start={21}
              style={{ marginBottom: "0cm", listStyleType: "decimal" }}
            >
              <li
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "8.0pt",
                  marginLeft: "0cm",
                  fontSize: "11.0pt",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <h3
                  style={{
                    marginTop: "6.2pt",
                    marginRight: "0cm",
                    marginBottom: "0cm",
                    marginLeft: "61.65pt",
                    textIndent: "-20.7pt",
                    fontSize: 15,
                    fontFamily: '"Cambria",serif',
                  }}
                >
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    ADDITIONAL DOCUMENTS:
                  </span>
                </h3>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "7.5pt",
              marginRight: "6.55pt",
              marginBottom: ".0001pt",
              marginLeft: "77.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              textIndent: "-36.0pt",
              lineHeight: "92%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              Each Party hereto shall promptly execute and deliver such
              additional documents as are reasonably required by any other Party
              hereto for the purpose of implementing this Agreement. Provided
              that no such document shall be inconsistent with the provisions
              hereof.
            </span>
          </p>
          <ol
            className="decimal_type"
            start={22}
            style={{ listStyleType: "decimal" }}
          >
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  INDEPENDENT PARTIES:
                </span>
              </h3>
              <ol
                className="decimal_type"
                start={22}
                style={{ listStyleType: "decimal" }}
              >
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    The Parties shall act in all matters pertaining to this
                    Agreement as independent Parties. The Agreement is being
                    entered into on a principal to principal basis and does not
                    and shall not be deemed to make either Party an agent,
                    partner or joint venture partner of the other or any
                    analogous relationship. No contention to the contrary will
                    be raised at any time by either Party. It is expressly
                    agreed by the Parties that Company shall not be liable or
                    responsible in any manner nor be liable to pay any monetary
                    compensation in the event of the death of, or in the event
                    of any injury sustained by any employee, agent,
                    representative of the SERVICE PROVIDER/REFERRER during
                    performance of their functions or rendering services under
                    this Agreement.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    The SERVICE PROVIDER/REFERRER shall ensure that all visiting
                    cards of its employee mention the name/entity of the SERVICE
                    PROVIDER/REFERRER clearly, as associate of the Company, and
                    that all identity cards issued to its employees clearly
                    display the name/entity of the SERVICE PROVIDER/REFERRER and
                    the designation or work profile/nature of work of the
                    relevant employee in the Company /Business entity of the
                    SERVICE PROVIDER/REFERRER. The requirements of this clause
                    shall, so far as engagement of non-employees by the SERVICE
                    PROVIDER/REFERRER is concerned, be applicable in like manner
                    as it would have applied in the context of employees.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    All bills/invoices and other communications from the SERVICE
                    PROVIDER/REFERRER to any party must necessarily carry/show
                    the name/entity of the SERVICE PROVIDER/REFERRER only and
                    not mention the name of Company.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    It is agreed between the Parties that this Agreement is on a
                    principal to principal basis and does not create any
                    employer-employee or principal-agent relationship between
                    the Company and Service Provider/Referrer.
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  REPRESENTATIONS ON AUTHORITY:
                </span>
              </h3>
            </li>
          </ol>
          <p
            style={{
              marginTop: "7.5pt",
              marginRight: "6.7pt",
              marginBottom: ".0001pt",
              marginLeft: "77.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              textIndent: "-36.0pt",
              lineHeight: "92%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              Each signatory to this Agreement represents and warrants that he
              is duly authorized by the Party for and on whose behalf, he is
              signing this Agreement to execute the same in a manner binding
              upon said Party and that all corporate approvals and procedures
              necessary for vesting such authority in him have been duly
              obtained and complied with.
            </span>
          </p>
          <div
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <ol
              start={24}
              style={{ marginBottom: "0cm", listStyleType: "decimal" }}
            >
              <li
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "8.0pt",
                  marginLeft: "0cm",
                  fontSize: "11.0pt",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <h3
                  style={{
                    marginTop: "6.2pt",
                    marginRight: "0cm",
                    marginBottom: "0cm",
                    marginLeft: "61.65pt",
                    textIndent: "-20.7pt",
                    fontSize: 15,
                    fontFamily: '"Cambria",serif',
                  }}
                >
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    PARTIAL INVALIDITY:
                  </span>
                </h3>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "7.45pt",
              marginRight: "6.45pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "92%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              It is expressly agreed and declared that if, for any reason
              whatsoever after execution hereof, any Court of competent
              jurisdiction holds any provision hereof to be illegal, void or
              unenforceable, such provision shall be of no force and effect, but
              the illegality or unenforceability of such provision shall have no
              effect upon and shall not impair the legality, validity and
              enforceability of any other provisions of this Agreement.
            </span>
          </p>
          <div
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <ol
              start={25}
              style={{ marginBottom: "0cm", listStyleType: "decimal" }}
            >
              <li
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "8.0pt",
                  marginLeft: "0cm",
                  fontSize: "11.0pt",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <h3
                  style={{
                    marginTop: "6.2pt",
                    marginRight: "0cm",
                    marginBottom: "0cm",
                    marginLeft: "61.65pt",
                    textIndent: "-20.7pt",
                    fontSize: 15,
                    fontFamily: '"Cambria",serif',
                  }}
                >
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    GOVERNING LAW:
                  </span>
                </h3>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "7.5pt",
              marginRight: "6.7pt",
              marginBottom: ".0001pt",
              marginLeft: "77.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              textIndent: "-36.0pt",
              lineHeight: "92%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              This Agreement shall be governed by and construed in accordance
              with the laws of India and the court of Delhi, shall have the
              exclusive jurisdiction to entertain, try and dispose off any
              matter in connection with this Agreement.
            </span>
          </p>
          <ol
            className="decimal_type"
            start={26}
            style={{ listStyleType: "decimal" }}
          >
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  ARBITRATION:
                </span>
              </h3>
              <ol
                className="decimal_type"
                start={26}
                style={{ listStyleType: "decimal" }}
              >
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    Any dispute, controversy or difference which may arise
                    between the Parties out of or in relation to or in
                    connection with this Agreement, shall first be resolved by
                    amicable negotiations within 30 (Thirty) days of the
                    commencement of discussions, failing which the matter shall
                    be referred to the managing directors of the disputing
                    Parties. If no resolution is reached by the Parties within
                    30 (Thirty) days of commencement of discussions, the dispute
                    shall be submitted for exclusive resolution
                  </span>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}></span>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    by arbitration under the Indian Arbitration and Conciliation
                    Act, 1996, or any statutory modification or re-enactment
                    thereof, by a sole arbitrator who is mutually appointed by
                    the Parties. The arbitration shall be held in Delhi only and
                    the language to be used in the arbitration proceedings shall
                    be English. The existence of any dispute shall not release
                    the Parties of their respective obligations under this
                    Agreement.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    Each Party further agrees that it shall not commence or
                    maintain any suit or legal proceeding concerning a dispute
                    hereunder until such dispute has been finally settled in
                    accordance with the arbitration procedure provided for
                    herein and then only for enforcement of the arbitral award,
                    if any.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    Notwithstanding the aforesaid, each Party shall have the
                    right to institute judicial proceedings against the other
                    Party or any one acting through or under such Party in order
                    to enforce the instituting Party’s rights hereunder through
                    amendment of contract, specific performance, injunction or
                    similar equitable relief.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    The courts of Delhi shall have exclusive jurisdiction to try
                    and entertain any proceedings arising out of or in relation
                    to this Agreement.
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  NOTICES:
                </span>
              </h3>
            </li>
          </ol>
          <p
            style={{
              marginTop: "6.05pt",
              marginRight: "6.75pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              All notices and other communications in respect of this Agreement
              shall be given in writing in English by registered airmail,
              postage prepaid, or by facsimile to the Party entitled thereto at
              its address set forth below, or such other address as it shall
              hereafter designate for this purpose:
            </span>
          </p>
          <p
            style={{
              marginTop: "6.2pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "41.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "6.2pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "41.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "6.2pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "41.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "6.2pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "41.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}>
              A) In case of notice to
              <strong>Company at</strong>:
            </span>
          </p>
          <p
            style={{
              marginTop: "7.15pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "46.55pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <strong>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                R.K. BANSAL FINANCE PVT. LTD.
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "7.15pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "46.55pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>Address:</span>
            <span
              style={{
                fontSize: 13,
                fontFamily: '"Arial",sans-serif',
                color: "black",
                background: "white",
              }}
            >
              8/9, Pusa Rd, Block 8, WEA, Karol Bagh, Delhi, 110005
            </span>
          </p>
          <p
            style={{
              marginTop: ".2pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "46.55pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}>
              Telephone: 98999-85495
            </span>
          </p>
          <p
            style={{
              marginTop: ".3pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "46.55pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}>
              Email: info@ramfincorp.com
            </span>
          </p>
          <p
            style={{
              marginTop: ".3pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "46.55pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}>
              B) In case of notice to
            </span>
            <strong>
              <span style={{ fontFamily: "Calibri" }}>
                SERVICE PROVIDER/REFERRER
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: ".3pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "46.55pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <strong>
              <span style={{ fontFamily: "Calibri" }}>
                SERVICE PROVIDER/REFERRER
              </span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "7.15pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "46.55pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span
              style={{
                fontFamily: '"Calibri",sans-serif',
              }}
            >
              Address: <strong>{data?.profile?.address}</strong>
            </span>
          </p>
          <p
            style={{
              marginTop: ".2pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "46.55pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}>
              Telephone: <strong>{data?.phone}</strong>
            </span>
          </p>
          <p
            style={{
              marginTop: ".3pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "46.55pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}>
              Email: <strong>{data?.email}</strong>
            </span>
          </p>
          <p
            style={{
              marginTop: "9.35pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              or such other address as the Party to whom such writing is to be
              given shall have last notified to the Party giving the same in the
              manner provided in this Clause.
            </span>
          </p>
          <p
            style={{
              marginTop: "7.5pt",
              marginRight: "6.8pt",
              marginBottom: ".0001pt",
              marginLeft: "41.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              lineHeight: "92%",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              The notice will be deemed to have been served on the SERVICE
              PROVIDER/REFERRER on proof of having posted/delivered the notice
              to the addresses given herein. The notice will be considered to be
              received by Company on actual receipt of the said notice.
            </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span
              style={{ fontSize: 17, fontFamily: '"Calibri",sans-serif' }}
            ></span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span
              style={{ fontSize: 17, fontFamily: '"Calibri",sans-serif' }}
            ></span>
          </p>
          <p
            style={{
              marginTop: ".35pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span
              style={{ fontSize: 19, fontFamily: '"Calibri",sans-serif' }}
            ></span>
          </p>
          <ol
            className="decimal_type"
            start={28}
            style={{ listStyleType: "decimal" }}
          >
            <li>
              <h3
                style={{
                  marginTop: "6.2pt",
                  marginRight: "0cm",
                  marginBottom: "0cm",
                  marginLeft: "61.65pt",
                  textIndent: "-20.7pt",
                  fontSize: 15,
                  fontFamily: '"Cambria",serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  COMPLIANCE WITH LAWS:
                </span>
              </h3>
              <ol
                className="decimal_type"
                start={28}
                style={{ listStyleType: "decimal" }}
              >
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    At all times SERVICE PROVIDER/REFERRER shall keep itself
                    fully informed of applicable laws, ordinances, regulations,
                    rules and orders of the land, in performances of the
                    Services including procurement and renewal of licenses,
                    permits, and certificates and payment of taxes where
                    required.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    The SERVICE PROVIDER/REFERRER shall not hold Company liable
                    for any non-compliance as set out in clause 26.2 above and
                    the SERVICE PROVIDER/REFERRER shall, at its own expense,
                  </span>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    indemnify, defend and hold harmless Company and Affiliates
                    of Company, their respective officers,
                  </span>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    directors, personnel, representatives, consultants,
                    nominees, designees from and against any damages, losses,
                    claims, costs and expenses pertaining to the non-compliance
                    of the clause above.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    The SERVICE PROVIDER/REFERRER shall at its own cost, obtain
                    for itself and renew in accordance with requirement/law,
                    licenses and permits required by the SERVICE
                    PROVIDER/REFERRE to provide the Services and shall submit
                    such returns, papers or documents to any government or local
                    authority as may be required by Law. All taxes, fees,
                    charges and other outgoings incurred or to be incurred under
                    any Laws shall be at the complete cost and responsibility of
                    the SERVICE PROVIDER/REFERRER. Any loss caused, penalty
                    imposed or legal obligation created on account of the
                    failure to adhere to any Laws in regard to submission of
                    such returns as may be required, shall be borne by the
                    SERVICE PROVIDER/REFERRER and the Company shall not be
                    liable for any damages or payments in this regard.
                  </span>
                </li>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    The SERVICE PROVIDER/REFERRER shall ensure to register,
                    obtain and renew in
                  </span>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    accordance with requirement/law, licenses (as applicable)
                    and comply with the provisions and
                  </span>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    requirements of the relevant Labour Laws including but not
                    limited to Minimum Wages Act, Provident Fund Act, Employees’
                    State Insurance Act etc. and other applicable statutory
                    enactment’s including but not limited to the Bombay Shops
                    and Establishment Act, 1948, for all its office locations
                    (which office locations, as well as any other addresses of
                    the SERVICE PROVIDER/REFERRER shall be maintained by the
                    SERVICE PROVIDER/REFERRER independent of and in addition to
                    the offices of the Company), and will provide Services in
                    full compliance with all laws, ordinances, regulations,
                    rules and order of the land. The SERVICE PROVIDER/REFERRER
                    shall ensure that it has its own independent registrations
                    and employer code numbers under the Provident Fund Act and
                    the Employees State Insurance Act.
                  </span>
                </li>
              </ol>
            </li>
          </ol>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span
              style={{ fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            ></span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span
              style={{ fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            ></span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span
              style={{ fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
            ></span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <strong>
              <u>
                <span style={{ fontFamily: "Calibri" }}>
                  <span style={{ textDecoration: "none" }}> </span>
                </span>
              </u>
            </strong>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <strong>
              <u>
                <span style={{ fontFamily: "Calibri" }}>
                  <span style={{ textDecoration: "none" }}> </span>
                </span>
              </u>
            </strong>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <strong>
              <u>
                <span style={{ fontFamily: "Calibri" }}>
                  <span style={{ textDecoration: "none" }}> </span>
                </span>
              </u>
            </strong>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <strong>
              <u>
                <span style={{ fontFamily: "Calibri" }}>
                  <span style={{ textDecoration: "none" }}> </span>
                </span>
              </u>
            </strong>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "center",
            }}
          >
            <strong>
              <u>
                <span style={{ fontFamily: "Calibri" }}>ANNEXURE I</span>
              </u>
            </strong>
          </p>
          <p
            style={{
              marginTop: "1.55pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}>
              This <strong>ANNEXURE I </strong>
              is annexed to and is made an integral part of this Agreement{" "}
              <span style={{ fontWeight: "bold" }}>
                dated {new Date().toDateString()}
              </span>{" "}
            </span>
            <span style={{ fontFamily: "Calibri" }}>
              between <strong>R.K. BANSAL FINANCE PVT. LTD. </strong>
              and
              <strong>the SERVICE PROVIDER/REFERRE</strong>
            </span>
            <br />
            <span style={{ fontFamily: "Calibri" }}>
              [CONSIDERATION FOR SERVICES RENDERED]
            </span>
          </p>
          <p
            style={{
              marginTop: ".15pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 19, fontFamily: '"Calibri",sans-serif' }}
              ></span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <strong>
              <span style={{ fontFamily: "Calibri" }}>Company </span>
            </strong>
            <span style={{ fontFamily: "Calibri" }}>
              shall pay to
              <strong>the SERVICE PROVIDER/REFERRER. </strong>
              The following payout structure:
            </span>
          </p>
          <p
            style={{
              marginTop: "1.85pt",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <strong>
              <u>
                <span style={{ fontFamily: "Calibri" }}>PAYOUT STRUCTURE</span>
              </u>
            </strong>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 13, fontFamily: '"Calibri",sans-serif' }}
              ></span>
            </strong>
          </p>
          <div
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <ol
              style={{
                marginBottom: "0cm",
                listStyleType: "decimal",
                marginLeft: 26,
              }}
            >
              <li
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "8.0pt",
                  marginLeft: "0cm",
                  fontSize: "11.0pt",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <strong>
                  <span
                    style={{ fontFamily: '"Calibri",sans-serif', fontSize: 15 }}
                  >
                    Upfront Pay-out on Disbursement
                  </span>
                </strong>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "6.15pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "36.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}> </span>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <p
            style={{
              marginTop: "17.6pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "5.0pt",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              The payments will be made in accordance with the provisions
              contained in clause 7 above.
            </span>
          </p>
          <h3
            style={{
              marginTop: "3.8pt",
              marginRight: "52.8pt",
              marginBottom: ".0001pt",
              marginLeft: "50.9pt",
              textIndent: "0cm",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              textAlign: "center",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              ANNEXURE – II
            </span>
          </h3>
          <p
            style={{
              marginTop: ".25pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 20, fontFamily: '"Calibri",sans-serif' }}
              ></span>
            </strong>
          </p>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "6.75pt",
              marginBottom: "8.0pt",
              marginLeft: "5.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
              lineHeight: "113%",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}>
              This <strong>ANNEXURE II </strong>is annexed to and is made an
              integral part of the Agreement dated{" "}
            </span>
            <strong>
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "113%",
                  fontFamily: "Calibri",
                }}
              >
                {new Date().toDateString()}{" "}
              </span>
            </strong>
            <strong>
              <span
                style={{
                  fontSize: 16,
                  lineHeight: "113%",
                  fontFamily: "Calibri",
                }}
              ></span>
            </strong>
            <span style={{ fontFamily: "Calibri" }}>
              between <strong>R K Bansal Finance PVT LTD, </strong>a company
              incorporated in India under Companies Act, 1956/2013 and having
              corporate identity number U74110DL1984PTC019355 and its registered
              office at 8/9 2<sup>nd</sup> Floor WEA, Karol Bagh New Delhi-
              110005
              <strong> (hereinafter referred to as “Company”) </strong>
              and{" "}
              <strong>
                the SERVICE PROVIDER/REFERRER) {data?.name} -{" "}
                {data?.profile?.address}
              </strong>
            </span>
          </p>
          <p
            style={{
              marginTop: ".1pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 18, fontFamily: '"Calibri",sans-serif' }}
              ></span>
            </strong>
          </p>
          <h3
            style={{
              marginTop: ".05pt",
              marginRight: "0cm",
              marginBottom: ".0001pt",
              marginLeft: "5.0pt",
              textIndent: "0cm",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
              textAlign: "justify",
            }}
          >
            <span style={{ fontFamily: '"Calibri",sans-serif' }}>
              SCOPE OF SERVICES
            </span>
          </h3>
          <p
            style={{
              marginTop: ".15pt",
              marginRight: "0cm",
              marginBottom: "0cm",
              marginLeft: "0cm",
              textAlign: "justify",
              fontSize: 15,
              fontFamily: '"Cambria",serif',
            }}
          >
            <strong>
              <span
                style={{ fontSize: 25, fontFamily: '"Calibri",sans-serif' }}
              ></span>
            </strong>
          </p>
          <div
            style={{
              marginTop: "0cm",
              marginRight: "0cm",
              marginBottom: "8.0pt",
              marginLeft: "0cm",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <ol
              style={{
                marginBottom: "0cm",
                listStyleType: "decimal",
                marginLeft: 67,
              }}
            >
              <li
                style={{
                  marginTop: "0cm",
                  marginRight: "0cm",
                  marginBottom: "8.0pt",
                  marginLeft: "0cm",
                  fontSize: "11.0pt",
                  fontFamily: '"Calibri",sans-serif',
                }}
              >
                <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                  The SERVICE PROVIDER/REFERRER shall approach, discuss and
                  procure Borrowers for and behalf of Company. All forms of
                  interaction with prospectus whether by way of telephone calls,
                  personal visits or any other form shall be based on the terms
                  communicated to the SERVICE PROVIDER/REFERRER from time to
                  time.
                </span>
              </li>
            </ol>
          </div>
          <p
            style={{
              marginTop: "0cm",
              marginRight: "6.9pt",
              marginBottom: "8.0pt",
              marginLeft: "41.0pt",
              fontSize: "11.0pt",
              fontFamily: '"Calibri",sans-serif',
              textAlign: "justify",
              lineHeight: "112%",
            }}
          >
            <span style={{ fontFamily: "Calibri" }}> </span>
          </p>
          <ol start={2} style={{ listStyleType: "decimal", marginLeft: 67 }}>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The SERVICE PROVIDER/REFERRER shall be provided on a regular
                basis a stock of relevant acceptance forms, relevant brochures,
                pamphlets etc. and all such information, data and material as
                may be deemed necessary by Company to enable the SERVICE
                PROVIDER/REFERRER to render its Services.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The SERVICE PROVIDER/REFERRER shall get the prescribed
                acceptance form duly and completely filled up and signed from
                the prospective Borrower. The SERVICE PROVIDER/REFERRER shall
                send the duly completed documents along with required supporting
                documentation directly to Company, Company reserve its right to
                fix minimum application procurement targets for the SERVICE
                PROVIDER/REFERRER and Company will inform the SERVICE
                PROVIDER/REFERRER from time to time about its targets.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The SERVICE PROVIDER/REFERRER shall perform verification with
                regard to the information provided by the prospective Borrower
                including the address.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The SERVICE PROVIDER/REFERRER shall not collect cash from any
                prospective Borrower.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The SERVICE PROVIDER/REFERRER shall hand-over or forward the
                acceptance form so collected from time to time and in the manner
                informed by the Company on the day of its receipt or latest by
                the following day.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                The SERVICE PROVIDER/REFERRER shall procure and provide to
                Company any additional information as and when required.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                It is clearly understood by the SERVICE PROVIDER/REFERRER, that
                Company may at its sole discretion, accept or reject the Leads
                by assigning reasons for rejection, whatsoever and without any
                liability/obligation towards the SERVICE PROVIDER/REFERRER
                and/or towards the prospective Borrower. If for any reason, a
                prospective Borrower is finally not converted into a Lead
                Borrower by Company, the application shall become null and void.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                It is hereby clarified and agreed that the application form and
                the information contained therein shall not be returned by
                Company to the SERVICE PROVIDER/REFERRER and/or the prospective
                Borrower in any circumstances, and that the application shall
                become the property of the Company and the Company shall be
                entitled to use the said information in any manner. It shall be
                the responsibility of the SERVICE PROVIDER/REFERRER to inform
                the prospective Borrower of this fact in advance before receipt
                of the application.
              </span>
            </li>
            <li>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                Under no circumstances, the SERVICE PROVIDER/REFERRER or any of
                its employees
              </span>
              <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                shall make any false promise, commit fraud as determined by
                Company, or statement or offer or representation to the
                prospective Borrower including but not limited to fee
                waivers/referrals/gifts etc. The SERVICE PROVIDER/REFERRER shall
                be solely responsible for the same and would make good the loss
                suffered by Company on account of the aforesaid representations
                and statements etc. and further agree to indemnify Company in
                this regard.
              </span>
              <ol start={11} style={{ listStyleType: "decimal" }}>
                <li>
                  <span style={{ fontFamily: '"Calibri",sans-serif' }}>
                    In the event of any fraud perpetrated by the SERVICE
                    PROVIDER/REFERRER or any of its employees including but not
                    limited to sales executive the SERVICE PROVIDER/REFERRER
                    shall be solely responsible and liable for all the cost,
                    expenses and consequences thereof which Company may incur or
                    sustain in this regard. The SERVICE PROVIDER/REFERRER agrees
                    and undertakes to extend all its co-operation to Company in
                    case, Company decides to initiate legal action or ask the
                    SERVICE PROVIDER/REFERRER to initiate legal action.
                  </span>
                </li>
              </ol>
            </li>
          </ol>

          <Box display="flex" justifyContent="space-between">
            <p
              style={{
                marginTop: "6.15pt",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                textAlign: "justify",
              }}
            >
              For, <strong>R.K BANSAL FINANCE PRIVATE LIMITED</strong>
            </p>
            <p
              style={{
                marginTop: "6.15pt",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                textAlign: "justify",
              }}
            >
              For, <strong>{data?.name}</strong>
            </p>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <p
              style={{
                marginTop: "6.15pt",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                textAlign: "justify",
              }}
            >
              <strong>Name: Bharat Soni</strong>
            </p>

            <p
              style={{
                marginTop: "6.15pt",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                textAlign: "justify",
              }}
            >
              <strong>{data?.name}</strong>
            </p>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <p
              style={{
                marginTop: "6.15pt",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                textAlign: "justify",
              }}
            >
              <strong>Authorised Director</strong>
            </p>

            <p
              style={{
                marginTop: "6.15pt",
                marginRight: "0cm",
                marginBottom: "8.0pt",
                marginLeft: "0cm",
                fontSize: "11.0pt",
                fontFamily: '"Calibri",sans-serif',
                textAlign: "justify",
              }}
            >
              <strong> Authorised Signatory/ Director</strong>
            </p>
          </Box>
        </div>

        <br />
        <br />

        <Grid container spacing={3}>
          <Grid size={4} offset={4} textAlign="center">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              size="large"
              onClick={handleAgreement}
              endIcon={
                isSubmitting && (
                  <CircularProgress
                    size="1.4rem"
                    style={{ color: "#ffffff", marginLeft: 6 }}
                  />
                )
              }
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
}
