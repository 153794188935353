import React from "react";
import { Routes, Route } from "react-router-dom";
import { Login, LoginOTP, Register, RegisterOTP } from "../pages/Auth";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login-otp" element={<LoginOTP />} />
      <Route path="/register" element={<Register />} />
      <Route path="/register-otp" element={<RegisterOTP />} />
    </Routes>
  );
}
