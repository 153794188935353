import moment from "moment";

// Return Filter Days
export const getFilterDays = (days) => {
  switch (days) {
    case "today":
      return moment(new Date()).subtract(0, "days").format("YYYY-MM-DD");

    case "yesterday":
      return moment(new Date()).subtract(1, "days").format("YYYY-MM-DD");

    case "7days":
      return moment(new Date()).subtract(7, "days").format("YYYY-MM-DD");

    case "30days":
      return moment(new Date()).subtract(30, "days").format("YYYY-MM-DD");

    case "90days":
      return moment(new Date()).subtract(90, "days").format("YYYY-MM-DD");

    case "365days":
      return moment(new Date()).subtract(365, "days").format("YYYY-MM-DD");

    default:
      return moment(new Date()).format("YYYY-MM-01");
  }
};
