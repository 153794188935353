import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import styles from "./Details.module.scss";

// components
import FilterLoader from "../../../components/Loader/FilterLoader";
import FilterButton from "../../../components/Filters/FilterButton";
import SwitchFilter from "../../../components/Filters/SwitchFilter";

// methods
import { useDispatch, useSelector } from "react-redux";
import { changeFilterLoader } from "../../../redux/slices/filterSlice";
import CRMAPI from "../../../services/crm-api";

// icons
import { LuBadgePercent } from "react-icons/lu";
import { IoReceiptSharp } from "react-icons/io5";
import { FiMapPin } from "react-icons/fi";
import DateRangeSelector from "../../../components/Filters/DateRangeSelector";

export default function Details(props) {
  const { data, id } = props;
  const [report, setReport] = useState(null);

  const { filterLoader } = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  const { getDashboardReportAPI } = new CRMAPI();

  useEffect(() => {
    fetchReportDetails();
  }, [filterLoader]);

  const fetchReportDetails = async () => {
    const res = await getDashboardReportAPI(id);
    if (res.ok) {
      setReport(res.data);
    }
    dispatch(changeFilterLoader(false));
  };

  return (
    <Card elevation={0} sx={{ py: 1, borderRadius: 3 }}>
      <CardContent>
        <Grid container spacing={4}>
          <Grid size={12}>
            <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
              <Box className="header-title">
                <Typography variant="h4">DSA Summary Report</Typography>
                <DateRangeSelector />
              </Box>

              <Box>
                <SwitchFilter />
                <FilterButton />
              </Box>
            </Box>
          </Grid>

          <Grid size={3}>
            <Box className={styles.personalInfo}>
              <Box className={styles.icons}>
                <LuBadgePercent size={18} />
              </Box>
              <Box>
                <Typography variant="h6">
                  {data?.profile?.businesses[0]?.GSTNumber}
                </Typography>
                <Typography variant="body2">GST Number</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid size={3}>
            <Box className={styles.personalInfo}>
              <Box className={styles.icons}>
                <IoReceiptSharp size={18} />
              </Box>
              <Box>
                <Typography variant="h6">
                  {data?.profile?.businesses[0]?.TAN}
                </Typography>
                <Typography variant="body2">TAN</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid size={6}>
            <Box className={styles.personalInfo}>
              <Box className={styles.icons}>
                <FiMapPin size={18} />
              </Box>
              <Box>
                <Typography variant="h6">{data?.profile?.address}</Typography>
                <Typography variant="body2">Address</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>

      <CardContent>
        <Grid container spacing={4}>
          <Grid size={3}>
            <Box className={styles.dsaPerformance}>
              <Typography variant="h6">{report?.lead_count}</Typography>
              <Typography variant="body2">Leads</Typography>
            </Box>
          </Grid>

          <Grid size={3}>
            <Box className={styles.dsaPerformance}>
              <Typography variant="h6">{report?.part_payment_leads}</Typography>
              <Typography variant="body2">Part Payment Cases</Typography>
            </Box>
          </Grid>

          <Grid size={3}>
            <Box className={styles.dsaPerformance}>
              <Typography variant="h6">{report?.closed_leads}</Typography>
              <Typography variant="body2">Closed Leads</Typography>
            </Box>
          </Grid>

          <Grid size={3}>
            <Box className={styles.dsaPerformance}>
              <Typography variant="h6">₹{report?.disbursal_amount}</Typography>
              <Typography variant="body2">Disbursal Amount</Typography>
            </Box>
          </Grid>

          <Grid size={3}>
            <Box className={styles.dsaPerformance}>
              <Typography variant="h6">{report?.disbursed_leads}</Typography>
              <Typography variant="body2">Disbursed Leads</Typography>
            </Box>
          </Grid>

          <Grid size={3}>
            <Box className={styles.dsaPerformance}>
              <Typography variant="h6">
                {report?.not_interested_leads}
              </Typography>
              <Typography variant="body2">Not Interested Leads</Typography>
            </Box>
          </Grid>

          <Grid size={3}>
            <Box className={styles.dsaPerformance}>
              <Typography variant="h6">{report?.overdue_leads}</Typography>
              <Typography variant="body2">Overdue Leads</Typography>
            </Box>
          </Grid>

          <Grid size={3}>
            <Box className={styles.dsaPerformance}>
              <Typography variant="h6">{report?.rejected_leads}</Typography>
              <Typography variant="body2">Rejected Leads</Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>

      <CardHeader
        // action={
        //   <Box>
        //     <Button
        //       variant="outlined"
        //       sx={{ mr: 1, textTransform: "capitalize" }}
        //       size="small"
        //     >
        //       + Add New Bank
        //     </Button>
        //   </Box>
        // }
        title={
          <Box className="header-title">
            <Typography variant="h4">Bank Account Details</Typography>
          </Box>
        }
      />
      <CardContent>
        <TableContainer component={Paper}>
          <Table aria-label="lead table">
            <TableHead>
              <TableRow>
                <TableCell>Account Holder's Name</TableCell>
                <TableCell>Account Number</TableCell>
                <TableCell>Bank Name</TableCell>
                <TableCell>IFSC</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.profile?.bankDetails.length > 0 ? (
                <React.Fragment>
                  {data?.profile?.bankDetails?.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.accountHolderName}
                      </TableCell>
                      <TableCell>{row.bankAccountNumber}</TableCell>
                      <TableCell>{row.bankName}</TableCell>
                      <TableCell>{row.IFSCCode}</TableCell>
                      <TableCell>
                        {row.isActive ? "Active" : "Inactive"}
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell colSpan={5} align="center">
                      No Data Found
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {filterLoader && <FilterLoader />}
      </CardContent>
    </Card>
  );
}
