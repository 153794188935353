import { apiData } from "../utils/api";

class AuthAPI {
  // Login API
  async loginAPI(values) {
    const req = {
      url: "api/v1/auth/login/otp",
      method: "POST",
      data: {
        email: values.email,
      },
    };

    return await apiData(req);
  }

  // Login Verify OTP
  async verifyOTP(tempEmail, data, otp) {
    const req = {
      url: "api/v1/auth/login/verifyOTP",
      method: "POST",
      data: {
        email: tempEmail,
        otp: otp,
        requestId: data.requestId,
      },
    };

    return await apiData(req);
  }

  // Register API
  async registerAPI(values) {
    const req = {
      url: "api/v1/auth/register/otp",
      method: "POST",
      data: {
        email: values.email,
        phone: values.phone.toString(),
        role: "DSA", // ADMIN, DSA
      },
    };

    return await apiData(req);
  }

  // Register Verify OTP
  async registerVerifyOTP(tempValues, data, values) {
    const req = {
      url: "api/v1/auth/register/verifyOTP",
      method: "POST",
      data: {
        email: tempValues.email,
        phone: tempValues.phone.toString(),
        otp: values.otp,
        requestId: data.requestId,
      },
    };

    return await apiData(req);
  }

  // Logout
  async logoutAPI() {
    const req = {
      url: "api/v1/auth/sign-out",
      method: "POST",
      data: {
        email: "",
      },
    };

    return await apiData(req);
  }
}

export default AuthAPI;
