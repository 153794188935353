import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FaFilter } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFilterLoader,
  changePageNumber,
  setUpdateDateRange,
} from "../../redux/slices/filterSlice";
import { getFilterDays } from "../../helpers/date-format";

export default function FilterButton(props) {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { filterLoader } = useSelector((state) => state.filter);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(location.search);

  const startDate = queryParams.get("startDate");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterItems = [
    { id: "today", name: "Today" },
    { id: "yesterday", name: "Yesterday" },
    { id: "7days", name: "Last 7 Days" },
    { id: "30days", name: "30 Days" },
    { id: "90days", name: "90 Days" },
    { id: "365days", name: "365 Days" },
  ];

  const handleFilter = (item) => {
    queryParams.set("startDate", getFilterDays(item));
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const resetFilter = () => {
    queryParams.delete("startDate");
    queryParams.delete("leadFilter");
    navigate(`${location.pathname}?${queryParams.toString()}`);
    window.location.reload();
  };

  const displayName = startDate
    ? filterItems.find((item) => item.id === selectedFilter)?.name || "Filter"
    : "Filter";

  return (
    <>
      <Button
        variant="contained"
        startIcon={<FaFilter size={14} />}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ mr: 1, textTransform: "capitalize" }}
        size="small"
      >
        {selectedFilter ? selectedFilter : "Filter"}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {filterItems.map((item, index) => (
          <MenuItem
            onClick={() => {
              handleClose();
              handleFilter(item.id);
              setSelectedFilter(item.name);
              dispatch(changeFilterLoader(!filterLoader));
              dispatch(changePageNumber(1));
              dispatch(setUpdateDateRange(true));
            }}
            key={index}
            sx={{ fontSize: "0.875rem" }}
          >
            {item.name}
          </MenuItem>
        ))}

        {startDate && (
          <MenuItem onClick={() => resetFilter()} sx={{ fontSize: "0.875rem" }}>
            Reset Filter
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
