import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { MdCloudDownload } from "react-icons/md";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

import CRMAPI from "../../../services/crm-api";

// Filters
import FilterLoader from "../../../components/Loader/FilterLoader";
// import FilterButton from "./Filters/FilterButton";

// import SwitchFilter from "./Filters/SwitchFilter";

import { useDispatch, useSelector } from "react-redux";
import {
  changeFilterLoader,
  changePageNumber,
} from "../../../redux/slices/filterSlice";

import FilterButton from "../../../components/Filters/FilterButton";
import SwitchFilter from "../../../components/Filters/SwitchFilter";
import DateRangeSelector from "../../../components/Filters/DateRangeSelector";
import StatusDropdown from "../../../components/Filters/StatusDropdown";

export default function Leads({ id }) {
  const [data, setData] = useState([]);

  const [totalPage, setTotalPage] = useState(1);
  const [totalLeads, setTotalLeads] = useState(null);

  const { pageNumber, filterLoader } = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  const { getLeadListAPI, leadsDownloadURL, leadsDownloadAPI } = new CRMAPI();

  useEffect(() => {
    fetchLeadDetails();
  }, [pageNumber, filterLoader]);

  const fetchLeadDetails = async () => {
    const res = await getLeadListAPI(id, pageNumber);
    if (res.ok) {
      setData(res.data.leads);
      setTotalPage(res.data.totalPages);
      setTotalLeads(res.data.totalRows);
    }
    dispatch(changeFilterLoader(false));
  };

  const handleDownloadAPI = async () => {
    dispatch(changeFilterLoader(true));
    await leadsDownloadAPI();
    dispatch(changeFilterLoader(false));
  };

  return (
    <>
      <Card elevation={0} sx={{ py: 1, borderRadius: 3 }}>
        <CardHeader
          action={
            <Box>
              <SwitchFilter />
              <FilterButton />
              <Button
                variant="contained"
                startIcon={<MdCloudDownload size={20} />}
                sx={{ mr: 1, background: "#28a745" }}
                size="small"
                href={leadsDownloadURL()}
                onClick={handleDownloadAPI}
              >
                Download
              </Button>
            </Box>
          }
          title={
            <Box className="header-title">
              <Typography variant="h4">DSA Report</Typography>
              <StatusDropdown />
              <DateRangeSelector />
            </Box>
          }
        />

        <CardContent>
          <TableContainer component={Paper}>
            <Table aria-label="lead table">
              <TableHead>
                <TableRow>
                  <TableCell>Lead ID</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>Approval Status</TableCell>
                  <TableCell>Disbursal Amount</TableCell>
                  <TableCell>Disbursal Date</TableCell>
                  <TableCell>Lead Current Status</TableCell>
                  <TableCell>Loan Approved</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.length > 0 ? (
                  <React.Fragment>
                    {data?.map((row) => (
                      <TableRow
                        key={row.leadID}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.leadID}
                        </TableCell>
                        <TableCell>{row.mobile}</TableCell>
                        <TableCell>{row.approval_status}</TableCell>
                        <TableCell>{row.disbursalAmount}</TableCell>
                        <TableCell>{row.disbursalDate}</TableCell>
                        <TableCell>{row.lead_current_status}</TableCell>
                        <TableCell>{row.loanAmtApproved}</TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ) : (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell colSpan={7} align="center">
                      No Record Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Box
            sx={{ mt: 4 }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              {totalLeads > 0 && (
                <Typography variant="body2">
                  Total Leads: <strong>{totalLeads}</strong>
                </Typography>
              )}
            </Box>
            <Box>
              <ResponsivePagination
                current={pageNumber}
                total={totalPage}
                onPageChange={(page) => {
                  dispatch(changePageNumber(page));
                  dispatch(changeFilterLoader(true));
                }}
                maxWidth={400}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>

      {/* Filter Loader */}
      {filterLoader && <FilterLoader />}
    </>
  );
}
