import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { FiEye } from "react-icons/fi";
import CRMAPI from "../../../services/crm-api";

import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import Swal from "sweetalert2";
import UserAPI from "../../../services/profile-api";
import { getDownloadUrlFromPresignedUrl } from "../../../helpers/upload-helper";
import { smallButton } from "../../../constants/styles";
import styled from "@emotion/styled";
import { MdOutlineCloudUpload } from "react-icons/md";
import PaymentModal from "../../../components/Payment/PaymentModal";
import { downloadFiles } from "../../../helpers/download";

export default function Payout({ id }) {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const { generatePresignedURLAPI } = new UserAPI();
  const { getPayoutAPI, uploadInvoiceAPI, downloadDocumentsAPI } = new CRMAPI();

  useEffect(() => {
    fetchPayoutDetails();
  }, [currentPage]);

  const fetchPayoutDetails = async () => {
    const res = await getPayoutAPI(id, currentPage);
    if (res.ok) {
      setData(res.data.commissionReport);
      setTotalPage(res.data.totalPages);
    }
  };

  const handleUploadFile = async (file, commissionReportId) => {
    const res = await generatePresignedURLAPI(file);

    const presignedUrl = res.data.url;

    await fetch(presignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        "Content-Length": file.size,
      },
      body: file,
    });

    const downloadURL = await getDownloadUrlFromPresignedUrl(presignedUrl);

    if (downloadURL) {
      const res = await uploadInvoiceAPI("", commissionReportId, downloadURL);

      if (res.ok) {
        Swal.fire({
          icon: "success",
          text: "Upload Successfully!",
          showConfirmButton: false,
          timer: 2000,
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  };

  const downloadInvoice = async (url) => {
    const res = await downloadDocumentsAPI(url);

    if (res.ok) {
      downloadFiles(res.data.url);
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  // console.log("data------------", data);

  return (
    <Card elevation={0} sx={{ py: 1, borderRadius: 3 }}>
      <CardHeader
        title={
          <Box className="header-title">
            <Typography variant="h4">Payouts</Typography>
          </Box>
        }
      />

      <CardContent>
        <TableContainer component={Paper}>
          <Table aria-label="lead table">
            <TableHead>
              <TableRow>
                <TableCell>Month</TableCell>
                <TableCell>Invoice</TableCell>
                <TableCell>Commission Amount</TableCell>
                <TableCell>GST Amount</TableCell>
                <TableCell>Total Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Payment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length > 0 &&
                data?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {new Date(row.month).toDateString()}
                    </TableCell>
                    <TableCell>
                      {row.invoiceUrl ? (
                        <Button
                          variant="outlined"
                          size="small"
                          startIcon={<FiEye size={15} color="#585858" />}
                          sx={{
                            ...smallButton,
                            backgroundColor: "#ebebeb",
                            borderColor: "#ebebeb",
                            color: "#585858",
                            fontSize: 11,
                          }}
                          onClick={() => downloadInvoice(row.invoiceUrl)}
                        >
                          View
                        </Button>
                      ) : (
                        <Button
                          component="label"
                          size="small"
                          role={undefined}
                          variant="outlined"
                          tabIndex={-1}
                          startIcon={
                            <MdOutlineCloudUpload size={16} color="#585858" />
                          }
                          sx={{
                            ...smallButton,
                            backgroundColor: "#ebebeb",
                            borderColor: "#ebebeb",
                          }}
                        >
                          <Typography sx={{ fontSize: 11, color: "#2e2e2e" }}>
                            Upload
                          </Typography>
                          <VisuallyHiddenInput
                            type="file"
                            onChange={(event) =>
                              handleUploadFile(event.target.files[0], row.id)
                            }
                          />
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>₹{row.commissionAmount}</TableCell>
                    <TableCell>₹{row.gstAmount}</TableCell>
                    <TableCell>₹{row.totalAmount}</TableCell>
                    <TableCell>{row.status}</TableCell>
                    <TableCell>
                      {(row.status === "pending" ||
                        row.status === "under_review") && (
                        <PaymentModal data={row} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}

              {data?.length === 0 && (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={6} align="center">
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 4 }}>
          <ResponsivePagination
            current={currentPage}
            total={totalPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
