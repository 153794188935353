import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import styles from "./CRMDSAUserDetails.module.scss";

import UserInfo from "../../components/User/UserInfo";
import Layout from "../../layout/Layout";

import { useLocation, useNavigate, useParams } from "react-router-dom";

// import Lead from "./Leads/Leads";
import Payout from "./Payout/Payout";
import Details from "./Details/Details";
import UserAPI from "../../services/profile-api";
import Loader from "../../components/Loader/Loader";
import Leads from "./Leads/Leads";

export default function CRMDSAUserDetails() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [tabs, setTabs] = useState("details");

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const queryParams = new URLSearchParams(location.search);

  const { getProfileAPI } = new UserAPI();

  useEffect(() => {
    const tab = queryParams.get("type");
    setTabs(tab);
  }, [location]);

  useEffect(() => {
    fetchUserAPI();
  }, []);

  const fetchUserAPI = async () => {
    const res = await getProfileAPI(id);
    if (res.ok) {
      setData(res.data);
    }
    setLoading(false);
  };

  const updateTabsParams = (name) => {
    queryParams.set("type", name);
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <Layout>
      <Box className="crm__user">
        <Stack spacing={3}>
          {/* User Info */}
          <UserInfo data={data} />

          {/* Tabs */}
          <Box className={styles.tabs}>
            <Box
              className={tabs == "details" ? styles.active : ""}
              onClick={() => updateTabsParams("details")}
            >
              Details
            </Box>
            <Box
              className={tabs == "leads" ? styles.active : ""}
              onClick={() => updateTabsParams("leads")}
            >
              Leads
            </Box>
            <Box
              className={tabs == "payouts" ? styles.active : ""}
              onClick={() => updateTabsParams("payouts")}
            >
              Payouts
            </Box>
          </Box>

          {tabs == "details" && <Details data={data} id={id} />}
          {tabs == "leads" && <Leads id={id} />}
          {tabs == "payouts" && <Payout id={id} />}
        </Stack>
      </Box>
    </Layout>
  );
}
