import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Formik } from "formik";
import styles from "./Auth.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { registerValidation } from "./Auth.validation";
import AuthAPI from "../../services/auth-api";
import Swal from "sweetalert2";

export default function Register() {
  const navigate = useNavigate();

  const { registerAPI } = new AuthAPI();

  const initialValues = {
    phone: "",
    email: "",
    password: "",
    repeatPassword: "",
  };

  return (
    <Box className={styles.auth__styles}>
      <Container maxWidth="md">
        <Grid container spacing={4}>
          <Grid size={12}>
            <Box className={styles.box}>
              <Box className={styles.form}>
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  validationSchema={registerValidation}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    setTimeout(async () => {
                      const res = await registerAPI(values);

                      if (res.ok) {
                        navigate("/register-otp", {
                          state: {
                            data: res.data,
                            tempValues: values,
                          },
                        });
                      } else {
                        Swal.fire({
                          icon: "error",
                          text: res.err,
                          showConfirmButton: false,
                          timer: 3000,
                        });
                      }

                      setSubmitting(false);
                    }, 500);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Box sx={{ mb: 3 }}>
                        <img src="/images/logo2.png" alt="" />
                        <Typography variant="h2">Registration</Typography>
                        <Typography variant="body2">
                          Welcome to Ram Fincorp - Your Partner in Financial
                          Growth
                        </Typography>
                      </Box>

                      <Grid container spacing={3}>
                        <Grid size={12}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            size="small"
                            name="email"
                            label="Email"
                            variant="outlined"
                            helperText={touched.email && errors.email}
                            error={touched.email && errors.email}
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid>

                        <Grid size={12}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            size="small"
                            name="phone"
                            label="Phone"
                            variant="outlined"
                            helperText={touched.phone && errors.phone}
                            error={touched.phone && errors.phone}
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            inputProps={{ maxLength: 10 }}
                            type="number"
                          />
                        </Grid>

                        {/* <Grid size={12}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            size="small"
                            name="password"
                            label="Password"
                            variant="outlined"
                            type="password"
                            helperText={touched.password && errors.password}
                            error={touched.password && errors.password}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid> */}

                        {/* <Grid size={12}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            size="small"
                            name="repeatPassword"
                            label="Re-enter Password"
                            variant="outlined"
                            type="password"
                            helperText={
                              touched.repeatPassword && errors.repeatPassword
                            }
                            error={
                              touched.repeatPassword && errors.repeatPassword
                            }
                            value={values.repeatPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </Grid> */}

                        <Grid size={12} textAlign="center">
                          <Button
                            color="primary"
                            // disabled={true}
                            variant="contained"
                            fullWidth
                            type="submit"
                            size="large"
                            endIcon={
                              isSubmitting && (
                                <CircularProgress
                                  size="1.4rem"
                                  style={{ color: "#ffffff", marginLeft: 6 }}
                                />
                              )
                            }
                            sx={{
                              "&.Mui-disabled": {
                                color: "#333333",
                                background: "#ddddddd",
                              },
                              width: 200,
                              margin: 2,
                              textTransform: "capitalize",
                            }}
                          >
                            Request OTP
                          </Button>

                          <Typography typography="body2">
                            Not a Ram Fincorp DSA? <Link to="/">Login Now</Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Box>
              <Box className={styles.images}>
                <img src="/images/login-image.jpg" alt="" />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
